@import "../../../../../../../../shared/styles/styles.scss";

.browser-tab-header-filter {
  align-items: flex-start;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;

  &__input {
    flex: 1;

    & > label {
      font-weight: $font-weight--500;
    }
  }

  & > * + * {
    margin-left: $spacing--xs;
  }
}
