@import "../../../../shared/styles/styles.scss";

.transform-editor {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 40%;
  min-height: 0;
  width: 100%;

  &--single {
    height: 100%;
  }

  &__card {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100%;
    margin-top: $spacing--xs;
    min-height: 0;

    & > .ant-card-body {
      height: 100%;
    }

    &__editor {
      height: 100%;
      width: 100%;
    }
  }
}
