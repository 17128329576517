@import "../../../../shared/styles/styles.scss";

.run-header {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;

  & > * + * {
    margin: 0 0 0 $spacing--lg;
  }

  &__info {
    align-items: center;
    display: flex;
    flex-wrap: nowrap;

    & > * + * {
      margin-left: $spacing--lg;
      white-space: nowrap;
    }
  }

  &__failure-reason {
    color: $color--text-error;
    flex: 1;

    p {
      margin: 0;
      padding: 0;

      & > span {
        font-weight: $font-weight--600;
      }
    }
  }

  &__progress-bar {
    min-width: 300px;
  }

  @media only screen and (max-width: 1024px) {
    display: block;

    & > * + * {
      margin: $spacing--lg 0 0 0;
    }
  }
}
