@import "../../../styles/styles.scss";

.graph-droppable {
  align-items: center;
  display: flex;
  flex: 1;
  height: 100%;
  justify-content: center;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;

  &--is-dragging {
    background-color: rgba($color--neutral-5, 0.5);
    border: 4px solid $color--divider-2;
    pointer-events: all;

    & > .graph-droppable__content--default {
      background-color: $color--neutral-5;
    }
  }

  &--is-over {
    background-color: rgba($color--item-bg-selected, 0.5);
    border: 4px solid $color--coalesce-blue;
    pointer-events: all;

    & > .graph-droppable__content--default {
      background-color: $color--coalesce-blue;
    }
  }

  &__content {
    &--default {
      border-radius: 4px;
      color: $color--text-primary-inverted;
      padding: $spacing--lg $spacing--xxl;
    }
  }
}
