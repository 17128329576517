@import "../../../shared/styles/styles.scss";

#view-package {
  --displayWidth: 832px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  background-color: $color--bg-view;

  & .view-package-description {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: $color--bg-primary;

    & .view-package-description-nav {
      padding: $spacing--md-lg 0px;
      width: var(--displayWidth);
    }

    & .view-package-description-header {
      display: flex;
      justify-content: space-between;
      width: var(--displayWidth);
      padding: $spacing--md-lg 0px;

      & .view-package-description-title {
        display: flex;
        align-items: center;
        margin: 0;
        font-weight: $font-weight--500;
        font-size: $font-size--l-xl;

        & .ant-tag {
          margin-left: $spacing--xs;
          font-weight: $font-weight--400;
        }
      }
    }

    & .view-package-description-text {
      line-height: $line-height--22;
      margin: 0 0 $spacing--lg 0;
      width: var(--displayWidth);
    }

    & .view-package-description-tag-wrapper {
      display: flex;
      gap: $spacing--xs;
      margin: 0 0 $spacing--lg 0;
      width: var(--displayWidth);

      & * {
        margin: 0;
      }
    }

    & .view-package-description-metadata-wrapper {
      display: flex;
      gap: $spacing--lg;
      margin: 0 0 $spacing--sm 0;
      width: var(--displayWidth);

      & .view-package-description-metadata-title {
        font-weight: $font-weight--400;
        font-size: $font-size--sm;
        color: $color--text-secondary;
      }
    }
  }

  .view-package-tabs {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    & .ant-tabs-nav-wrap {
      padding: 0;
    }

    & .ant-tabs-nav {
      margin: 0;
      width: var(--displayWidth);

      & .ant-tabs-tab-active > .ant-tabs-tab-btn {
        color: $color--coalesce-blue;
      }

      & .ant-tabs-ink-bar {
        background: $color--coalesce-blue;
      }
    }

    & .ant-tabs-content-holder {
      padding: 0 $spacing--lg $spacing--lg;
      background-color: $color--bg-view;
      width: 100%;
      height: 100%;

      & .view-package-release-list {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: $spacing--lg;
        height: 100%;
        width: 100%;
        padding: $spacing--lg;
        background-color: $color--bg-view;

        & .ant-card {
          width: var(--displayWidth);

          & .ant-card-body {
            display: flex;
            flex-direction: column;

            & .view-package-release-card-header {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: $spacing--xs;

              & .view-package-release-card-title {
                margin: 0;
                font-weight: $font-weight--500;
                font-size: $font-size--lg;

                & .ant-tag,
                .ant-badge {
                  margin-left: $spacing--sm;
                  font-weight: $font-weight--400;
                }
              }

              & .view-package-description-view-release {
                border: none;
                box-shadow: none;
                color: $color--coalesce-blue;
              }
            }

            & .view-package-release-card-text {
              margin: 0 0 $spacing--xs 0;
              line-height: $line-height--22;
            }

            & .view-package-release-card-footer {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              gap: $spacing--md;

              & .anticon {
                margin-right: $spacing--xs;
              }

              & * {
                margin: 0;
              }
            }
          }
        }
      }
    }
  }
}
