@import "../../web/shared/styles/styles.scss";

.propagation-confirm-error {
  margin: $spacing--md $spacing--xl $spacing--sm 0px;
}

.ant-spin-nested-loading {
  .column-lineage-loading-spinner {
    padding-top: 60vh;
  }
}

.translate-layer {
  cursor: pointer;
  overflow: hidden;
  width: 100%;

  &--default {
    background-color: $color--bg-graph;
    background-size: 50px 50px;
    border: none;
    height: 100%;
    margin: 0;
  }

  &--impact {
    background-color: $color--bg-graph;
    height: 100%;
  }

  &--doc {
    background-color: $color--bg-view;
    border: 1px solid $color--divider-2;
    height: 500px;
  }

  &__header {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    left: 0;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 1;

    & > * + * {
      margin-top: $spacing--xxs;
    }

    &--passthrough {
      opacity: 10%;
    }

    &__controls {
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-end;
      padding: $spacing--xxs $spacing--xxs;

      & > * + * {
        margin-left: $spacing--xxs;
      }

      &:first-of-type {
        background-color: $color--bg-view;
      }
    }
  }

  &__content {
    height: 100%;
    width: 100%;

    &--passthrough {
      opacity: 10%;
    }
  }
}
