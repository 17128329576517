@import "../../styles/styles.scss";

.display-card-container {
  position: relative;
  font-family: $font-family--roboto;
  font-size: $font-size--sm;
  color: $color--text-secondary;
}

.display-card__title {
  position: absolute;
  top: -10px; // half of line-height
  left: 16px;
  display: flex;
  padding: 0 4px; // nice little gap between text and border
  align-items: center;
  gap: 4px;
  background-color: $color--bg-primary;
  line-height: $line-height--20;
  max-width: fit-content; // makes sure div sizes with the legend text
}

.display-card {
  display: flex;
  padding: 20px;
  align-items: center;
  gap: $spacing--md; // 16px
  border-radius: 2px;
  border: 1px solid $color--neutral-4;
  justify-content: space-between;
  margin-bottom: 25px;

  &__text-wrap {
    flex: 1 0 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
  }

  &__primary-text {
    color: $color--text-primary; // font color: black, 0.85
    font-size: $font-size--md; //14px
    line-height: $line-height--22;
    font-weight: $font-weight--400;
    margin: 0;
  }

  &__secondary-text {
    margin: 0;
    font-weight: $font-weight--400;
    line-height: $line-height--20;
    font-size: $font-size--sm; // 12px
  }

  &__copy-button {
    color: $color--text-secondary;
  }
}

// error style
.display-card.display-card-error {
  border: 1px solid $color--dust-red-5;

  .display-card__primary-text-error {
    color: $color--text-secondary;
  }
}
