/**********************************************/
/***********  Marketing colors  ***************/
/**********************************************/

// Note: use these SPARINGLY, and only for non-core elements of the app (e.g. logo, confetti color, illustrations)

$color--brand-blue: #135594; // Coalesce logo blue

$color--marketing-dark-blue: #00274b;
$color--marketing-bright-blue: #2962ff;
$color--marketing-light-blue: #22c9fc;
$color--marketing-teal: #04df9e;
$color--marketing-orange: #ffa800;
$color--marketing-yellow: #ece210;
$color--marketing-lime: #72e001;
$color--marketing-magenta: #bd0485;
$color--marketing-green: #0ec943;
$color--marketing-red-orange: #ff521b;
