@import "../../../../../../../transform/variables.scss";
@import "../../../../../../shared/styles/styles.scss";

.job-runs-display-overview {
  &__title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-bottom: $spacing--xxxs;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &__name-container {
    overflow: hidden;
    display: flex;
    align-items: baseline;
    gap: $spacing--xxs;
  }

  &__actions-button.ant-btn {
    height: auto;
    padding: 0;
    padding-right: $spacing--xxs;
    padding-left: $spacing--xxs;
  }

  &__secondary-label {
    color: $color--text-secondary;
    font-size: 0.7rem;
  }

  &__notification-environment-name {
    font-weight: $font-weight--500;
  }
  &__notification-job-name {
    font-weight: $font-weight--500;
  }

  &__container {
    border-radius: $spacing--xs;
    padding: $spacing--sm;
    &--highlighted {
      @extend .job-runs-display-overview__container;

      @keyframes highlight-out {
        from {
          background-color: $color--highlight;
        }
        to {
          background-color: rgba(1, 1, 1, 0);
        }
      }

      animation: highlight-out 4s ease-out;
    }
  }
}
