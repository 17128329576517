@import "../../../../../web/shared/styles/styles.scss";

.centered-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.loading-text {
  margin-top: $spacing--md;
  font-size: $font-size--lg;
  color: $color--text-primary;
}

.error-alert {
  margin-bottom: $spacing--md-lg;
}

.table-container {
  height: 100%;
  width: 100%;
  overflow-y: auto;
}
