@import "../../../../../../web/shared/styles/styles.scss";

.environments-loading-spinner {
  &__container {
    background-color: transparentize($color--neutral-4, 0.5);
    border-radius: 4px;
    border: none;
    display: flex;
    flex-direction: row;
    height: 100%;
    position: absolute;
    width: 100%;
  }

  &__spinner {
    margin: auto;
  }
}
