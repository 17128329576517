@import "../../../../../../web/shared/styles/styles.scss";

.password-reset-segmented-input-form-text,
.enter-six-digits-text-second-line,
.enter-six-digits-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.password-reset-submit-button {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.password-reset-segmented-input-form-text {
  margin-bottom: $spacing--xs;
}

.enter-six-digits-text {
  margin: 0;
}

.disable-totp-form-instructions,
.disable-totp-form-segmented-input {
  display: flex;
  flex-direction: row;
  margin-bottom: 1em;
}

.disable-totp-form-instructions {
  justify-content: center;
}

.disable-totp-form-segmented-input {
  justify-content: space-between;
}

.disable-totp-form-segmented-input > .ant-row.ant-form-item-row,
.disable-totp-form-segmented-input > .ant-row.ant-form-item-row > .ant-col.ant-form-item-control,
.disable-totp-form-segmented-input
  > .ant-row.ant-form-item-row
  > .ant-col.ant-form-item-control
  > .ant-form-item-control-input,
.disable-totp-form-segmented-input
  > .ant-row.ant-form-item-row
  > .ant-col.ant-form-item-control
  > .ant-form-item-control-input
  > .ant-form-item-control-input-content {
  width: 100%;
}
