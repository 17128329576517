@import "../../web/shared/styles/styles.scss";

.problem-scanner {
  display: contents;
  flex: 1 0 auto;
  width: 100%;

  &__no-problems {
    color: $color--text-secondary;
    margin: $spacing--md;
    text-align: center;
  }

  &__cell {
    color: $color--text-primary;
    font-size: $font-size--sm;
    font-weight: $font-weight--500;

    &__value {
      align-items: center;
      display: flex;
      font-size: $font-size--sm;
      flex-wrap: nowrap;

      &--italic {
        font-style: italic;
      }

      &--actionable {
        cursor: pointer;
        text-decoration: underline;
      }

      &__icon,
      .anticon.problem-scanner__cell__value__icon {
        font-size: $font-size--md;
        margin-right: $spacing--xs;

        &--warning {
          color: $color--text-warning;
        }

        &--error {
          color: $color--text-error;
        }
      }
    }
  }
}
