@import "../../web/shared/styles/styles.scss";

.node-testing-menu {
  height: 100%;
}

.ant-tabs.testing-tabs {
  & > .ant-tabs-nav > .ant-tabs-nav-wrap {
    padding-left: $spacing--xs;
  }
}

.testDetailsContainer {
  padding: 4px 0px;
  border-top: 1px solid $color--divider-1;
}

.testDetailsTestName {
  display: flex;
  align-items: center;
  margin-left: 10px;
  padding: 2px 8px;
  border-radius: 8px;
  transition-duration: 0.3s;
  font-weight: 400;
  min-width: fit-content;
  white-space: nowrap;
  overflow: hidden;
  border: solid 1px $color--neutral-1;
}

.testDetailsTestName:hover {
  cursor: pointer;
  color: $color--brand-blue;
  border: solid 1px $color--brand-blue;
}

.testDetailsTestNameSelected {
  @extend .testDetailsTestName;
  background-color: $color--brand-blue;
  color: $color--neutral-1;
  border: solid 1px $color--neutral-1;
}

.testDetailsTestNameSelected:hover {
  cursor: pointer;
  background-color: $color--neutral-1;
  color: $color--text-error;
  border: solid 1px $color--text-error;
}

.testDetailsColName {
  display: flex;
  align-items: center;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 190px;
  min-width: 100px;
  border-right: 1px solid $color--divider-1;

  span {
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.testDetailsColTests {
  flex: 2;
  margin-left: 2em;
  align-items: flex-end;
}

.newNodeTestButton {
  width: 100%;
}

.testDragHandle {
  background-color: transparent;
  width: 18px;
  height: 36px;
  position: absolute;
  left: -15px;
  z-index: 2;
  top: 0;
}

.testDragHandle:hover + div {
  svg {
    fill: $color--neutral-7 !important;
  }
}

.testDragHandleSVG {
  position: absolute;
  left: -16px;
  top: 8px;
  z-index: 1;
  font-size: 20px !important;
  transition: 0.3s ease-in-out;
  fill: $color--neutral-6 !important;
  cursor: grab;
}

.draggableNodeTest {
  cursor: default !important;
}

.testHeaderEditing {
  color: $color--text-primary !important;
  input {
    font-size: 14px !important;
  }
}

.testHeaderDefault {
  input {
    color: $color--text-primary !important;
    text-overflow: ellipsis;
    font-size: 14px !important;
  }
  input:hover {
    cursor: default;
  }
}

.nodeTestWhenToRunSelector {
  width: 100%;

  div {
    border-radius: 6px !important;
  }
}

.nodeTestHeaderWrapper {
  min-height: 0 !important;
  background-color: $color--bg-secondary !important;
}

.nodeTestHeaderWrapper .MuiIconButton-root {
  padding: 0 !important;
}

.nodeTestCollapseWrapper {
  background-color: $color--bg-secondary !important;
  margin: 8px 10px 8px 10px !important;
  min-width: 255px;

  .MuiAccordionSummary-root {
    cursor: grab;
    padding: 0;
    margin: 0;
    height: 35px;
    min-height: 30px;
  }

  .MuiAccordionSummary-root:focus {
    cursor: grabbing;
  }

  .MuiButtonBase-root {
    cursor: pointer;
  }
}

.nodeTestCollapseWrapper .MuiAccordionSummary-content {
  justify-content: space-between;
}

.nodeTestCollapseWrapper .MuiAccordionDetails-root {
  background-color: $color--bg-primary;
  border-top: solid 1px $color--divider-2 !important;
  padding: 8px 16px 8px;
}

.nodeTestCollapseWrapper .MuiButtonBase-root {
  display: flex;
  flex-direction: row-reverse;
  margin: 0px 15px !important;
}
.nodeTestCollapseWrapper .MuiButtonBase-root > .MuiButtonBase-root {
  margin-left: 15px !important;
}
