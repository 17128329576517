@import "../../../shared/styles/styles.scss";

#published-packages-landing-page {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  overflow: scroll;

  & .published-packages-landing-page-content {
    width: 832px;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 $spacing--lg;

    & .published-packages-landing-page-title-bar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: $spacing--xl 0px $spacing--lg 0px;

      & .published-packages-landing-page-title {
        font-weight: $font-weight--500;
        font-size: $font-size--xl;
        margin: 0;
      }
    }

    & .published-packages-landing-page-list {
      display: flex;
      flex-direction: column;
      gap: $spacing--lg;
      height: 100%;

      & .ant-empty {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0 228px;
        height: 75vh;
      }

      & .ant-card-body {
        display: flex;
        flex-direction: column;

        & .published-packages-landing-page-card-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: $spacing--xs;

          & .published-packages-landing-page-card-title {
            display: flex;
            align-items: center;
            margin: 0;
            font-weight: $font-weight--500;
            font-size: $font-size--lg;

            & .published-package-landing-page-version {
              margin-left: $spacing--xxs;
            }

            & .ant-tag {
              margin-left: $spacing--xs;
              font-weight: $font-weight--400;
            }
          }

          & .published-packages-landing-page-card-view-button {
            border: none;
            box-shadow: none;
            color: $color--coalesce-blue;
          }
        }

        & .published-packages-landing-page-card-text {
          margin: 0 0 $spacing--xs 0;
          line-height: $line-height--22;
        }

        & .published-packages-landing-page-card-text-loading {
          margin: 0;
        }

        & .published-packages-landing-page-card-footer {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: $spacing--md;

          & .anticon {
            margin-right: $spacing--xs;
          }

          & * {
            margin: 0;
          }
        }
      }
    }
  }
}
