@import "../../../web/shared/styles/styles.scss";

.model-viewer {
  z-index: 500;

  & > .ant-tabs-nav {
    margin: 0 0 0 0 !important; // using !important to override .ant-tabs-top .ant-tabs-nav

    & > .ant-tabs-nav-wrap {
      padding-left: $spacing--xs;
    }
  }

  &__tab-content {
    align-items: center;
    display: flex;
    flex-wrap: nowrap;

    & > .anticon {
      font-size: $font-size--lg;
      margin-right: $spacing--xs !important; // using !important to override .ant-tabs-tab .anticon
    }
  }
}
