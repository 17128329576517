@import "../../../../../transform/variables.scss";
@import "../../../../../web/shared/styles/styles.scss";

.custom-monaco {
  display: flex;
  flex-direction: row;
  flex: 1 0 auto;
  height: 100%;
  width: 100%;
}

.editor-header {
  display: flex;
  flex-direction: row;
  height: 100%;
  background-color: $color--bg-primary;
  border-radius: 6px;
  z-index: 1;
  margin-right: 2px;
  padding: 10px 10px 10px 20px;
}

.editor-header-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
  margin-top: -10px;
  padding-right: 10px;
  &--header-overlay {
    position: absolute;
  }
}

.monaco-container {
  width: 100%;
  padding: 10px;
  background-color: $color--bg-primary;
  border-radius: 6px;
  &--header-overlay {
    position: relative;
  }
  &--dynamic-height {
    height: fit-content;
  }
  &--fixed-height {
    height: 100%;
  }
}
