@import "../../../../../shared/styles/styles.scss";

.ai-features-opt-in-text-toggle {
  margin-right: $spacing--xs;
}

.ai-features-toggles {
  margin-top: $spacing--md-lg;
  display: flex;
  flex-direction: column;
  gap: $spacing--md;
}

.ai-features-toggle {
  span {
    margin-right: $spacing--xs;
  }

  .ai-features-column-description-detail {
    margin-top: $spacing--xs;
    color: $color--text-secondary;
  }
}

.ai-sparkle-icon {
  margin-left: $spacing--xs;
}
