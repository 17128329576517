@import "../../../styles/styles.scss";

.warning-modal {
  &__header-container {
    display: flex;
    flex-direction: row;
    gap: $spacing--sm;
  }

  &__header-icon {
    color: $color--text-warning !important;
  }
}
