@import "../../../../../shared/styles/styles.scss";

.project-member-role-select {
  min-width: 100px;
  width: 100%;

  &__doc-link {
    display: inline-block;
    font-size: $font-size--sm;
    margin: $spacing--sm $spacing--sm $spacing--xs $spacing--sm;

    & > .anticon {
      margin-left: $spacing--xxxs;
    }
  }

  &__item {
    &__description {
      color: $color--text-secondary;
      font-size: $font-size--sm;
      margin: 0;
      white-space: normal;
    }
  }
}
