.killSwitchAnimation {
  .ant-switch,
  .ant-switch-handle,
  .ant-switch-handle::before,
  .ant-switch-inner {
    transition-property: none !important;
    transform: none !important;
    animation: none !important;
  }
}
