@import "../../../../../web/shared/styles/styles.scss";

#docs-wrapper {
  background-image: url("../../../../../assets/svg/docs-gradient-bg.svg");
  background-position: bottom left;
  background-color: $color--bg-view;
  background-repeat: no-repeat;
  height: 100%;
  overflow: hidden;
}
