@import "../../../../../../shared/styles/styles.scss";

.create-workspace-form {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;

  &__header {
    margin-top: $spacing--md;
    text-align: center;
  }

  &__steps {
    margin: $spacing--md 0;
    max-width: 600px;
  }

  &__content {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: auto;
    width: 100%;
  }

  &__footer {
    border-top: 1px solid $color--divider-2;
    padding-top: $spacing--md;
    position: relative;
    width: 100%;

    &__spinner {
      position: absolute;
      left: 50%;
      top: calc(50% + calc($spacing--md / 2));
      transform: translate(-50%, -50%);
      width: 100%;
    }

    &__buttons {
      align-items: center;
      display: flex;
      justify-content: space-between;

      &--first {
        justify-content: flex-end;
      }

      & > * + * {
        margin-left: $spacing--sm;
      }
    }
  }
}
