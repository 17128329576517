@import "../../web/shared/styles/styles.scss";

.deploy-wizard-modal {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;

  & > * {
    width: 100%;
  }

  &__header {
    padding: 0 0 $spacing--md 0;
  }

  &__steps {
    padding: $spacing--md 0;
    width: 50%;
  }

  &__content {
    align-items: center;
    flex: 1;
    display: flex;
    justify-content: center;
    overflow: auto;
  }

  &__loading-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: $spacing--md;
    height: 100%;
  }

  &__progress {
    display: flex;
    justify-content: flex-end;
    padding: $spacing--md 0 0 0;
  }
}
