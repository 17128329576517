@import "../../../web/shared/styles/styles.scss";

.sequence-header {
  align-items: center;
  display: flex;
  overflow: hidden;
  width: 100%;

  .anticon.sequence-header__icon {
    margin-right: $spacing--xs;

    &--error {
      color: $color--text-error;
    }

    &--success {
      color: $color--text-success;
    }
  }
}
