.orgs-table {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  &__buttons {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    width: 100%;
  }

  &__table {
    flex: 1;
    height: 100%;
    overflow: hidden;
    width: 100%;

    &__column {
      &--name {
        min-width: 200px;
      }

      &--id {
        min-width: 200px;
      }

      &--account-level {
        min-width: 175px;
      }
    }
  }
}
