@import "../../../shared/styles/styles.scss";

.git-footer {
  background-color: $color--brand-blue;
  color: $color--text-primary-inverted;
  padding: $spacing--xxs $spacing--md;
  position: relative;
  width: 100%;
  z-index: 100; // should be above everything else

  img,
  .anticon {
    display: block;
    font-size: $font-size--xs;
    height: $font-size--xs;
    width: $font-size--xs;
  }

  & > .ant-col {
    font-size: $font-size--xs;

    & > .ant-row {
      align-items: center;
      display: flex;

      & > .ant-col {
        font-size: $font-size--xs;

        &:not(.no-line) + .ant-col {
          border-left: 1px solid rgba($color--neutral-1, 0.5);
          padding-left: $spacing--xs;
        }

        & + .ant-col {
          margin-left: $spacing--xs;
        }
      }
    }
  }

  &__status {
    display: flex;
    flex-direction: row;
    background: none;
    border: none;
    color: $color--text-primary-inverted;
    cursor: pointer;
    padding: 0;

    &:active,
    &:hover,
    &:focus,
    &:focus {
      background: none;
      border: none;
      color: $color--text-primary-inverted;
      font-size: $font-size--xs;
      padding: 0;
    }
  }
}
