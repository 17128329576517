@import "../../../../../shared/styles/styles.scss";

.storage-location {
  &.ant-card {
    border-radius: 6px;
    max-width: 1200px;
  }

  &__content {
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 100%;
  }

  &__header {
    align-items: center;
    display: flex;
    flex-wrap: nowrap;

    &__text {
      margin: 0;
      padding: 0;
    }

    &__tag.ant-tag {
      margin: 0 0 0 $spacing--sm;
    }
  }

  & + .storage-location {
    margin-top: $spacing--sm;
  }
}
