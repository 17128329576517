@import "../../../../web/shared/styles/styles.scss";

.status-badge-container {
  margin-right: $spacing--xs;
}

.override-sql-content {
  padding: $spacing--xs;
  &__container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: $spacing--xs;
    &__title {
      font-weight: $font-weight--600;
      font-size: $font-size--lg;
    }
  }
}
