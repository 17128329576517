@import "../../../web/shared/styles/styles.scss";

// Modal Formatting
.force-checkout-modal-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: $spacing--md-lg;
}

// Give Cards Title in Border
.force-checkout-modal-card-container {
  position: relative;
  font-family: $font-family--roboto;
  font-size: $font-size--sm;
  color: $color--text-primary;
  max-width: 590px;
}

.force-checkout-modal-card-container:nth-of-type(2) {
  margin-bottom: $spacing--sm;
}

.force-checkout-modal-card-title {
  position: absolute;
  top: -10px;
  left: $spacing--md;
  display: flex;
  padding: 0 $spacing--xs;
  align-items: center;
  gap: $spacing--xs;
  background-color: $color--bg-primary;
  color: $color--text-secondary;
  line-height: $line-height--20;
  max-width: fit-content;
}

// Card Formatting
.force-checkout-modal-card {
  display: flex;
  flex-direction: column;
  padding: $spacing--md-lg;
  align-items: flex-start;
  border-radius: $spacing--xxs;
  border: 1px solid $color--neutral-4;
  justify-content: space-between;
  gap: $spacing--sm;
}

.force-checkout-modal-card > p {
  overflow-wrap: anywhere;
}

.force-checkout-modal-arrow {
  align-self: center;
}

.force-checkout-modal-card-commit-code {
  color: $color--text-secondary;
  font-weight: $font-weight--400;
  font-size: $font-size--sm;
  margin-left: $spacing--xxs;
}

.force-checkout-modal-branch {
  display: flex;
  align-items: center;
  font-weight: $font-weight--500;
  gap: $spacing--xs;
}

.force-checkout-modal-branch,
.force-checkout-modal-commit {
  margin-bottom: 0;
}

// Warning if uncommitted changes
.force-checkout-modal-uncommitted-warning {
  padding: $spacing--xxxs;
  display: flex;
  flex-direction: column;
  gap: $spacing--xxs;
}

#forceCheckoutInput {
  height: $spacing--xl-xxl;
}

.force-checkout-modal-span-text {
  font-weight: $font-weight--500;
  text-decoration: underline;
}

// Footer
.force-checkout-modal-footer {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
}

.force-checkout-modal-footer > p {
  margin: 0;
}
