@import "../../../shared/styles/styles.scss";

.ant-breadcrumb-link {
  & .navigation-breadcrumb-button {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    height: $spacing--md;
    color: $color--text-secondary;

    & :hover {
      color: $color--text-primary;
    }
  }
}
