@import "../../../../../../../shared/styles/styles.scss";

.run-status-popover {
  width: 256px;

  &__header {
    align-items: center;
    display: flex;
    flex-wrap: nowrap;

    &__title {
      font-size: $font-size--lg;
      font-weight: $font-weight--500;
      line-height: $line-height--24;
      margin: 0 $spacing--sm 0 0;
    }
  }

  &__commit {
    margin-top: $spacing--xxs;
  }

  &__time {
    margin: $spacing--md 0;

    & > .anticon {
      margin-right: $spacing--sm;
    }

    &__from-now {
      margin-left: $spacing--xs;
    }
  }

  &__button.ant-btn {
    width: 100%;
  }
}
