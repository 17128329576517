@import "../../../../../web/shared/styles/styles.scss";

.job-schedule-edit {
  $content-width: 700px;

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $color--bg-primary;
    height: 100%;
    overflow: auto;
  }

  &__navigation {
    width: $content-width;
    margin-bottom: $spacing--xxs;
    margin-top: $spacing--sm;
    padding: $spacing--md $spacing--lg;
  }

  &__navigation-header {
    gap: $spacing--md;
    padding-top: $spacing--sm;
    padding-bottom: $spacing--xxs;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    padding: $spacing--lg 0;
    gap: $spacing--xxs;
  }

  &__notification-keyword {
    font-weight: $font-weight--500;
  }

  &__container &__platform-alert {
    margin-top: $spacing--sm;
    align-items: center;
  }

  &__platform-alert-keyword {
    font-weight: $font-weight--500;
  }

  &__platform-alert-text {
    margin-bottom: 0;
  }

  &__form-container {
    display: grid;
    grid-template-columns: 1fr $content-width 1fr;
    max-width: 960px;
  }

  &__form-step-container {
    display: flex;
    flex-direction: column;
    grid-column: span 2 / span 2;
  }

  &__form-steps {
    height: 40%;
  }

  &__form-step-header-container {
    display: flex;
    flex-direction: column;
  }

  &__form-step-header-title {
    font-weight: $font-weight--500;
    font-size: $font-size--lg;
    margin-bottom: $spacing--md;
  }

  &__form-step-header-description {
    color: $color--text-secondary;
  }
}
