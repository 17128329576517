@import "../../../../../../web/shared/styles/styles.scss";

.table-container {
  width: 100%;

  .original-description-cell,
  .new-description-cell {
    white-space: pre-wrap;
    word-break: break-word;
    word-wrap: break-word;
  }

  .hidden-textarea-placeholder {
    position: absolute;
    visibility: hidden;
    width: 100%;
  }
  .textarea-input {
    resize: vertical;
  }
  .gray-text {
    color: $color--text-secondary;
  }
}
