@import "../../../web/shared/styles/styles.scss";

.subgraphs-tree {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;

  .ant-tree {
    // is this being used at all?
    &.ant-tree-directory {
      .ant-tree-treenode-selected::before,
      .ant-tree-treenode-selected:hover::before {
        background: $color--neutral-4;
        bottom: 0px;
      }
    }

    .ant-tree-treenode {
      padding: 0px;
    }

    .ant-tree-node-content-wrapper {
      min-height: 10px;
    }
  }

  // Indent some levels of the tree
  // Unsure why we have to set the indentation manually. May be something we're doing wrong or a bug in ag-grid.
  .ag-row-level-2 {
    .ag-cell {
      padding-left: $spacing--lg;
    }
  }

  .ag-row-level-3 {
    .ag-cell {
      padding-left: calc($spacing--xxxl + $spacing--lg);
    }
  }

  &__column-viewer {
    flex: 0 0 40%;
  }
}
