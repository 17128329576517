@import "../../../../shared/styles/styles.scss";

.project-header {
  margin-bottom: $spacing--xl;

  &__title-and-buttons {
    align-items: center;
    display: flex;
    justify-content: space-between;
    max-width: 100%;
  }

  &__title {
    font-weight: 600;
    margin-bottom: 0;
    overflow: hidden;
    margin-right: $spacing--sm;
  }

  &__description {
    color: $color--text-secondary;
    text-align: justify;
    margin: $spacing--sm 0 0 0;
  }

  &__buttons {
    align-items: center;
    display: flex;
    flex-wrap: nowrap;

    & > * + * {
      margin-left: $spacing--sm;
    }
  }
}
