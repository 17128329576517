.run-grid {
  flex: 1;
  height: 100%;
}

.run-grid-node {
  .ag-sort-indicator-icon.ag-sort-order {
    // hide number shown by default when multi-group sorting is enabled
    display: none;
  }
  .ag-row-group.ag-row-level-0 > .ag-cell.ag-cell-value,
  .ag-row-group > .ag-group-value {
    // bold row headers
    font-weight: 500;
    & span.ag-group-child-count {
      // hide parenthetical child count shown by default in group rows
      display: none;
    }
  }
}

.RunGridStatusContainer {
  width: 12px;
  margin-right: 12px;
}
