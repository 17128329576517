.environments-list {
  &__container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-height: 0;
    overflow-y: auto;
    padding: 10px;
    width: 100%;
  }
}
