@import "../../../styles/styles.scss";

.wizard-step {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  width: 100%;

  &__header {
    margin-bottom: $spacing--xl;
    overflow: hidden;
  }

  &__body {
    flex: 1;
    overflow: hidden;
  }

  &__footer {
    border-top: 1px solid $color--divider-1;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    padding-top: $spacing--xl;

    &__section {
      display: flex;
      flex-wrap: nowrap;

      &--buttons {
        & > * + * {
          margin-left: $spacing--sm;
        }
      }

      &--left {
        justify-content: flex-start;
      }

      &--right {
        justify-content: flex-end;
      }
    }
  }
}
