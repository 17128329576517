.segmented-input-form-item.ant-form-item,
.segmented-input-form-item,
.segmented-input-input-number {
  margin: 1em 0.4em 2em 0.4em;
}

.segmented-input-display {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.segmented-input-input-array {
  display: flex;
  flex: 1 0 auto;
  flex-direction: row;
  justify-content: space-between;
  .ant-input-number-input {
    text-align: center;
  }
}
