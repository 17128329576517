@import "../../../../shared/styles/styles.scss";

.feature-flags {
  &__items {
    & > * + * {
      border-top: 1px solid $color--divider-2;
    }
  }

  &__item {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: $spacing--xs $spacing--sm;
    width: 400px;

    &:nth-child(odd) {
      background-color: $color--bg-secondary;
    }

    &__label {
      flex: 1;
      margin-right: $spacing--sm;
    }
  }
}
