@import "../../../../web/shared/styles/styles.scss";

.commit-menu-container {
  height: 100%;
  position: relative;

  // This div will block out the background so user cant click when the resync modal is open
  & #resync-modal-background {
    background-color: transparentize($color--bg-primary, 0.5); /* White Transparent Background */
    z-index: 5; /* Sit on top of background, but under the modal */
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  & .commit-menu {
    height: 100%;

    & #resync-modal {
      z-index: 10; /* Sit on top everything */
    }
  }
}

.git-commit-form {
  align-items: stretch;
  display: grid;
  grid-template-rows: 1fr auto;
  grid-template-columns: clamp(300px, 40%, 600px) 1fr;
  grid-template-areas:
    "files diff"
    "footer footer";
  height: 100%;
  overflow: hidden;

  &__files {
    display: flex;
    flex-direction: column;
    height: 100%;
    grid-area: files;
    overflow: hidden;
    padding-right: $spacing--sm;

    &__header {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      margin-bottom: $spacing--sm;

      &__actions {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-evenly;
        margin-left: $spacing--sm;

        & > * + * {
          margin-left: $spacing--sm;
        }
      }
    }

    &__list {
      flex: 1;
      height: 100%;
      overflow: auto;
      margin-bottom: $spacing--sm;

      .ant-empty {
        margin: auto;
      }
    }
  }

  &__footer {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid $color--divider-2;
    grid-area: footer;
    justify-content: flex-end;
    padding-top: $spacing--sm;

    &__commit-message-input.ant-form-item {
      margin-bottom: $spacing--sm;
      max-width: 100%;
      width: 100%;
    }

    &__disabled-button-alert.ant-alert-error {
      flex: 1;
      margin-right: $spacing--md;
      width: 100%;
    }

    &__commit-push-button {
      height: 30px;
      width: 100%;
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;

      & .loading-spinner-with-text {
        margin: 0;
      }
    }

    & .ant-form-item-row {
      width: 100%;
    }

    & .ant-form-item-control-input-content {
      width: 100%;
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
    }

    &__commit-push-button.ant-form-item {
      margin-bottom: 0;
    }

    &__commit-message-input {
      .ai-generate-icon {
        width: $spacing--md;
        height: $spacing--md;
        display: block;
      }

      .ant-input-suffix {
        display: flex;
        align-items: center;

        span {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: $spacing--xxs;
          width: $spacing--lg;
          height: $spacing--lg;

          // Container for both icon and spinner
          > * {
            display: flex;
            align-items: center;
            justify-content: center;
            width: $spacing--md;
            height: $spacing--md;
          }
        }
      }
    }
  }
}
