.version-control-form-commit-modal {
  overflow: hidden;

  &__table {
    height: 100%;
    overflow: hidden;

    & > .ant-table-wrapper {
      height: 100%;
    }

    &__column {
      overflow: ellipsis;

      &--message {
        min-width: 150px;
      }

      &--environment {
        min-width: 200px;
      }
    }
  }
}
