@import "../../styles/styles.scss";

.graph {
  background-color: $color--bg-graph;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  width: 100%;

  &__content,
  &__error,
  &__loading {
    flex: 1;
    height: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;
  }

  &__content {
    background-size: 20px 20px;
    background-image: radial-gradient($color--neutral-7 1px, transparent 0);
    background-position: center;
    cursor: move;

    .react-transform-wrapper {
      height: 100%;
      width: 100%;
    }
  }

  &__error,
  &__loading {
    align-items: center;
    display: flex;
    justify-content: center;
  }
}
