@import "../../../../../web/shared/styles/styles.scss";

.docs-workspaces-list {
  width: 50%;
  &__title {
    font-size: $font-size--lg;
    font-weight: 600;
    margin-bottom: $spacing--sm;
  }
  &__table {
    border-radius: 8px;
  }
  &__item {
    padding-left: $spacing--md;
    padding-right: $spacing--md;
  }
  &__link {
    font-size: $font-size--md;
    font-weight: 400;
    &:link,
    &:visited,
    &:active {
      color: $color--coalesce-blue;
      text-decoration: none;
    }
    &:hover {
      text-decoration: underline;
    }
  }
  &__load-more {
    text-align: center;
    margin-top: $spacing--sm;
    margin-bottom: $spacing--sm;
    height: 32px;
  }
}

.docs-workspaces {
  margin-right: $spacing--sm;
}

.docs-environments {
  margin-left: $spacing--sm;
}

.empty-workspace-description-docs {
  text-align: center;
  color: $color--text-tertiary;
  max-width: 200px;
  margin: 0 auto;
}
