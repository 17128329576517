@import "../../../../../shared/styles/styles.scss";

.git-account {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid $color--divider-1;
  padding: $spacing--md;

  &__info {
    &__name {
      font-size: $font-size--lg;
      font-weight: 600;
    }

    &__description {
      color: $color--text-secondary;
    }
  }

  &__actions {
    & > * + * {
      margin-left: $spacing--xs;
    }
  }
}

.git-account-form-header {
  margin: $spacing--xl 0 $spacing--lg 0;

  &--no-margin-bottom {
    margin-bottom: 0;
  }
}

.git-account-help-text {
  display: block;
  margin-bottom: $spacing--lg;
}
