@import "../../styles/styles.scss";

.commit-table {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  &__alerts {
    & > * + * {
      margin-top: $spacing--md;
    }

    &__alert {
      text-align: start;
    }
  }

  &__toolbar {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;

    &__button.ant-btn {
      padding: 0;
    }
  }

  &__branch-selector {
    align-items: center;
    display: flex;
    flex-wrap: nowrap;

    & > * + * {
      margin-left: $spacing--sm;
    }

    & > span {
      margin-right: $spacing--md;
    }

    &__input {
      flex: 1;
    }
  }

  &__table {
    flex: 1;
    height: 100%;
    margin-top: $spacing--xs;
    overflow: hidden;

    & > .ant-table-wrapper {
      height: 100%;
    }

    &__column {
      overflow: ellipsis;

      &--message {
        min-width: 150px;
      }

      &--author {
        min-width: 150px;
      }

      &--commit {
        min-width: 150px;
      }

      &--date {
        min-width: 100px;
        text-transform: capitalize;
      }

      &--workspace {
        min-width: 200px;
      }

      &--environment {
        min-width: 200px;
      }
    }
  }
}
