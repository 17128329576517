@import "../../../../../web/shared/styles/styles.scss";

.docs-search-top {
  &__breadcrumb {
    align-items: center;
    display: flex;
    grid-column-end: 25;
    grid-column-start: 1;
    padding: 0px $spacing--md;
  }
  &__searchContainer {
    background-color: $color--bg-primary;
    display: flex;
    flex-direction: column;
    grid-column-end: 21;
    grid-column-start: 5;
    justify-content: center;
    margin-bottom: 0px;
  }
  &__titles {
    align-items: flex-end;
    display: flex;
    font-weight: 600;
    margin: 0px;
    &--primary {
      font-size: $font-size--xl;
      line-height: $spacing--xl;
      margin-bottom: 0px;
    }
    &--secondary {
      color: $color--text-secondary;
      font-size: $font-size--lg;
      line-height: $spacing--lg;
      margin-bottom: 0px;
      margin-left: $spacing--xs;
    }
  }
}

.docs-search-results {
  list-style-type: none;
  padding-left: 0px;
}

.docs-search-empty {
  width: 360px;
  &__text {
    &--primary {
      font-size: $font-size--lg;
      font-weight: 500;
      margin-bottom: 0px;
    }
    &--secondary {
      font-size: $font-size--md;
      font-weight: 400;
      margin-bottom: 0px;
    }
  }
}
