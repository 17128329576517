@use "~ag-grid-community/styles" as ag;

@import "./styles.scss";

@include ag.grid-styles(
  (
    theme: balham,
    // this overrides ag default indent size 28px, so we could have normal looking tree grids
    row-group-indent-size: 0px,
    row-border-color: null,
  )
);

.ag-theme-balham {
  font-size: $font-size--xs;

  .ag-drag-handle {
    visibility: hidden;
  }

  .ag-row-selected {
    background-color: $color--brand-blue;

    .ag-cell,
    .ag-cell-value,
    .ag-header-cell-text,
    .ag-drag-handle,
    .ant-dropdown-trigger {
      color: $color--text-primary-inverted;
    }

    &:before {
      background-color: $color--brand-blue;
    }
  }

  .ag-group-expanded,
  .ag-group-contracted {
    margin-right: $spacing--xs;
  }

  .ag-row-selected,
  .ag-row:hover {
    .ag-drag-handle {
      visibility: visible;
    }
  }

  .ag-header-cell-text {
    color: $color--text-primary;
    font-family: $font-family--roboto;
    font-weight: 500;
  }

  .ag-cell,
  .ag-cell-value,
  .ag-header-cell-text {
    flex: 1;
  }

  .ag-cell-value,
  .ag-cell,
  .ag-group-value {
    font-family: $font-family--roboto;
    font-size: $font-size--xs;
    font-weight: 400;

    // START: CD-3616 - override so our custom antd5 context menu area fills up the entires ag-grid cell

    padding: 0;

    & > * {
      height: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
    }

    .ag-drag-handle {
      margin: 0 !important;
      padding: 0 $spacing--xs !important;
    }

    .ant-dropdown-trigger {
      height: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
    }

    // END: CD-3616
  }

  .ag-root.ag-layout-normal {
    width: 100%;
  }

  .ag-body-viewport.ag-layout-normal.ag-row-no-animation {
    width: 100%;
  }

  .ag-root-wrapper.ag-layout-normal.ag-ltr {
    border: 1px solid $color--divider-1;
    width: 100%;
  }

  .ag-header {
    border: none;
    background-color: $color--bg-secondary;
  }

  .ag-pinned-left-header {
    border: none;
  }

  .ag-center-cols-viewport {
    overflow-x: auto;
  }

  // should only be affecting ag grid inline text editors
  .ag-ltr input[class^="ag-"][type="text"] {
    padding-left: 21px;
    color: $color--text-primary;
  }

  // this class added in MappingGrid.GetStandardGridColumnData - extra padding needed to account for drag handle
  .columnNameInlineEditorLeftPadding {
    input[class^="ag-"][type="text"] {
      padding-left: $spacing--xl;
    }
  }

  &.metadataGrid {
    .ag-cell {
      padding-right: 0px;
    }

    .ag-cell-value {
      width: 100%;
    }

    .ag-drag-handle {
      margin-right: 0px;
    }
  }

  // below block sets row group indendation for megagrids and problem tree
  &.ag-grid-megagrid,
  &.problem-scanner {
    .ag-row-group-indent-1 {
      padding-left: $spacing--md; //16px
    }
    .ag-row-group-indent-2 {
      padding-left: calc(2 * $spacing--md);
    }
    .ag-row-group-indent-3 {
      padding-left: calc(3 * $spacing--md);
    }
    .ag-row-group-indent-4 {
      padding-left: calc(4 * $spacing--md);
    }
  }
}
