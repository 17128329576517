.resizable-monaco-editor {
  position: relative;
}

//In Playwright firefox this textarea has a height of 0 if we don't impose
// min-height.
.monaco-editor textarea.inputarea {
  min-height: 1px;
  min-width: 1px;
}

//CD-8765
/* The monaco editor in tabular config is affected by MUI table cell's 
text-align: center (TabularConfigWrapper.tsx <StyledTableCell>), causing text in the editor to center in an unwanted way. Hence we need to override this property
*/
.MuiTableCell-root .monaco-editor .view-line {
  text-align: left;
}
