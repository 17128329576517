.disable-sms-modal-segmented-description {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 2em;
}

.disable-sms-modal-form-reauth {
  display: flex;
  flex-direction: column;
}
