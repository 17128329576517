@import "../../styles/spacing.scss";

.password-input {
  display: flex;
  width: 100%;
  height: 100%;

  button.ant-btn {
    margin-left: $spacing--sm;
  }
}
