@import "../../../web/shared/styles/styles.scss";

.view-selector {
  align-items: stretch;
  display: flex;
  flex-wrap: nowrap;

  &__label {
    background: $color--bg-secondary;
    border: 1px solid $color--divider-2;
    border-radius: 6px 0 0 6px;
    border-right: 0;
    display: inline-block;
    font-size: $font-size--sm;
    line-height: $line-height--22; // to match the height of the small select input
    padding: 0 $spacing--xs;
  }

  &__select {
    &__label {
      margin-left: $spacing--xxs;
    }
  }
}

#view-select-container .ant-select-selector {
  border-radius: 6px;
  width: 130px;
}

#view-select-container .ant-select-selector .ant-select-selection-item,
.view-select-dropdown .ant-select-item .ant-select-item-option-content {
  font-family: "Roboto";
  font-size: 13px;
}

.view-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  font-weight: 500;
}

#view-select-container div.ant-select-selector {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
