@import "../../../web/shared/styles/styles.scss";

.resync-modal-container {
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  background-color: $color--bg-primary;
  gap: $spacing--md;
  margin: auto;
  padding: $spacing--lg $spacing--xl;
  box-shadow: 0 $spacing--xxs $spacing--xs -4px $color--neutral-13;
  border-radius: $spacing--xxs;
  width: 640px;

  & .resync-modal-content {
    display: flex;
    align-items: flex-start;
    & .resync-modal-icon {
      min-width: 22px;

      & svg {
        height: 100%;
        width: 100%;
      }
    }

    & .resync-modal-text,
    .resync-modal-text-skelton {
      display: flex;
      flex-direction: column;
      margin-left: $spacing--md-lg;
      gap: $spacing--md;
      width: 100%;

      & .resync-modal-title {
        font-weight: $font-weight--500;
        font-size: $font-size--lg;
        margin: 0;
      }

      & p {
        margin-bottom: 0;
      }

      & .resync-modal-docs-link {
        margin-left: $spacing--xxs;
        text-decoration: underline;
      }

      .resync-modal-card-container {
        position: relative;
        font-family: $font-family--roboto;
        font-size: $font-size--sm;
        color: $color--text-primary;
        width: 100%;
        margin-top: $spacing--xxs;

        & .resync-modal-card-title {
          position: absolute;
          top: -10px;
          left: $spacing--md;
          display: flex;
          padding: 0 $spacing--xs;
          align-items: center;
          gap: $spacing--xs;
          background-color: $color--bg-primary;
          color: $color--text-secondary;
          line-height: $line-height--20;
          max-width: fit-content;
        }

        & .resync-modal-card-commit-info {
          border-radius: $spacing--xxs;
          border: 1px solid $color--divider-1;
          width: 100%;
          padding: $spacing--lg;
          font-size: $font-size--md;

          & p {
            margin: 0;

            & .resync-modal-oid {
              color: $color--text-secondary;
              font-weight: $font-weight--400;
              font-size: $font-size--sm;
              margin-left: $spacing--xs;
            }
          }
        }
      }
    }
  }

  & .resync-modal-button-handle-resync,
  .resync-modal-button-view-docs,
  .resync-modal-button-discard-resync {
    height: $spacing--xl;
  }

  & .resync-modal-button-handle-resync {
    align-self: flex-end;
    min-width: 124px;
  }

  & .resync-modal-button-view-docs {
    min-width: 98px;
  }

  & .resync-modal-button-discard-resync {
    min-width: 232px;
  }

  & .resync-modal-button-box {
    align-self: flex-end;
    display: flex;
    gap: $spacing--xs;
  }
}
