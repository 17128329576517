@import "../../../../../shared/styles/styles.scss";

.environment {
  &.ant-card {
    border-radius: 6px;
    max-width: 1200px;
    margin-bottom: $spacing--md;
  }

  &__header {
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin: 0 0 $spacing--xs 0;
  }

  &__name {
    margin: 0;
    padding: 0;
  }

  &__description {
    color: $color--text-secondary;
    margin: $spacing--sm 0 0 0;
    padding: 0;
  }

  &__tag {
    color: $color--text-secondary;
  }
}
