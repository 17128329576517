@import "../../../../shared/styles/styles.scss";

$step-padding--xl: 145px;

.create-release {
  display: flex;
  flex-direction: row;
  justify-content: center;
  overflow: scroll;
  height: 98vh;
  background-color: $color--bg-primary;
  padding: $spacing--md-lg;
  font-size: $font-size--lg;

  &__steps {
    padding-top: $step-padding--xl;
    padding-bottom: $step-padding--xl;
    padding-right: $spacing--xxxl;
  }

  &__container {
    min-width: 50vw;
    &__header {
      padding-top: $spacing--xl;
      padding-bottom: $spacing--xl;

      p {
        color: $color--text-secondary;
      }
      &__details {
        p {
          margin-bottom: 0px;
        }
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        &__id {
          font-size: $spacing--sm;
        }
      }
    }
  }

  .create-release-step {
    width: 50vw;

    &__header {
      padding-bottom: $spacing--xl;

      p {
        color: $color--text-secondary;
        padding-top: $spacing--sm;
      }
    }

    &__overflow-content {
      min-height: 35vh;
      max-height: 50vh;
    }

    &__buttons {
      display: flex;
      justify-content: space-between;
      padding-top: $spacing--xl;
      padding-bottom: $spacing--xl;

      &__rh {
        display: flex;
        gap: $spacing--xs;
      }
    }
  }
}
