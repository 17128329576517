@import "../../../../shared/styles/styles.scss";

.canvas {
  display: flex;
  flex: 1;
  height: 100%;
  overflow: hidden;
  width: 100%;

  &__nav {
    display: flex;
    flex-direction: column;
    height: 100%;

    &__tree {
      display: flex;
      flex: 1;
    }
  }
}
