.ant-space.multiFactorSpace {
  :first-child {
    flex-basis: 25%;
    flex: 1 0 auto;
  }
  :last-child {
    flex-basis: 75%;
    flex: 1 0 auto;
  }
}

.modal-parent {
  display: flexbox;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
