@import "../../../../shared/styles/styles.scss";

.workspaces {
  &__no-results {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    &__description {
      width: 360px;
    }
  }

  & > * + * {
    margin-top: $spacing--sm;
  }
}
