@import "../../styles/styles.scss";

.loading-spinner {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }

  &__wrapper {
    height: 100%;
    width: 100%;
  }

  &__img {
    animation: spin 3s linear infinite;
    height: 100%;
    width: 100%;
  }

  &__text {
    margin: $spacing--md 0 0 0;
    padding: 0;
  }
}
