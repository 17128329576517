@import "../../../shared/styles/styles.scss";

.create-edit-package-view {
  & .create-edit-package-overview {
    display: flex;
    flex-direction: column;
    gap: $spacing--lg;

    & .create-edit-package-overview-title {
      margin: 0;
    }

    & .create-edit-package-overview-info {
      color: $color--text-secondary;
      line-height: $line-height--22;
    }

    & .create-edit-package-overview-form {
      display: flex;
      flex-direction: column;
      gap: $spacing--lg;

      & .ant-form-item-label {
        width: 142px;
      }

      & .create-edit-package-overview-form-overview-input {
        width: 100%;
      }
    }
  }

  & .create-edit-package-description {
    display: flex;
    flex-direction: column;
    gap: $spacing--lg;

    & .create-edit-package-description-title {
      margin: 0;
    }

    & .create-edit-package-description-info {
      color: $color--text-secondary;
      line-height: $line-height--22;

      & .create-edit-package-description-info-link {
        color: $color--text-secondary;
        text-decoration: underline;
        padding: 0 $spacing--xxs;
      }
    }

    & .create-edit-package-description-monaco-editor {
      height: 360px;
      width: 100%;
    }
  }
}
