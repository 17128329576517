@import "../../../transform/variables.scss";
@import "../../../web/shared/styles/colors.scss";

.html,
body {
  margin: 0;
  padding: 0;
}

body {
  overscroll-behavior: none;
  overflow-x: hidden;
}

/*
    TABLE OF CONTENTS
    1 - VARIABLES
    2 - HEIGHT/WIDTH
    3 - POSITION
    4 - Z-INDEX
    5 - PADDING
    6 - MARGIN
    7 - BORDER
    8 - OVERFLOW
    9 - TEXT/FONT
    10 - COLOR
    11 - FLEX
    12 - CSS-GRID
    13 - POINTER EVENTS
    14 - AG-GRID
    15 - ANTD
    15.1 - MUI
    16 - UI MODULE
    17 - SPECIFIC-COMPONENTS
        17.5 - SPECIFIC-COMPONENTS:ORGANIZED
        17.52 - REUSABLE COMPILATION CLASSES
        17.6 - TESTING
    18 - FIREBASE
    19 - MISC
    20 - ANIMATIONS
*/

/////===== HEIGHT/WIDTH - START =====/////

.height100Percent {
  height: 100%;
}

.width100Percent {
  width: 100%;
}

.width80Percent {
  width: 80%;
}

.height80Percent {
  height: 80%;
}

.height75Percent {
  height: 75%;
}

.height70Percent {
  height: 70%;
}

.height30Percent {
  height: 30%;
}

.width75Percent {
  width: 75%;
}

.width70Percent {
  width: 70%;
}

.width60Percent {
  width: 60%;
}

.width50Percent {
  width: 50%;
}

.width40Percent {
  width: 40%;
}

.width30Percent {
  width: 30%;
}

.width300px {
  width: 300px;
}

.height200px {
  height: 200px;
}

.height30px {
  height: 30px;
}

.height25px {
  height: 25px;
}

.width50px {
  width: 50px;
}

.minWidth0 {
  min-width: 0;
}

.height100px {
  height: 100px;
}

.columnName {
  height: 18px;
}

.height100Percent > svg {
  @extend .height100Percent;
  @extend .width100Percent;
}

/////===== HEIGHT/WIDTH - END =====/////
/////===== POSITION - START =====/////
.displayContents {
  display: contents;
}

.displayNone.displayNone {
  // double-naming gives precedence over other class names
  display: none;
}

.positionAbsolute {
  position: absolute;
}

.positionRelative {
  position: relative;
}

/////===== POSITION - END =====/////
/////===== Z-INDEX - START =====/////
.zIndexZero {
  z-index: 0;
}

.zIndex1 {
  z-index: 1;
}

/////===== Z-INDEX - END =====/////
/////===== PADDING - START =====/////
.paddingSides5 {
  padding-left: 5px;
  padding-right: 5px;
}

.padding5px {
  padding: 5px;
}

.padding0px {
  padding: 0px;
}

.padding1px,
li.padding0px {
  padding: 1px;
}

.padding20px {
  padding: 20px;
}

.paddingLeft8px {
  padding-left: 8px;
}

.paddingRight8px {
  padding-right: 8px;
}

.paddingRight10px {
  padding-right: 10px;
}

.paddingLeft20px {
  padding-left: 20px;
}

.paddingBottom5px {
  padding-bottom: 5px;
}

.padding10px {
  padding: 10px;
}

.paddingTop5px {
  padding-top: 5px;
}

.paddingTop20px {
  padding-top: 20px;
}

.paddingLeft8px {
  padding-left: 8px;
}

.horizontalPadding8 {
  padding: 0 8px;
}

.paddingHeader8px {
  padding: 12px 8px 4px 8px;
}

.paddingBody8px {
  padding: 4px 8px 12px 8px;
}

.padding8px {
  padding: 8px;
}

.inputPadding,
input.ant-input.inputPadding {
  padding: 4px 11px;
}

.paddingTop5px {
  padding-top: 5px;
}

/////===== PADDING - END =====/////
/////===== MARGIN - START =====/////
.marginAuto {
  margin: auto;
}

.floatRight {
  margin-left: auto;
}

.margin5px {
  margin: 5px;
}

.marginTopAuto {
  margin-top: auto;
}

.marginBottomAuto {
  margin-bottom: auto;
}

.verticalMarginAuto {
  @extend .marginTopAuto;
  @extend .marginBottomAuto;
}

.margin4px {
  margin: 4px;
}

.horizontalMargin8px {
  margin: 0 8px;
}

.verticalMargin20px {
  margin: 20px 0;
}

.margin0 {
  margin: 0 !important;
}

.margin10px {
  margin: 10px;
}

.margin24px16Left {
  margin: 24px 16px 24px 24px;
}

.margin24px16Right {
  margin: 24px 24px 24px 16px;
}

.marginLeftAuto {
  margin-left: auto;
}

.marginRight12px {
  margin-right: 12px;
}

.marginLeft3px {
  margin-left: 3px;
}

.marginLeft4px {
  margin-left: 4px;
}

.marginLeft8px {
  margin-left: 8px;
}

.marginLeft16px {
  margin-left: 16px;
}

.marginLeft30px {
  margin-left: 30px;
}

.marginLeftNegative10px {
  margin-left: -10px;
}

.marginRight4px {
  margin-right: 4px;
}

.marginRight5px,
.ant-row.marginRight5px {
  margin-right: 5px;
}

.marginBottom8px,
.ant-row.marginBottom8px {
  margin-bottom: 8px;
}

.marginBottom10 {
  margin-bottom: 10px;
}

.marginBottom24 {
  margin-bottom: 24px;
}

.marginRight8px {
  margin-right: 8px;
}

.marginRight10px {
  margin-right: 10px;
}

.marginRight32 {
  margin-right: 32px;
}

.marginRight20px {
  margin-right: 20px;
}

.marginRight30px {
  margin-right: 30px;
}

.marginRight35px {
  margin-right: 20px;
}

.marginRight16px {
  margin-right: 16px;
}

.marginTop5px {
  margin-top: 5px;
}

.marginTop10px,
.ant-row.marginTop10px {
  margin-top: 10px;
}

.marginTop25px {
  margin-top: 25px;
}

/////===== MARGIN - END =====/////
/////===== BORDER - START =====/////
.CurrentStep {
  border: 2px solid $color--node-bg-selected;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
}

.NotCurrentStep {
  border: 2px solid transparentize($color--neutral-5, 0.3);
}

.inputBorder {
  border: 1px solid $color--divider-2;
  border-radius: 4px;
}

.errorBorder {
  border: 1px solid $color--dust-red-5;
  border-radius: 4px;
}

.Successor .NotCurrentStep,
.Predecessor .NotCurrentStep {
  border: 2px solid $color--brand-blue;
  box-shadow: 0px 4px 4px transparentize($color--neutral-13, 0.7);
}
.CurrentStep > .ellipseParentWide > .ellipseChild {
  color: $color--neutral-1;
}

.mapping-drop-zone:hover {
  border: 2px solid $color--brand-blue;
}

.formBoxShadow {
  box-shadow: 1px 1px 2px $color--neutral-7;
}

.roundedBorder12pxFull {
  border-radius: 12px;
}

.roundedBorderFull,
.roundedBorderFull > button,
.roundedBorderFull.ant-btn,
.roundedBorderFull.ant-tag {
  border-radius: 6px;
}

.borderNone,
.borderNone > .ant-btn,
.borderNone.ant-btn {
  border: none;
}

.borderRadius2 {
  border-radius: 2px;
}

/////===== BORDER - END =====/////
/////===== OVERFLOW - START =====/////
.overflowHidden {
  overflow: hidden;
}

.overflowYScroll {
  overflow-y: scroll;
}

.overflowYAuto {
  overflow-y: auto;
}

.overflow-x--auto {
  overflow-x: auto;
  &__rtl {
    @extend .overflow-x--auto;
    direction: rtl;
  }
}

.overflowScroll {
  overflow: scroll;
}

/////===== OVERFLOW - END =====/////
/////===== TEXT/FONT - START =====/////
///
.textAlignRight {
  text-align: right;
}

.textAlignLeft {
  text-align: left;
}

.textAlignCenter {
  text-align: center;
}

.fontSmall {
  font-size: 0.8rem;
}

.font1 {
  font-size: $font1;
}

.font3 {
  font-size: $C_standardFontSize;
}

.font4 {
  font-size: $C_headerFontSize;
}

.font45 {
  font-size: $font45;
}

.font12px {
  font-size: $font4;
}

.font13px {
  font-size: 13px !important;
}

.font6 {
  font-size: $font6;
}

.font11px,
h5.ant-typography.font11px {
  font-size: $C_font11px;
}

.font14px {
  font-size: 14px !important;
}

.fontHUGE {
  font-size: 50px;
}

.fontStandard,
.fontStandard h3.ant-typography,
.fontStandard h5.ant-typography,
.fontStandard h4 {
  font-weight: 400;
}

.fontWeight400 {
  font-weight: 400;
}

.fontWeight500 {
  font-weight: 500;
}

.fontWeight700 {
  font-weight: 700;
}

.fontSizeNormal {
  font-size: $C_headerFontSize;
}

.fontRobot {
  font-family: $C_fontFamilyStandard;
}

.fontDark {
  color: $color--text-primary;
}

/////===== TEXT/FONT - END =====/////
/////===== COLOR - START =====/////
.opacity10 {
  opacity: 10%;
}

.opacity50 {
  opacity: 50%;
}

.opacity85 {
  opacity: 85%;
}

.backgroundWhite {
  background-color: $color--bg-primary;
}

.backgroundGray {
  background-color: $color--bg-secondary;
}

.nameBox.CurrentStep {
  //here
  background: $color--node-bg-selected;
  border: 2px solid $color--brand-blue;
}

.dataTypeGray {
  color: $color--text-secondary;
}

.backgroundBrandBlueDark {
  background-color: $color--brand-blue;
}

.colorBrandBlueDark {
  color: $color--brand-blue;
}

.textRed {
  color: $color--text-error;
}

.textLightGray {
  color: $color--text-secondary;
}

.black-font {
  color: $color--text-primary;
}

.standardBackground {
  background-color: $color--bg-view;
}

.white-font {
  color: $color--text-primary-inverted;
}

.SelectedItemBrand {
  background-color: $color--brand-blue;
}

.filteredGrayscale {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.DANGER {
  color: $color--text-error;
}

.textSecondaryColor {
  color: $color--text-secondary;
}

/////===== COLOR - END =====/////
/////===== FLEX - START =====/////
.displayFlex,
.antdSelectForceFlex .ant-select-selection-item {
  display: flex;
}

.justifyContentCenter {
  justify-content: center;
}

.flexEnd {
  justify-content: flex-end;
}

.justifyCenter {
  justify-content: center;
}

.alignItemsBaseline {
  align-items: baseline;
}

.alignItemsCenter {
  align-items: center;
}

.justifyContentFlexStart {
  justify-content: flex-start;
}

.justifyContentFlexEnd {
  justify-content: flex-end;
}

.justifySelfCenter {
  justify-self: center;
}

.alignItemFlexStart {
  align-items: flex-start;
}

.alignItemsFlexEnd {
  align-items: flex-end;
}

.alignSelfCenter {
  align-self: center;
}

.alignSelfFlexStart {
  align-self: flex-start;
}

.alignSelfFlexEnd {
  align-self: flex-end;
}

.rowFlexBox {
  @extend .displayFlex;
  flex-direction: row;
}

.columnFlexBox {
  @extend .displayFlex;
  flex-direction: column;
}

.flexAll {
  flex: 1 1 auto;
}

.flexAllNoAuto {
  flex: 1 1;
}

.flexShrink {
  flex: 0 1 auto;
}

.flexWrap {
  flex-wrap: wrap;
}

.flexNoWrap {
  flex-wrap: nowrap;
}

.flexGrow {
  flex: 1 0 auto;
}

.flexBasis100 {
  flex-basis: 100%;
}

.flexDouble {
  flex: 2 1;
}

.flexGrowTwoParts {
  flex: 2 0 auto;
}

.flexNone {
  flex: none;
}

.flexStay {
  flex: 0 0 auto;
}

.flexStay20 {
  flex: 0 0 20%;
}

.flexStay30 {
  flex: 0 0 30%;
}

.flexStay20 {
  flex: 0 0 20%;
}

.flexStay40 {
  flex: 0 0 40%;
}

.flexStay25px {
  flex: 0 0 25px;
}

.flexStay100px {
  flex: 0 0 100px;
}

.flexStay200px {
  flex: 0 0 200px;
}

.flexStay250px {
  flex: 0 0 300px;
}

.flexStay300px {
  flex: 0 0 300px;
}

.justifyContentEnd {
  justify-content: end;
}

.justifyContentSpaceEvenly {
  justify-content: space-evenly;
}

.justifyContentSpaceBetween {
  justify-content: space-between;
}

.nameBox {
  @extend .displayFlex;
  @extend .positionRelative;
  justify-content: start;
  user-select: none;
  font-size: $C_standardFontSize;
  background-color: $color--bg-primary;
  border-radius: 5px;
  min-width: 200px;
  align-items: center;
  height: 40px;
}

.ellipseComponent {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ellipseParent {
  @extend .displayFlex;
  flex-direction: row;
  align-items: center;
  padding-left: 10px;
}

.ellipseParentColumnName {
  @extend .ellipseParent;
  width: 175px;
}

.ellipseParentWide {
  @extend .displayFlex;
  flex-direction: row;
  align-items: center;
  width: 180px;
  padding-left: 10px;
}

.ellipseChild {
  min-width: 0;
  white-space: nowrap;
  @extend .overflowHidden;
  text-overflow: ellipsis;
}

.ant-page-header-heading-left {
  flex-direction: column;
}

.ant-page-header-heading > .ant-page-header-heading-extra {
  align-self: center;
  margin: 5px 0;
}

/////===== FLEX - END =====/////

/////===== POINTER EVENTS - START =====/////
.hoverHand {
  cursor: pointer;
}

.pointerEventsNone {
  pointer-events: none;
}

/////===== POINTER EVENTS - END =====/////
/////===== AG-GRID - START =====/////

.metadataGrid .ag-row[row-id="-1"] .ag-icon-grip {
  visibility: hidden;
}

.metadataGrid .ag-row[row-id="-1"] .ag-drag-handle {
  cursor: default;
  pointer-events: none;
}

.ag-cell.ag-cell-not-inline-editing.ag-cell-auto-height.ag-cell-value.dataTypeGray {
  color: $color--text-secondary;
}

/////===== AG-GRID - END =====/////
/////===== ANTD - START =====/////

.MappingGridContextMenuShadow {
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%) !important;
}

.StepMenuTransfer .ant-transfer-list-body.ant-transfer-list-body-with-search {
  background-color: $color--bg-primary;
}

.ant-list-sm li.ant-list-item.columnText,
div.columnText,
span.columnText {
  font-size: $C_headerFontSize;
  padding: 5px;
  color: $color--text-secondary;
  font-weight: 400;
}

[data-theme="compact"] .card-container > .ant-tabs-card > .ant-tabs-content > .ant-tabs-tabpane,
.card-container > .ant-tabs-card > .ant-tabs-content > .ant-tabs-tabpane {
  background: $color--bg-primary;
  padding: 5px;
}

[data-theme="compact"] .card-container > .ant-tabs-card > .ant-tabs-bar,
.card-container > .ant-tabs-card > .ant-tabs-bar {
  border-color: $color--bg-primary;
}

.ant-tabs-content-holder {
  background-color: $color--bg-primary;
  display: flex;
  flex-direction: row;
}

.DataView .ant-tabs-nav-list {
  @extend .width100Percent;
}

.ant-card-contain-tabs > .ant-card-head .ant-card-head-title {
  min-height: 16px;
  padding-bottom: 0;
  color: $color--text-secondary;
}

.StepNameContainer .ant-ribbon-wrapper {
  flex: 1 0 auto;
}

.ant-tabs-tab-btn {
  user-select: none;
  font-family: $C_fontFamilyStandard;
}

.anchor-row .ant-anchor {
  display: flex;
  flex-direction: row;
}

.ant-tag > .anticon + span {
  margin-right: 0px;
}

.ant-transfer-list-content-item {
  line-height: 10px;
  font-size: $C_standardFontSize;
}

.ant-input.ant-transfer-list-search {
  padding-left: 4px;
}

.ant-transfer-list-search-action .anticon {
  vertical-align: 0.15em;
}

.ant-page-header-heading {
  margin: 0 0 3px 0;
}

.ant-page-header-heading-title {
  margin-left: -1px;
}

/*
- this is only used in settings.tsx, except for multi-factor-settings-form, so leaving it here for now
*/
.multi-factor-settings-form {
  .justifyContentFlexEnd {
    .ant-col.ant-col-16.ant-col-offset-5.ant-form-item-control {
      justify-content: flex-end;
    }
  }
  .ant-space-item {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .ant-row.ant-form-item {
    display: flex;
    justify-content: center;
  }
  .ant-col-8,
  .ant-col-5 {
    display: flex;
    justify-content: center;
    flex: 0 0 auto;
  }
  .ant-col-8 {
    width: 25%;
  }
  .ant-col-5 {
    @extend .width100Percent;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .ant-col-16.ant-form-item-control {
    width: 45%;
    flex: 0 0 auto;
  }
  .ant-col-offset-5 {
    margin-left: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    @extend .width100Percent;
  }
  .ant-btn.ant-btn-primary.ant-btn-sm {
    margin-left: 6.5em;
  }
}

.filterButton.ant-btn.ant-btn-link {
  padding: 0px;
  margin-right: 8px;
  span {
    font-size: 13px;
  }
}

.ant-cascader-picker {
  flex: 1 1 auto;
}

.default-font,
.ant-cascader-input {
  font-size: $C_standardFontSize;
  line-height: 20px;
}

.ant-cascader-menu .ant-cascader-menu-item {
  padding: 1.5px 12px;
}

.extra-tab .ant-unchecked-background {
  background-color: $color--text-success;
}

.anticon-check-circle > svg {
  color: $color--text-success;
}

.anticon-minus-circle > svg {
  color: $color--neutral-7;
}

.ant-form-item-control-input-content {
  display: flex;
}

.ant-checkbox-checked::after {
  border: none;
}

///// BUTTON COLORING
///
.ag-row-selected .fontRobot {
  @extend .white-font;
}

.ant-tabs-nav-list > .ant-tabs-tab.ant-tabs-tab-active > .ant-tabs-tab-btn,
.ant-tabs-tab.ant-tabs-tab-active.ant-tabs-tab-with-remove .ant-tabs-tab-btn {
  color: $color--brand-blue;
}

.ant-btn-group.ant-dropdown-button .ant-btn-primary:first-child:not(:last-child) {
  border-right-color: $color--brand-blue;
  border-radius: 6px 0 0 6px;
}

.ant-btn-group.ant-dropdown-button .ant-btn-primary:last-child:not(:first-child),
.ant-btn-group .ant-btn-primary + .ant-btn-primary {
  border-left-color: $color--brand-blue;
  border-radius: 0 6px 6px 0;
}

.borderNone > .ant-btn:disabled {
  background: none;
  color: $color--text-secondary;
}

///// END BUTTON COLORING

.ant-tabs-nav-list > .ant-tabs-ink-bar {
  background: $color--brand-blue;
}

.ant-tabs-content-holder {
  margin-left: 0;
}

.rightTab .ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab {
  @extend .padding8px;
  .ant-tabs-tab-btn {
    @extend .width100Percent;
    .anticon {
      margin-right: 0px;
    }
  }
}

.rightTab.ant-tabs > .ant-tabs-nav {
  margin: 0 0 4px 0;
}

.rightTab.ant-tabs-right > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane {
  padding-right: 0px;
}

.ant-tabs-tabpane.ant-tabs-tabpane-active.config-pane {
  margin: 0px;
}

.ant-tabs-nav-wrap {
  padding-top: 8px;
}

.ant-modal.standardBackground {
  padding: 0;
}

.standardBackground .ant-modal-body {
  background-color: $color--bg-view;
  padding-bottom: 16px;
}

.ant-list-item .ant-list-item-action {
  margin-left: 0;
}

thead.ant-table-thead .ant-table-cell,
.ant-form-item-required.ant-form-item-no-colon,
.ant-form-item-no-colon,
.ant-form-item-font-override {
  @extend .fontStandard;
}

.joinError {
  .ant-alert-message {
    @extend .font3;
  }
}

.StepMenuTransfer .ant-transfer-list-body.ant-transfer-list-body-with-search {
  @extend .positionRelative;
  overflow-y: scroll;
  > .ant-transfer-list-content {
    @extend .positionAbsolute;
    @extend .width100Percent;
    top: 4em;
  }
}

.columnEditorCard .ant-card-head-wrapper > .ant-card-head-title,
div.ant-select-dropdown {
  @extend .fontRobot;
  @extend .fontStandard;
  @extend .black-font;
  @extend .font11px;
}

.ant-form.ant-form-vertical .ant-form-item-control-input-content > .ant-input {
  padding: 4px 11px;
}

.hidePopoverContent .ant-popover-inner-content {
  @extend .displayNone;
}

.ant-cascader-picker-show-search .ant-cascader-input.ant-input {
  height: 32px;
}

.SearchTextArea.ant-input.fontRobot {
  @extend .font45;
}

.nodes-selector-container {
  padding: 0 10px;

  #nodeTypeEditorNodeSelector {
    max-width: calc(100% - 46px);
    width: 100%;

    .ant-select-selection-item {
      font-size: 0.8rem;
    }
  }

  &__refresh-button {
    width: 46px;
  }
}

#bulkEditorAttributeSelector {
  margin-bottom: 25px;
}

.ant-select.roundedBorderFull div.ant-select-selector {
  border-radius: 6px;
}

.ant-btn-text.textRed span {
  color: $color--text-error;
}

/////===== ANTD - END =====/////
/////===== MUI - START =====/////

/////===== MUI - END =====/////
/////===== UI MODULE - START =====/////

.transformIndicator {
  @extend .positionAbsolute;
  right: 2px;
  top: 2px;
  padding-bottom: 8px;
}

.tableNameSecondaryTitle {
  min-width: 80px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  font-size: $font3;
  color: $color--text-secondary;
}

.columnDataTypeDisplay {
  font-size: $font4;
  color: $color--text-secondary;
}

.tableNameSecondaryText {
  @extend .tableNameSecondaryTitle;
  @extend .overflowHidden;
  text-overflow: ellipsis;
  font-size: $font1;
  max-width: 300px;
}

.CurrentStep .tableNameSecondaryText {
  color: $color--text-primary-inverted;
}

.columnNamePrimaryText {
  @extend .overflowHidden;
  max-width: 400px;
  text-overflow: ellipsis;
}

.radio-btn-override {
  display: inline-flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  width: 120px;
  padding: 0 !important;
}

.btnFontSize13 {
  font-size: 13px;
}

/////===== UI MODULE - END =====/////
/////===== SPECIFIC-COMPONENTS - START =====/////

/** Datasets Tree - start **/

.immutableFolderWrapper {
  display: flex;
  align-items: center;
}

.categoryParentCell {
  .ag-cell-wrapper {
    .ag-drag-handle {
      display: none !important;
    }
  }
}

.dataTreeHeaderButton {
  padding: 0 7px;
  margin-left: 6px;
}

.treeGridHeadingContainer {
  display: flex;
  align-items: center;

  svg:first-child {
    // specific to svg for appearance centering
    margin-bottom: 3px;
    font-size: 26px;
    color: $color--neutral-7;
    margin-right: 10px;
  }
}

#column-viewer-grid {
  .ag-header {
    height: 32px !important;
    min-height: 32px !important;
  }
}

/** Datasets Tree - end **/

.activeLineage.LinkLine {
  stroke-width: 1.5px;
  stroke: $color--brand-blue;
}
//column lineage
.tempColumn {
  color: $color--text-tertiary;
}

.SelectedItemBrand .tempColumn {
  color: $color--text-primary-inverted;
}

.ColumnLineageNode.SelectedItemBrand {
  color: $color--text-primary-inverted;
}

.ColumnLineageNode.CurrentColumnLineageNode {
  color: $color--text-primary-inverted;
}

.CurrentLineageStep {
  border: 4px solid $color--brand-blue;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  background: $color--node-bg-selected;
}

.NotCurrentLineageStep {
  border: 1px solid $color--divider-2;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  background: $color--bg-primary;
}

.LineageStepTitle {
  width: 225px;
  border-radius: 12px 12px 0px 0px;
  background: $color--neutral-3;
}

.LineageStepTitle.CurrentNodeTitle {
  background: $color--brand-blue;
  color: $color--bg-primary;
}

.LineageStepTitleTop {
  height: 9px;
  min-width: 225px;
  border-radius: 8px 8px 0px 0px;
}

.GraphStepTitleSide {
  @extend .height100Percent;
  width: 6px;
  border-radius: 3.5px 0px 0px 3.5px;
  margin-left: -0.5px;
}

.inactiveLineage.LinkLine {
  stroke-width: 1px;
  stroke: transparentize($color--neutral-13, 0.7);
}

path.Edge {
  stroke: transparentize($color--neutral-13, 0.65);
  fill: transparent;
}

tr.ResultTableRow {
  td {
    padding: 2px !important;
    margin: 0px !important;
  }
}

.errorMessage {
  margin-bottom: 5px;
  font-size: 15px;
  max-width: 100vw;
  max-height: 100px;
  overflow: auto;
}

.isAddSqlActivated {
  @extend .positionAbsolute;
  @extend .width100Percent;
  @extend .height100Percent;
  background-color: $color--neutral-7;
  top: 0px;
}

.emptyNameActivated {
  @extend .positionAbsolute;
  @extend .width100Percent;
  @extend .height100Percent;
  background-color: transparentize($color--neutral-7, 0.5);
  top: 0px;
  left: 0px;
  z-index: 1;
}

.ResultsWidgetContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  @extend .positionRelative;
  height: 20px;
  width: 20px;
}

.ResultsWidgetContainer .ResultDiv {
  flex: 1 0 auto;
  font-size: $C_headerFontSize;
  @extend .positionAbsolute;
}

.LinkCanvas {
  @extend .width100Percent;
  @extend .height100Percent;
  overflow: visible;
}

.NodeContainer {
  pointer-events: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.LinkLine.SuccessorLink,
.LinkLine.PredecessorLink {
  stroke-width: 1.5px;
  stroke: $color--brand-blue;
}

.StepNodeContainer.NodeOut {
  background-color: $color--bg-primary;
  border: 2px solid $color--divider-1;
  .nameBox {
    color: $color--text-tertiary;
  }
}

.justifiedIcon > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.config-pane {
  margin: 10px;
  @extend .height100Percent;
  padding-bottom: 20px;
}

.mapping-drop-zone {
  height: 75%;
  width: 98%;
  background-color: $color--neutral-4;
  border-radius: 10px;
  padding: 10px;
  margin: 12px;
  text-align: center;
  color: $color--text-tertiary;
  // keep the border hidden to prevent the ui shifting when it appears
  border: 2px solid $color--neutral-4;
}

.TaskWrapper {
  @extend .height100Percent;
  @extend .width100Percent;
}

.treeContainer {
  @extend .height100Percent;
  @extend .width100Percent;
  @extend .positionRelative;
  cursor: move;
}

svg:not(:root).LinkCanvas {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  @extend .positionAbsolute;
  overflow: visible;
}

.NodeCanvas {
  pointer-events: none;
  transform-origin: 0 0;
  @extend .width100Percent;
  @extend .height100Percent;
  overflow: visible;
}

.StepNodeContainer {
  @extend .positionAbsolute;
  pointer-events: auto;
  border-radius: 5px;
}

.LinkLine {
  stroke: $color--neutral-5;
  stroke-width: 1px;
  fill: none;
  transition: stroke 100ms;
  pointer-events: stroke;
}

.LinkCanvas {
  pointer-events: all;
}

.LinkLine:hover,
.LinkLine.SuccessorLink:hover,
LinkLine.PredecessorLink:hover {
  stroke: transparentize($color--neutral-13, 0.55);
  stroke-width: 3px;
}

.TreeLayerContainer {
  @extend .positionAbsolute;
}

.TreeTaskItems {
  pointer-events: none;
  @extend .height100Percent;
  @extend .width100Percent;
}

.header {
  grid-area: header;
  background-color: $color--bg-primary;
}

.col,
.column {
  overflow: auto;
}

.isBackdropActivated {
  @extend .positionAbsolute;
  z-index: 1;
  top: 0;
  left: 0;
  background: transparentize($color--neutral-7, 0.45);
  @extend .width100Percent;
  @extend .height100Percent;
}

i.icon.Status {
  margin-right: 0px;
}

.git-settings-subheader {
  margin-bottom: 40px;
  margin-top: 20px;
}

.graphStep {
  text-align: center;
}

.graphStepContainer {
  border-radius: 20%;
}

.RealNodesContainer.IsDragging {
  * {
    pointer-events: none;
  }
  .DragSource {
    pointer-events: auto;
  }
}

.z-index > .ant-tabs-nav > .ant-tabs-extra-content {
  z-index: 3 !important;
  background: $color--bg-primary;
}

.wizardStepContentParameters {
  text-align: start;
}

.wizardProgress {
  @extend .marginTop10px;
  @extend .padding10px;
  @extend .rowFlexBox;
  @extend .alignItemsFlexEnd;
  @extend .justifyContentFlexEnd;
  border-top: 1px solid $color--divider-1;
}

.wizardProgress .ant-typography {
  color: $color--text-tertiary;
}

.monacoToolTipIcon {
  position: absolute;
  font-size: 13px;
  z-index: 100;
  right: 16px;
  top: 2px;
  color: $color--text-tertiary !important;
  cursor: help;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  will-change: color;
  &:hover {
    color: $color--coalesce-blue !important;
  }
}

.simpleTableRow {
  &:nth-of-type(odd) {
    background-color: $color--neutral-2;
  }
  .MuiTableCell-root {
    border: 0;
  }
}

/////Data.tsx
.colHeader.columnSorting.sortAction,
.relative > .rowHeader {
  @extend .font4;
  @extend .black-font;
}

.StepNameContainer {
  @extend .fontStandard;
  font-size: 16px;

  .ant-typography.ant-typography-edit-content.ant-typography-span {
    @extend .rowFlexBox;
    @extend .flexGrow;
    margin: 8px;
    margin-right: 0px;
  }
}

/////===== TESTING -START =====/////

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
