@import "../../../../web/shared/styles/styles.scss";

.job-schedule-container {
  background-color: $color--bg-primary;
  width: 100%;
  height: 100%;

  .job-schedule-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .job-schedule-navigation {
      margin-bottom: $spacing--xxs;
      margin-top: $spacing--sm;
      padding: $spacing--md $spacing--lg;

      &__header-layout {
        background-color: $color--bg-primary;
        gap: $spacing--md;
        padding-top: $spacing--sm;
        padding-bottom: $spacing--xxs;

        &__sider {
          background: none;
        }

        &__button {
          display: flex;
          flex-direction: row;
          justify-content: end;
          gap: $spacing--sm;

          .job-schedule__view-all {
          }
        }
      }
    }
  }
}
