.environment-health-status {
  &__container {
    margin-left: 6px;
    opacity: 0.8;
    scale: 0.9;
  }

  &__tag {
    border-radius: 4px;
  }
}
