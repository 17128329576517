@import "./styles.scss";

/**
 * Global style overrides for the react-split component.
 * 
 * Currently:
 *  - gives the gutter a background color and default widths/ heights
 *  - gives the gutter a hover color and a special cursor (when not disabled)
 */

.gutter {
  background-color: $color--divider-1;
  z-index: 10;

  &:hover {
    background-color: $color--divider-2;
  }

  &.gutter-horizontal {
    cursor: col-resize;
    height: 100%;
    width: 4px;
  }

  &.gutter-vertical {
    cursor: row-resize;
    height: 4px;
    width: 100%;
  }
}

.vertical-split,
.horizontal-split {
  &--disabled {
    & > .gutter {
      pointer-events: none;

      &:hover {
        background-color: $color--divider-1;
      }
    }
  }
}
