@import "../../../shared/styles/styles.scss";

.run {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 12px;

  & > * {
    background-color: $color--bg-primary;
    border-radius: 6px;
    padding: 12px;

    & + * {
      margin-top: 12px;
    }
  }
}
