@import "../../../../../../shared/styles/styles.scss";

.deploy-runs-display-overview {
  &__container {
    width: 100%;
    padding: $spacing--sm;

    &--not-visible {
      display: none;
    }
  }

  &__title {
    display: flex;
    flex-direction: row;
    height: 25px;
    padding-right: 10px;
  }
}
