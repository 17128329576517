@import "../../styles/styles.scss";

.invalid-route {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  margin: 0 auto;
  width: 100%;

  &__inner {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: -6rem;
  }

  &__header {
    margin: $spacing--lg 0 0 0;
  }

  &__text {
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    margin: $spacing--lg 0 0 0;
  }

  &__pathname {
    display: inline-block;
    font-weight: 600;
    margin-right: $spacing--xs;
    max-width: 400px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: nowrap;
  }

  &__img {
    display: block;
    height: 6rem;
    width: 6rem;

    &--rotating {
      animation: rotate 3s linear infinite;
    }
    @keyframes rotate {
      to {
        transform: rotate(360deg);
      }
    }
  }
}
