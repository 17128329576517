@import "../../../../shared/styles/styles.scss";

.node-types {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  width: 100%;

  &__table {
    flex: 1;
    height: 100%;
    overflow: hidden;
    width: 100%;

    &__column {
      &__inner {
        &--flex {
          align-items: center;
          display: flex;
          flex-wrap: nowrap;
        }
      }

      &--status {
        min-width: 80px;
      }

      &--enabled {
        min-width: 90px;
      }

      &--name {
        min-width: 100px;
      }

      &--package {
        min-width: 100px;
      }

      &--default-storage-location {
        min-width: 200px;
      }

      &--actions {
        min-width: 80px;
      }
    }

    &__package-id {
      color: $color--text-secondary;
      font-size: $font-size--sm;
      margin-top: $spacing--xxs;
    }
  }

  .node-types__readonly-alert {
    margin-bottom: $spacing--md-lg;
  }
}
