@import "../../../../../../web/shared/styles/styles.scss";

.docs-node-configuration {
  margin-bottom: $spacing--xl;
  border-radius: 8px;
}

#configuration .ant-card-body {
  padding-top: 0px;
}
