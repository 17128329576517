@import "../../../shared/styles/styles.scss";

$display-width: 832px;

#edit-package {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  background-color: $color--bg-primary;

  & .sticky-header-wrapper {
    &__breadcrumb {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: $spacing--lg;

      & .edit-package-nav {
        padding: $spacing--md-lg 0 0 0;
        width: $display-width;
      }
    }

    &__header {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: $spacing--lg;

      & .edit-package-title {
        margin: 0;
        width: $display-width;
      }
      & .edit-package-error-banner {
        margin: 0;
        width: $display-width;
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: $spacing--lg;

      & .create-edit-package-view {
        width: $display-width;

        & .create-edit-package-loading-overview-form {
          display: flex;
          flex-direction: column;
          gap: $spacing--lg;
          padding: 0 0 $spacing--lg 0;

          & .ant-skeleton-input {
            width: 100%;
          }
        }
      }

      & .edit-package-button-box {
        display: flex;
        width: $display-width;
        justify-content: space-between;
        padding: 0 0 $spacing--lg 0;

        .ant-btn-primary:enabled {
          background-color: $color--coalesce-blue;
          border: none;
        }
      }
    }
  }
}

.edit-package-success-message-name {
  font-weight: $font-weight--600;
}
