@import "../../../../../shared/styles/styles.scss";

.form-header-container {
  display: flex;
  justify-content: right;
}

.add-another-container {
  display: flex;
  justify-content: center;
  margin: $spacing--sm;
}

.default-storage-location-container {
  margin-top: $spacing--xl;
}
