@import "../../../web/shared/styles/styles.scss";

.node-description {
  display: flex;
  align-items: center;
  gap: $spacing--xxs;
  line-height: $font-size--l-xl;
  max-width: 500px;
  width: fit-content;
  font-size: $font-size--sm;
  cursor: pointer;

  &__text {
    margin: 0;
  }

  &__edit-icon.anticon-edit {
    color: $color--coalesce-blue;
    font-size: $font-size--lg;
    visibility: hidden;
  }

  &__popover {
    display: flex;
    flex-direction: column;
    gap: $spacing--xs;
  }

  &__popover-button-span {
    width: fit-content;
  }

  &__popover-button-tooltip.ant-tooltip {
    & .ant-tooltip-inner {
      width: 270px;
    }
  }

  &__popover-button.ant-tooltip-disabled-compatible-wrapper {
    width: fit-content;
  }

  &__popover-button.ant-btn {
    display: flex;
    width: fit-content;
    align-items: center;
    gap: $spacing--sm;
  }
}

.node-description:hover .node-description__edit-icon.anticon-edit {
  visibility: visible;
}

.node-description-overlay {
  width: 400px;

  & .ant-popover-inner-content {
    width: 100%;
  }
}

.node-description-tooltip.ant-tooltip {
  & .ant-tooltip-content {
    margin-left: $spacing--md-lg;
  }

  & .ant-tooltip-inner {
    width: 400px;
    font-size: $font-size--sm;
  }
}
