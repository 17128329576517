@import "../../../../../../shared/styles/styles.scss";

.job-editor {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__name {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: $spacing--xxs $spacing--sm;

    &__editor {
      align-items: center;
      display: flex;
      flex: 1;
      flex-wrap: nowrap;
      width: 100%;
      &__icon {
        width: $spacing--md;
        height: $spacing--md;
      }
    }
  }

  &__split {
    width: 100%;
    height: 100%;
  }

  &__content {
    display: flex;
    height: 100%;
    width: 100%;
  }

  &__grid {
    height: 100%;
    width: 100%;
  }

  &__data-viewer {
    display: flex;
    flex-direction: row;
  }
}
