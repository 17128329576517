@import "../../../web/shared/styles/styles.scss";

// Highlighting Conflicts and Dangling Markers Style
.current-change {
  background-color: $color--conflict-current;
  width: 100%;
}

.current-change-code {
  background-color: $color--conflict-current-light;
  width: 100%;
}

.incoming-change-code {
  background-color: $color--conflict-incoming-light;
  width: 100%;
}

.incoming-change {
  background-color: $color--conflict-incoming;
  width: 100%;
}

.dangling-conflict-marker.mtk5 {
  color: $color--text-error;
}

//Modal Style
.merge-conflict-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

// Modal Header Style
.merge-conflict-content-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  & p {
    margin: 0;
    font-weight: $font-weight--500;
  }

  & .merge-conflict-select-changes-buttons {
    display: flex;
    align-items: center;
    gap: $spacing--xs;

    & .merge-conflict-changes-button {
      display: flex;
      align-items: center;
      gap: $spacing--xs;

      & .merge-conflict-current-changes-icon,
      & .merge-conflict-incoming-changes-icon {
        border-radius: $spacing--xxxs;
        height: $spacing--sm;
        width: $spacing--sm;
      }

      & .merge-conflict-current-changes-icon {
        background-color: $color--conflict-current-dark;
      }

      & .merge-conflict-incoming-changes-icon {
        background-color: $color--conflict-incoming-dark;
      }
    }
  }

  & .merge-conflict-header-previous-next-buttons {
    display: flex;
    gap: $spacing--xs;

    & .ant-btn {
      padding: 0 $spacing--xxs;
      max-height: $spacing--lg;
    }
  }
}

// The next two selectors are to set the width of the inline buttons container, helps push buttons to the right
.contentWidgets {
  width: 100vw;
}

.contentWidgets > .merge-conflict-select-changes-button-box {
  width: 100%;
}

// When conflict is on first or second line adds a margin so the button isn't cut off
.merge-conflict-select-changes-button-box-first-line {
  margin-top: $spacing--xl;
}

.merge-conflict-select-changes-button-box-second-line {
  margin-top: $spacing--md-lg;
}

// Style for in-line Buttons
.merge-conflict-select-changes-button-box > .merge-conflict-select-changes-buttons {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: $spacing--xs;
  float: right;
  padding-right: $spacing--lg;
  height: $spacing--xl;

  & .merge-conflict-changes-button:hover {
    color: $color--coalesce-blue;
    border-color: $color--coalesce-blue;
  }

  & .merge-conflict-changes-button {
    display: flex;
    align-items: center;
    gap: $spacing--xs;
    white-space: nowrap;
    overflow: hidden;
    border: 1px solid $color--divider-2;
    background-color: $color--bg-primary;

    & .merge-conflict-current-changes-icon,
    & .merge-conflict-incoming-changes-icon {
      border-radius: $spacing--xxxs;
      height: $spacing--sm;
      width: $spacing--sm;
    }

    & .merge-conflict-current-changes-icon {
      background-color: $color--conflict-current-dark;
    }

    & .merge-conflict-incoming-changes-icon {
      background-color: $color--conflict-incoming-dark;
    }
  }
}

// Conflict Editor Style
.merge-conflict-monaco-editor {
  display: flex;
  height: 100%;
  margin: $spacing--md 0;
  box-shadow: inset 0px -12px $spacing--sm -12px rgba(0, 0, 0, 0.2);
}

.merge-conflict-instructions-alert.ant-alert {
  margin-bottom: $spacing--md-lg;
}

// Footer Style
.merge-conflict-instructions-alert.ant-alert {
  border-radius: $spacing--xs;
  margin-bottom: $spacing--md-lg;
}

.merge-conflict-footer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: $spacing--xs;
}

.warningBorder {
  border: 1px solid $color--calendula-gold-5;
  border-radius: 4px;
}
