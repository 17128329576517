@import "../../../../../shared/styles/styles.scss";

.current-workspace-nav-bar {
  align-items: center;
  display: flex;
  padding: $spacing--xs;

  &__back-button-wrapper {
    margin-left: $spacing--xs;
    width: calc(60px - $spacing--xs); // width of treeCategoriesSideBar minus the padding around parent
  }

  &__project-and-workspace-name {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    &-wrapper {
      align-items: center;
      display: flex;
      flex: 1;
      flex-wrap: nowrap;
      justify-content: space-between;
      overflow: hidden;
      width: 100%;
    }
  }

  &__workspace-name {
    font-size: $font-size--md;
  }

  &__project-name {
    color: $color--text-secondary;
    font-size: $font-size--sm;
    margin-bottom: $spacing--xxs;
  }

  &__edit-workspace-button {
    margin-left: $spacing--xs;
  }
}
