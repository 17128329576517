@import "../../../../shared/styles/styles.scss";

.org-member-container {
  display: flex;
  flex-direction: column;
}

.org-member-details {
  color: $color--text-secondary;
  font-size: $font-size--sm;
}
