@import "../../../../../../web/shared/styles/styles.scss";

.docs-breadcrumb-links > .ant-anchor {
  & > .ant-anchor-link > .ant-anchor-link-title {
    font-size: $font-size--sm;
    font-weight: 400;
    color: $color--text-secondary !important;
    &-active {
      color: $color--coalesce-blue !important;
    }
  }
  & > .ant-anchor-ink-visible::before {
    background-color: $color--coalesce-blue !important;
  }
}

.docs-anchor-title {
  font-size: $font-size--sm;
  font-weight: 500;
  color: $color--text-secondary;
}
