@import "../../../../shared/styles/styles.scss";

.git-accounts {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__cards {
    flex: 1;

    & > * + * {
      margin-top: $spacing--md;
    }
  }
}
