@import "../../styles/styles.scss";

.deployed-in-environment-tags-list {
  display: flex;
  flex-wrap: wrap;
  gap: $spacing--xxs;
  max-height: 80px;
  max-width: 400px;

  & > * {
    overflow: hidden;

    & > .ant-tag {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
    }
  }
}
