@import "../../../../../../../shared/styles/styles.scss";

.environment-members {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  &__header {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-bottom: $spacing--sm;
  }

  &__alert.ant-alert {
    margin-bottom: $spacing--sm;
  }

  &__table {
    flex: 1;
    height: 100%;
    min-height: 300px;
    overflow: hidden;
    width: 100%;

    &__column {
      &--member {
        display: flex;
        min-width: 100px;
      }

      &--role {
        min-width: 100px;
      }

      &--actions {
        min-width: 160px;
      }
    }
  }

  .environment-member-role-text {
    text-transform: capitalize;
    margin-left: $spacing--sm;
  }
}

.org-member-container--environment {
  gap: $spacing--xs;
}
