@import "../../../../transform/variables.scss";
@import "../../../../web/shared/styles/styles.scss";

.run-controls-and-status__action-button span {
  font-size: 13px;
  font-family: $C_fontFamilyStandard;
}

/*
* some of these properties have to be set manually
* because of a previous rule applying to `.ant-btn.ant-btn-primary.ant-btn-primary[disabled]`
*/
.run-controls-and-status__action-button .ant-btn.ant-btn-primary.ant-btn-primary[disabled],
.run-controls-and-status__action-button.ant-btn.ant-btn-primary.ant-btn-sm[disabled],
.run-controls-and-status__action-button .ant-dropdown-trigger[disabled] {
  opacity: 0.5;
  background: $color--brand-blue;
  color: $color--text-primary-inverted;
  border: none;
}

.run-controls-and-status__action-button .ant-dropdown-trigger[disabled] {
  border-radius: 0 2px 2px 0;
  pointer-events: none;
}

.run-controls-and-status__action-button
  .ant-btn-compact-item[disabled]:not(.ant-btn-compact-last-item):not(.ant-btn-compact-item-rtl) {
  margin-right: 0;
}

.run-controls-and-status__action-button .ant-btn,
.run-controls-and-status__action-button .ant-btn::before {
  transition: none;
}
