@import "../../web/shared/styles/styles.scss";

.ant-typography.node-type-name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: width 0.5s;

  &.ant-typography-edit-content {
    width: 100%;
  }
}

.node-type-id {
  flex: 1;
  white-space: nowrap;
}

.template-editor {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__header {
    margin: 5px 10px;
    font-size: 16px;

    .ant-typography.ant-typography-edit-content.ant-typography-span {
      margin: $spacing--xs;
      margin-right: 0px;
    }
  }

  &__content {
    display: flex;
    flex: 1;
    width: 100%;
  }
}

.reload-node-error {
  margin: $spacing--md $spacing--xl $spacing--sm 0px;
}
