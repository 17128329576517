@import "../../../../../../shared/styles/styles.scss";

.oauth-modal {
  &__footer {
    display: flex;
    justify-content: space-between;
  }

  // needed to override default antd styling
  > .ant-modal-content {
    > .ant-modal-body {
      padding: 0 !important;
    }

    > .ant-modal-footer {
      padding: $spacing--md !important;
    }
  }
}
