@import url("https://fonts.googleapis.com/css?family=Mukta:300|Roboto+Mono:100,200,300,400,500,600,700,800,900|Permanent+Marker|Lato|Rubik|Roboto|Roboto:100,500,600,700,800,900|Open+Sans&display=swap");
//font-family: 'Roboto', sans-serif;

// #region OLD

// A lot of these variables were defined a long time ago - we need to move towards standardizing with what's
// on figma. See the section above.
//
// If you are adding new variables from figmas you may be working with, be sure to add them in the NEW section
// above.

/////===== FONTS =====/////

$C_fontFamilyStandard: "Roboto", sans-serif;

/////===== FONT SIZES =====/////
$C_standardFontSize: 10px;
$C_headerFontSize: 11px;

$font1: 8px;
$font3: 10px;
$C_font11px: 11px;
$font4: 12px;
$font45: 13px;
$font6: 16px;
