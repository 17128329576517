@import "../../../../../transform/variables.scss";
@import "../../../../shared/styles/styles.scss";

.environment {
  &__card {
    border: 1px solid $color--neutral-1;
    border-radius: 6px;
    background: $color--bg-primary;
    flex: 0 0 auto;
    margin-bottom: 8px;
    overflow: hidden;
    width: 100%;

    // I really don't like antd
    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
      display: flex;
      align-items: center;
      padding: 0px $spacing--md;
    }
  }

  &__title {
    &-container {
      display: flex;
      flex-direction: column;
    }

    &-primary-label {
      align-items: center;
      display: flex;
      flex-direction: row;
      font-weight: $font-weight--500;
      padding-left: 10px;

      > * {
        min-width: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    &-id-label {
      color: $color--text-secondary;
      font-size: 0.7rem;
      margin-left: 8px;
      padding-top: 6px;
    }

    &-secondary-label {
      color: $color--text-secondary;
      font-size: 0.7rem;
      font-weight: lighter;
      margin-bottom: -10px;
      margin-left: 10px;
      margin-top: -8px;
    }
  }

  &__extra {
    &-container {
      align-items: center;
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }

    &-overview {
      margin-right: 20px;
    }

    &-action-menu {
      background-color: $color--bg-primary;
      border-radius: 6px;
      border: none;
    }

    &-action-menu-button {
      height: 25px;
    }
  }

  &__divider {
    padding-left: 10px;
    padding-right: 10px;
  }
}
