@import "../../../../shared/styles/styles.scss";

.create-workspace-modal {
  & .ant-modal-header {
    border-bottom: 0 !important;
    height: 75px !important;
    overflow: hidden;
  }

  & .ant-modal-title {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  &__title {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: end;
  }

  &__header-logo {
    position: absolute;
    height: 100px;
    top: -25px;
    left: -50px;
  }

  &__header-container {
    position: relative;
    width: 100%;
  }
}

.create-workspace-steps-icon {
  &__container {
    // this is the static size of the container antd gives to put an icon in
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__icon {
    height: 50%;
    width: 50%;
    border-radius: 50%;
    border-width: 3px;
    border-style: solid;

    &-waiting {
      border-color: $color--neutral-7;
    }

    &-complete {
      border-color: $color--coalesce-blue;
    }
  }
}

.create-workspace-error-modal {
  &__title {
    display: flex;
    gap: $spacing--md;
    line-height: normal;
  }

  &__icon {
    color: $color--text-warning !important;
  }
}
