@import "../../web/shared/styles/styles.scss";

.palette {
  display: flex;
  flex: 1;
  width: 100%;

  &--add-sql-activated {
    background-color: $color--highlight;
  }

  &__filter-button {
    margin-right: $spacing--xs;
  }
}

.palette-search-controls {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-bottom: $spacing--xs;

  &__edit-button {
    margin-left: $spacing--xs;
  }
}

.palette-bar {
  background-color: $color--bg-primary;
  border-bottom: 1px solid $color--divider-1;
  border-top: 1px solid $color--divider-1;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: $spacing--xxs $spacing--sm;
  width: 100%;

  &__view-controls {
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
}
