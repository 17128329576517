@import "../../../transform/variables.scss";
@import "../../shared/styles/styles.scss";

$font-family-4: "Roboto", sans-serif;
$modal-padding: 12px 24px;

.projects-dashboard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: $font-family-4;
  background-color: $color--bg-primary;
  height: 100%;
  border-top: 5px solid $color--divider-1;

  .ant-tabs-extra-content {
    width: 100%;

    .projects-tabs-header {
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      font-weight: 600;
      margin-left: 1rem;
      padding-top: 50px;
    }
  }

  .projects {
    background-color: $color--bg-primary;
    height: calc(100% - 0.5rem);
    margin: 0.25rem;
    overflow: auto;
    padding: 3rem 3rem 0 3rem;
    width: calc(60vw - 0.5rem);
    overflow: unset;

    .workspace-cards-container {
      overflow: auto;
    }
  }

  /*Hides an antd ellipses button that appears by default when project names exceed the screen height*/
  .ant-tabs-nav-operations {
    display: none !important;
  }

  .ant-tabs-tab-active {
    background-color: $color--item-bg-selected;
  }

  .project-dash-header {
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    .projects-header-btn-container {
      display: flex;
      align-items: center;

      .project-settings-btn {
        margin: unset;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 146px;
        height: 32px;
      }
    }
  }

  .add-project-btn {
    border: none;
    color: $color--text-secondary;
    margin-right: 0;
  }

  .projects-header {
    display: flex;
    flex-direction: column;
    font-size: 24px;
    overflow: auto;

    &__title {
      text-overflow: ellipsis;
      overflow: auto;
    }
  }

  .projects-menu-container {
    height: 100vh;
    min-width: 33vw;
    border-right: 2px solid $color--divider-1;

    .projects-menu {
      padding-top: 50px;
      margin-left: 10rem;
      height: 100vh;

      .menu-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 10px;
      }

      .project-list-name {
        font-size: 14px;
        padding: 10px 40px;
      }

      .project-list-name:hover {
        cursor: pointer;
      }

      .true {
        font-weight: 500;
        color: $color--brand-blue;
        background-color: $color--item-bg-selected;
      }
    }
  }

  .empty-projects-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;

    .find-out-more-btn {
      margin-top: 20px;
    }

    .empty-projects.ant-empty {
      width: 360px;
    }

    .empty-project-description-container {
      display: flex;
      flex-direction: column;
    }

    .project-member-description {
      max-width: 256px;
      color: $color--text-secondary;
      text-align: center;
    }
  }

  .ant-tabs-nav-list {
    height: 100%;
    overflow-y: auto;
  }

  // Truncates the text of the tabs and stops the tab from growing
  .ant-tabs-tab-btn {
    overflow: hidden;
  }

  // Hides the default tab styling that unnecessarily renders on the null item label
  .ant-tabs-tab-active:has(#rc-tabs-0-tab-no-projects-id),
  .ant-tabs-nav-list:has([data-node-key="no-projects-id"]) {
    visibility: hidden;

    .ant-tabs-ink-bar {
      visibility: hidden;
    }
  }

  .projects-dash-footer {
    display: flex;
    align-items: center;
    background-color: $color--brand-blue;
    color: $color--text-primary-inverted;
    font-size: 11px;
    padding: 5px 10px;

    .footer-detail {
      margin-bottom: 0px;
      margin-left: 5px;
      margin-right: 15px;
    }
  }
}

.delete-project-modal {
  font-family: $font-family-4;

  .delete-project-header {
    display: flex;
    align-items: center;
    background-color: $color--dust-red-5;
    color: $color--text-primary-inverted;
    height: 64px;
    font-size: 20px;
    font-weight: 500;
    padding: $modal-padding;
  }

  .delete-project-body {
    user-select: none;
    padding: $modal-padding;

    .delete-project-ul {
      margin-bottom: 25px;
    }

    .delete-project-warning {
      display: flex;
      flex-direction: column;
      padding: 16px;

      .ant-alert-message {
        color: $color--text-error;
      }
    }
  }

  .delete-project-action-field {
    margin-top: 25px;
    margin-bottom: 25px;

    .delete-project-input {
      padding: 8px 12px;
    }
  }

  .delete-project-btn-container {
    display: flex;
    justify-content: flex-end;
    padding: 10px;
    border-top: 1px solid $color--divider-1;

    .delete-project-btn {
      background-color: $color--dust-red-5;
      border: none;
      color: $color--text-primary-inverted;
    }

    button[disabled] {
      background-color: $color--neutral-3;
      color: $color--text-tertiary;
      border-color: $color--neutral-5;
    }

    button {
      margin: 5px;
    }
  }
}

.configure-git-account-guide-container {
  background-color: $color--bg-primary;
  margin-top: 5px;
  height: 100vh;
  padding-top: 20px;
  padding-left: 15rem;

  .configure-git-account-guide-content {
    width: 70vw;

    .step-explanation {
      color: $color--text-primary;
      margin-bottom: 25px;
    }

    .configure-git-account-guide-btn-container {
      display: flex;
      justify-content: flex-end;
      margin-top: 5px;
    }

    .version-control-account-btn-container {
      margin-top: 30px;

      button {
        margin-right: 20px;
      }
    }
    .add-your-git-creds-step {
      display: flex;
      flex-direction: column;

      h4 {
        font-size: 20px;
      }

      p {
        width: 53vw;
      }
    }
  }
}

.create-project-guide {
  height: 100%;
  width: 60vw;
  background-color: $color--bg-primary;
  margin-top: 5px;

  .create-project-guide-content {
    margin: 20px;

    .create-project-step-count {
      font-family: "Roboto";
      margin-bottom: 20px;
      margin-left: 5rem;
      color: $color--text-secondary;
    }

    .create-a-project-header {
      font-family: "Roboto";
      font-size: 16px;
      font-weight: 500;
      margin-left: 5rem;
    }

    .add-project-sub-header {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-top: 30px;
      margin-bottom: 25px;
      font-size: 30px;
      font-weight: 600;
    }

    .step-explanation {
      margin-bottom: 25px;
    }

    .create-project-btn-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-left: 3.75rem;
    }

    .create-project-nav-btn-container {
      display: flex;
      justify-content: flex-end;

      .create-project-nav-btn {
        margin: 5px;
      }
    }

    .project-details-step,
    .setup-version-control-form,
    .add-your-git-creds-step {
      display: flex;
      flex-direction: column;
      margin-left: 5rem;

      .ant-form-item-required,
      .ant-form-item-label {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
      }

      .git-account-label {
        margin-top: 20px;
        margin-bottom: 5px;
      }

      .version-control-account-btn-container {
        margin-top: 20px;

        button {
          margin-right: 10px;
        }
      }
    }
  }
}

.configure-git-account-navigation {
  .bread-crumbs {
    .active {
      color: $color--text-primary;
    }
  }

  &__header {
    margin-top: 10px;
    margin-bottom: 50px;
  }
}

.setup-version-control-container {
  background-color: $color--bg-primary;
  margin-top: 5px;
  height: 100%;
  padding-top: 20px;
  padding-left: 15rem;

  .step-explanation {
    color: $color--text-primary;
    margin-bottom: 25px;
  }

  .git-repo-url-form {
    margin-top: 25px;
  }

  .configure-navigation {
    .bread-crumbs {
      font-family: $font-family-4;

      &__button {
        padding: 0 4px;
        border-radius: 4px;
        height: 22px;
        display: inline-block;
        margin-inline: -4px;

        .active {
          color: $color--text-primary;
        }
      }
    }

    &__back-button {
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: "Roboto";
      font-size: 20px;
      margin-top: 10px;
      margin-bottom: 50px;
      padding: 2px;
    }
  }

  .setup-version-control-content {
    display: flex;
    width: 61vw;
    min-height: 30vh;

    .setup-version-control-step-icons {
      width: 20vw;
    }

    .add-project-sub-header {
      font-size: 16px;
      margin-bottom: 20px;
    }

    .setup-version-control-explanation {
      font-size: 14px;
      margin-bottom: 20px;
    }
    .add-your-git-creds-step {
      display: flex;
      flex-direction: column;

      button {
        margin-top: 20px;
        margin-right: 10px;
      }
    }
  }
  .setup-version-control-btn-container {
    display: flex;
    justify-content: flex-end;
    width: 61vw;
    margin-top: 5px;
  }
}

.setup-version-control-warning {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 14px;
  font-style: $font-family-4;

  h4 {
    font-size: 20px;
    margin-left: 24px;
  }

  .warning-btn-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 10px 16px;
    gap: $spacing--xs;
    width: 100%;
  }

  .setup-version-control-warning-content-container {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .warning-content {
      margin: 24px;
      width: 60%;
    }
  }
}

.ant-modal-body:has(
    .setup-version-control-warning,
    .project-settings-modal,
    .create-workspace-no-version-control-modal,
    .delete-project-modal
  ) {
  padding-right: 0px;
  padding-left: 0px;
}

.ant-modal-body:has(.delete-project-modal) {
  padding: unset;
}

.ant-modal-content:has(.delete-project-modal) {
  .ant-modal-close-icon {
    color: $color--neutral-1;
  }
}

.project-settings-modal {
  display: flex;
  flex-direction: column;
  font-family: $font-family-4;
  height: 500px;
  overflow: hidden;

  &__tabs {
    flex: 1;
  }

  .project-settings-header {
    display: flex;
    align-items: center;
    padding: 0 20px 10px;

    .project-settings-modal-title {
      font-weight: 500;
      font-size: 20px;
      flex-shrink: 0;
    }

    .project-settings-namespace {
      font-size: 14px;
      color: $color--text-secondary;
      margin: 0 15px 10px;
      overflow: hidden;
    }
  }

  .project-settings-tab-content {
    height: 100%;
    width: 100%;
    padding: 0 20px 10px;

    .add-project-sub-header {
      font-size: 16px;
      margin-bottom: 30px;
    }

    .edit-repo-btn {
      margin-left: 10px;
      width: 60px;
      height: 40px;
    }
  }
}

.create-workspace-no-version-control-modal {
  font-family: $font-family-4;

  .header {
    display: flex;
    justify-content: center;
    font-size: 20px;
    font-weight: 500;
  }

  .subheader {
    font-size: 16px;
    font-style: normal;
    margin-bottom: 30px;
  }

  .content {
    padding: 0 40px 10px;
  }

  .btn-container {
    padding: 0 40px 10px;
  }
}

.projects_overflow-boundary {
  height: 100vh;
  overflow: auto;
}
