@import "../../../../../../web/shared/styles/styles.scss";

.docs-column-overview {
  &__items {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    row-gap: $spacing--md;
  }
  &__item {
    min-height: 44px;
    word-wrap: break-word;
    word-break: break-all;
  }
  &__value {
    font-weight: 400;
    font-size: $font-size--md;
    font-family: "Roboto";
    flex-wrap: wrap;
    &--disabled {
      color: $color--text-secondary;
    }
  }
  &__label {
    font-weight: 500;
    font-size: $font-size--sm;
    color: $color--text-secondary;
    font-family: "Roboto";
  }
}
