@import "../../../../shared/styles/styles.scss";

.builder-setting {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  width: 100%;

  &__header {
    border-bottom: 1px solid $color--neutral-4;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    min-height: 72px; // the size of the header with a button to keep jumping when switching between pages
    padding: $spacing--md-lg $spacing--md;

    &__inner {
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: space-between;

      &__text-and-icon {
        align-items: center;
        display: flex;
        flex-wrap: nowrap;
      }

      &__text {
        margin: 0;
      }

      &__icon {
        margin-left: $spacing--sm;
      }

      &__buttons {
        align-items: center;
        display: flex;

        & > * + * {
          margin-left: $spacing--md;
        }
      }
    }

    &__alert {
      width: 100%;
      margin-top: $spacing--sm;
    }
  }

  &__content {
    background-color: $color--bg-secondary;
    flex: 1;
    padding: $spacing--md;
    width: 100%;
  }
}
