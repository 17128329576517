@import "../../../web/shared/styles/styles.scss";

.source-cascader {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;

  &__overlay {
    .ant-popover-inner-content {
      padding: $spacing--xs;
    }

    .source-cascader__popover__content {
      display: flex;
      flex-direction: column;
      max-height: 500px;
      width: 300px;

      @media screen and (max-height: 1200px) {
        max-height: 400px;
      }
      @media screen and (max-height: 768px) {
        max-height: 300px;
      }

      &__header {
        margin-bottom: $spacing--xxs;

        &__back {
          margin-bottom: $spacing--xxs;
        }
      }

      &__options {
        display: flex;
        flex: 1;
        flex-direction: column;
        overflow-y: auto;
      }

      &__option {
        &--table {
          font-weight: 500;

          &.source-cascader__popover__content__option--selected {
            & > button {
              .source-tooltip__entity-name {
                color: $color--coalesce-blue;
              }
            }
          }
        }

        &--column {
          display: block;
          width: 100%;

          &.source-cascader__popover__content__option--selected {
            & > button {
              background-color: $color--item-bg-selected;
            }
          }
        }
      }

      &__button {
        align-items: center;
        display: flex;
        flex-wrap: nowrap;
        height: 100%;
        padding-left: $spacing--xs;
        padding-right: $spacing--xs;
        text-align: left;
        width: 100%;

        &__label {
          flex: 1;
          font-size: $font-size--sm;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      &__children {
        button {
          padding-left: $spacing--lg;
        }
      }
    }
  }

  &__clear-icon > svg {
    fill: $color--neutral-5;
  }
}
