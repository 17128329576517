@import "../../../transform/variables.scss";
@import "../../../web/shared/styles/styles.scss";

#header {
  background-color: $color--bg-primary;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  height: $spacing--xxl;
  width: 100%;

  .header__logo {
    height: 50px;
    width: auto;
  }

  .header__menu {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    position: relative;

    // have to use !important since most of the styles are appended directly to the element, not applied via a class
    #pendoMenu > .pendo-resource-center-badge-notification-bubble {
      background-color: $color--brand-blue !important;
      // makes the pendo notification dot smaller so it does not look so ridiculous over the the (?) button
      border-radius: 8px !important;
      height: 8px !important;
      left: 4px !important;
      top: 4px !important;
      width: 8px !important;

      &::before {
        // remove pulsing animation, as proposed in CD-6201: https://coalescesoftware.atlassian.net/browse/CD-6201
        display: none;
      }

      // remove count, as proposed in CD-6201: https://coalescesoftware.atlassian.net/browse/CD-6201
      & > .pendo-notification-bubble-unread-count {
        display: none;
      }
    }
  }
}
