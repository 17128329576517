@import "../../web/shared/styles/styles.scss";

.ant-tabs-content:has(.upgrade-commit-box-container) {
  display: flex;
  align-items: center;

  & .upgrade-commit-box-container {
    max-width: 65%;
    margin: auto;

    & .upgrade-commit-box {
      box-shadow: $spacing--xxs $spacing--xxs $spacing--sm $spacing--xxs rgba($color--neutral-13, 0.1),
        $spacing--xxs -4px $spacing--sm $spacing--xxs rgba($color--neutral-13, 0.1);
      margin-top: $spacing--md;
      height: 100%;
      width: 100%;

      & .upgrade-commit-banner {
        display: flex;
        flex-direction: row;
        height: 100%;

        & .upgrade-commit-banner-text-box {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          font-family: $font-family--roboto;
          padding: $spacing--md 0 $spacing--md $spacing--md;

          & .upgrade-commit-banner-text {
            font-size: $font-size--lg;
          }
        }
      }
    }
  }
}

//adds a more rounded border for specified modals
.rounded-modal,
.ant-modal-body:has(.rounded-modal),
.ant-modal-content:has(.rounded-modal) {
  border-radius: $spacing--xs;
}

.git-modal {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  .ant-tabs.git-modal__tabs {
    flex: 1;
  }
}

.git-modal__tabs > .ant-tabs-nav {
  background-color: $color--bg-secondary;
  padding: 0 $spacing--lg;
}

.git-modal__tabs > .ant-tabs-content-holder {
  padding: 0 $spacing--lg $spacing--lg;
}

.git-modal-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: $color--bg-secondary;
}

.git-modal-header {
  background-color: $color--bg-secondary;
  width: 100%;
  padding: $spacing--lg $spacing--lg 0 $spacing--lg;
}

.git-modal-branch-commit-container {
  display: flex;
}

.git-modal-branch-container,
.git-modal-commit-container {
  display: flex;
  flex-direction: column;
  margin: 0;
  max-width: 500px;
}

.git-modal-branch-title,
.git-modal-commit-title {
  font-size: $font-size--sm;
  color: $color--text-secondary;
  margin-bottom: $spacing--xxs;
  overflow: hidden;
}

.git-modal-commit-name {
  font-weight: $font-weight--500;
  margin: 0;
  overflow: hidden;
}

.tooltip-on-truncation {
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.git-modal-arrow svg {
  color: $color--text-secondary;
  margin: 0 $spacing--lg;
  align-self: flex-end;
}

.git-modal-commit-name {
  margin-right: $spacing--md;
}

.git-modal-name-and-tag {
  display: flex;
  flex-direction: row;
}

.git-modal-banner-container {
  display: flex;
  flex-direction: column;
  gap: $spacing--xs;
  background-color: $color--bg-secondary;
  padding: $spacing--sm $spacing--lg;
}

.git-modal-alert-container {
  display: flex;
  flex-direction: row;
  gap: $spacing--xs;
  margin-left: $spacing--md;

  & > .ant-tag {
    margin: 0px;
  }
}

.git-modal-branch-tab-selector-and-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  & > * {
    margin: $spacing--xxs;
  }

  & > .git-modal-branch-tab-title {
    flex-shrink: 0;
    font-size: $font-size--sm;
  }

  & .git-modal-branch-selector {
    display: flex;
    flex-grow: 1;
    min-width: 100px;
    margin: $spacing--xs;
  }

  & .git-modal-branch-selector > .ant-select {
    display: flex;
    flex-grow: 1;
    min-width: 100px;
    font-size: $font-size--sm;
  }
}

.git-modal-banner-text {
  margin: 0;

  & .git-modal-banner-branch-name {
    margin-left: $spacing--xxs;
    font-weight: $font-weight--500;
  }
}
