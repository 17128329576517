@import "../../styles/styles.scss";

.page-header {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;

  & > .ant-typography.page-header__title {
    margin: 0;
    padding: 0;
  }

  & > .page-header__button {
    margin-right: $spacing--sm;
  }

  & > * + * {
    margin-left: $spacing--sm;
  }
}
