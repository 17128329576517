@import "../../../../../../../shared/styles/styles.scss";

.impacted-projects {
  &__message {
    margin-bottom: $spacing--sm;
  }

  &__list {
    max-height: 200px;
  }

  &__project {
    border: 1px solid $color--divider-1;
    border-radius: 3px;
    padding: $spacing--xs;

    & + .impacted-projects__project {
      margin-top: $spacing--xxs;
    }
  }

  &__alert {
    margin: $spacing--sm 0 $spacing--md;
  }
}
