@import "../../../web/shared/styles/styles.scss";

.environment-option {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;

  &__name,
  &__project {
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__project {
    margin-left: $spacing--xs;
  }
}

.feed-filter-dropdown {
  &__popover {
    & .anticon {
      margin-left: $spacing--xs;
    }
  }
}
