@import "../../web/shared/styles/styles.scss";

.ant-tag.ant-tag-blue.tag-font-11 {
  font-size: $font-size--sm;
}

.create-sql-monaco-editor {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__mapping {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  &__input {
    display: flex;
    flex: 1 1 auto;
    max-height: 100%;
  }

  &__input-container {
    flex: 1 1 auto;
  }
}
