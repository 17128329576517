@import "../../../web/shared/styles/styles.scss";

// Modal Formatting
.new-branch-modal-header > .ant-alert {
  margin-bottom: $spacing--xl;
}

.new-branch-modal-alert-text {
  margin: 0;
}

.new-branch-modal-alert-text > span {
  font-weight: $font-weight--500;
}

.new-branch-modal-container {
  display: flex;
}

.new-branch-modal-icon-container {
  display: flex;
  align-items: stretch;
  width: 32px;
  margin: 0 $spacing--lg;
}

.new-branch-icon {
  flex: 1;
  height: 100%;
  width: 100%;
  object-fit: fill;
}

.new-branch-modal-content {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.new-branch-modal-card-container,
.new-branch-modal-card-container-input {
  position: relative;
  font-family: $font-family--roboto;
  font-size: $font-size--sm;
  color: $color--text-primary;
  width: 100%;
}

.new-branch-modal-card-container {
  margin-bottom: $spacing--lg;
}

// Modal Card Formatting
.new-branch-modal-card-title {
  position: absolute;
  top: -10px;
  left: $spacing--md;
  display: flex;
  padding: 0 $spacing--xs;
  align-items: center;
  gap: $spacing--xs;
  background-color: $color--bg-primary;
  color: $color--text-secondary;
  line-height: $line-height--20;
  max-width: fit-content;
}

.new-branch-modal-card-commit-info,
.new-branch-modal-card-name-input {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  border-radius: $spacing--xxs;
  border: 1px solid $color--divider-1;
  gap: $spacing--sm;
  width: 100%;
}

// Top Card
.new-branch-modal-card-commit-info > div {
  display: flex;
  flex-direction: column;
  border-right: 1px dashed $color--divider-1;
  padding: $spacing--lg;
  gap: $spacing--sm;
  word-break: break-all;
}

.new-branch-modal-card-commit-info > div > p {
  margin: 0;
}

.new-branch-modal-card-commit-info > div:nth-of-type(1) {
  flex-grow: 1;
  flex-shrink: 1;
  font-size: $font-size--md;
}

.new-branch-modal-card-selected-branch {
  font-weight: $font-weight--500;
}

.new-branch-modal-oid {
  color: $color--text-secondary;
  font-weight: $font-weight--400;
  font-size: $font-size--sm;
  margin-left: $spacing--xs;
}

.new-branch-modal-card-commit-info > div:nth-of-type(2),
.new-branch-modal-card-commit-info > div:nth-of-type(3) {
  width: 160px;
  min-width: 130px;
}

.new-branch-modal-card-commit-info > div:nth-of-type(2) > p:nth-of-type(1),
.new-branch-modal-card-commit-info > div:nth-of-type(3) > p:nth-of-type(1) {
  color: $color--text-secondary;
}

// Bottom Card
.new-branch-modal-card-name-input > .ant-form {
  padding: $spacing--md-lg $spacing--md-lg 0 $spacing--md-lg;
  font-size: $font-size--md;
  gap: $spacing--xxs;
  width: 100%;
}

#newBranchName_help {
  height: 22px;
}

// Footer
.new-branch-modal-footer {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
}

.new-branch-modal-footer > p {
  margin: 0;
}
