@import "../../web/shared/styles/styles.scss";

.tabular-config {
  margin-bottom: 40px; // height of button + spacing above and below, 24 + 8 + 8

  &__add-new-row-button.ant-btn {
    margin-top: $spacing--xs;
    position: absolute;
    right: $spacing--xs;
  }

  &__footer {
    position: relative;
    width: 100%;
  }
}
