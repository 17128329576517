@import "../../styles/styles.scss";

.entity-name-editor {
  align-items: center;
  display: flex;
  flex: 1;
  flex-wrap: nowrap;
  margin: 0 auto 0 $spacing--xs;
  position: relative;
  width: 100%;

  &__name {
    flex: 1;
    font-size: $font-size--lg;
    position: relative;

    & > .ant-typography.ant-typography-edit-content.ant-typography-span {
      display: flex;
      margin: $spacing--sm 0 $spacing--sm $spacing--sm;
    }
  }

  &__id {
    color: $color--text-tertiary;
    font-size: $font-size--sm;
    margin-left: $spacing--xs;
  }
}
