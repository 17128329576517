@import "~antd/dist/antd.css";

/**
 * Primarily for shared or "global" overrides to antd component. Try to limit what goes in here.
 * We should use antd's theming functionality as much as possible instead of manual scss overrides, especially for
 * colors.
 */

@import "./styles.scss";

/****************************************
 * Form
 ***************************************/

// likely caused by a conflict between versions 4 and 5, weird margin styling get applied to inputs with help text
.ant-form.correct-help-margin {
  & > .ant-form-item.ant-form-item-with-help {
    margin-bottom: 24px;
  }
}

.ant-form-item-control--left-180.ant-form-item-control {
  padding-left: 180px;
}

// keep horizontal form items from wrapping - caused inputs with long error names to wrap, CD-9040
// known issue: https://github.com/ant-design/ant-design/issues/31296
.ant-form.ant-form-horizontal {
  & > .ant-form-item {
    & > .ant-form-item-row {
      flex-wrap: nowrap;
    }
  }
}

/****************************************
 * Modal
 ***************************************/

// makes sure the modal header is big enough and looks centered with the close-x
.ant-modal {
  & > .ant-modal-content {
    padding: 0;

    & > .ant-modal-header {
      align-items: center;
      border-bottom: 1px solid $color--divider-2; // to match .ant-tabs-left > .ant-tabs-content-holder
      display: flex;
      height: 56px;
      margin-bottom: 0px;
      padding: 0 $spacing--lg;
    }

    & > .ant-modal-body {
      padding: $spacing--lg;
    }

    & > .ant-modal-footer {
      padding: $spacing--xs $spacing--lg;
    }
  }
}

//removes the padding for specified modals
.ant-modal:has(.modal-without-padding) {
  & > .ant-modal-content {
    & > .ant-modal-header {
      padding: 0;
    }
    & > .ant-modal-body {
      padding: 0;
    }
  }
}

// makes the header of the any delete modal red and disables the user from highlighting/ copying the
// confirmation text to make deleting/removing as painful as possible for the user.
.delete-modal.ant-modal {
  & > .ant-modal-content {
    & > .ant-modal-header {
      background-color: $color--dust-red-5;
      border-bottom-color: $color--dust-red-7;

      & > .ant-modal-title {
        color: $color--text-primary-inverted;
      }
    }

    & > .ant-modal-body {
      user-select: none;
    }
  }
}

/****************************************
 * Collapse
 ***************************************/

.overflow-hidden-collapse.ant-collapse {
  overflow: hidden;

  & > .ant-collapse-item {
    overflow: hidden;

    & > .ant-collapse-header {
      align-items: center;
      overflow: hidden;
      width: 100%;

      & > .ant-collapse-header-text {
        overflow: hidden;
        width: 100%;
      }
    }

    & > .ant-collapse-content {
      overflow: hidden;

      & > .ant-collapse-content-box {
        overflow: hidden;
      }
    }
  }
}

.ant-collapse.wizard-collapse {
  margin-bottom: 5px;
  border: unset; // remove competing antd default border to avoid doubling
  border-radius: 3px;
  border: 1px solid $color--divider-2;

  .ant-collapse-header {
    top: 0;
    position: sticky;
    z-index: 999;
    background-color: $color--bg-secondary;
    border-radius: 3px;
    text-align: left;
  }

  > .ant-collapse-item:last-child,
  > .ant-collapse-item:last-child > .ant-collapse-header {
    border-radius: 3px;
  }

  > .ant-collapse-item.errors-table {
    > .ant-collapse-content.ant-collapse-content-active {
      border-top: none; // to avoid doubling with header's border-bottom
    }
  }

  > .ant-collapse-item {
    /* 
    * needs to apply to all collapse items under deploy wizard;
    * components are otherwise susceptible to ugly doubled bottom borders 
    */
    border-bottom: none;
  }
}

/****************************************
 * Tabs
 ***************************************/

.ant-tabs.full-height-tabs {
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;

  & > .ant-tabs-content-holder {
    height: 100%;
    overflow: hidden;
    width: 100%;

    & > .ant-tabs-content {
      height: 100%;
      overflow: hidden;
      width: 100%;

      & > .ant-tabs-tabpane {
        height: 100%;
        overflow: hidden;
        width: 100%;
      }
    }
  }
}

.ant-tabs-content {
  background-color: $color--bg-primary;
}

/****************************************
 * Tooltip
 ***************************************/

.ant-tooltip.tooltip-wrapper {
  max-width: 300px;

  .ant-tooltip-inner {
    font-size: $font-size--xs;
    min-height: 16px;
  }
}

/****************************************
 * Alert
 ***************************************/

.ant-alert.form-alert {
  margin-bottom: $spacing--lg;
}

/****************************************
 * Buttons
 ***************************************/

.ant-btn.ant-btn-primary:not(:disabled):not(.ant-btn-dangerous) {
  background-color: $color--brand-blue;
  border-color: $color--brand-blue;
  color: $color--text-primary-inverted;

  &:hover,
  &:active {
    background-color: darken($color--brand-blue, 13%);
    border-color: darken($color--brand-blue, 13%);
    color: $color--text-primary-inverted;
  }

  &:focus {
    background-color: $color--coalesce-blue;
    border-color: $color--coalesce-blue;
    color: $color--text-primary-inverted;
  }
}

/****************************************
 * Descriptions
 ***************************************/

.ant-descriptions.modal-descriptions {
  margin-bottom: $spacing--lg;
}

/****************************************
 * Menu
 ***************************************/
.ant-dropdown-menu:not(.ant-dropdown-menu-submenu) {
  max-height: 50vh; // so that the menu does not go off the screen and has a scrollbar
  max-width: 320px; // good for showing about 40 characters
  overflow-x: hidden;
  overflow-y: auto;

  ::-webkit-scrollbar {
    background-color: $color--bg-primary;
    border-radius: 0 8px 8px 0;
    transition: all 0.5s;
    -webkit-appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border: 4px solid $color--neutral-7;
    border-radius: 4px;
    background-clip: content-box;
    background-color: $color--neutral-7;
  }

  // if the length of the menu item exceeds the max width of the menu, cut off the text with a trailing ellipsis (...)
  & > .ant-dropdown-menu-item > .ant-dropdown-menu-title-content {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
}

// to match the styling of the standard ant-dropdown-menu - antd 5
// .ant-dropdown-menu-submenu-popup {
//   background: inherit;
//   box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
//   z-index: 1050 !important;
// }

/****************************************
 * Inputs
 ***************************************/

// Show only one validation message at a time
.only-one-error-message {
  & .ant-form-item-explain .ant-form-item-explain-error:not(:first-child) {
    display: none;
  }
}
