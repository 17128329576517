@import "../../../../../web/shared/styles/styles.scss";

.docs-project {
  background-color: $color--bg-primary;
  border-radius: 8px;
  padding: $spacing--lg;
  margin-bottom: $spacing--lg;
  &__title {
    font-size: $font-size--xl;
    font-weight: 600;
  }
  &__lists {
    display: flex;
  }
}
