.delete-nodes-modal-padding {
  padding: 10px;
}

.delete-nodes-modal-content-box {
  padding: 10px;
  display: flex;
  justify-content: center;
}

.delete-nodes-modal-message-font {
  font-size: 16px;
  font-weight: 400;
}

.delete-nodes-modal-footer {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
}
