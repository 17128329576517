@import "../../web/shared/styles/styles.scss";

.deploy-dashboard {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  &__filters-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: $spacing--xs;
  }

  &__body {
    display: flex;
    flex: 1;
    flex-direction: row;
    background-color: $color--bg-view;
    height: 100%;
    overflow-y: hidden;
    padding: $spacing--md-lg;

    &__left {
      display: flex;
      flex-direction: column;
      width: 70%;

      &__environment-container {
        overflow-y: scroll;
        border-radius: $spacing--sm;
        padding: $spacing--xs;
        margin-right: $spacing--xxs;

        &__options {
          display: flex;
          flex-direction: row;
          width: 100%;
          align-items: flex-end;
          justify-content: flex-end;
        }

        .ant-page-header {
          padding: $spacing--xs;
        }
      }
    }

    &__right {
      width: 30%;
    }
  }
}

.dashboard-access-token.ant-typography {
  margin-bottom: 0;

  & code {
    display: inline-block;
    word-break: break-all;
  }
}
