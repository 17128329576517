@import "../../../../../../web/shared/styles/styles.scss";

.docs-node-script-container {
  border: 1px solid $color--divider-2;
  margin: 0px $spacing--lg 0px $spacing--lg;
}
.docs-node-monaco-editor {
  display: flex;
  flex-direction: row;
  min-height: 200px;
}
