// Common styling used by both the ClientIDAndSecretForm and RoleAndWarehouseForm components

@import "../../../../../../../shared/styles/styles.scss";

.oauth-modal-form {
  display: flex;
  height: 100%;

  > div {
    width: 50%;
  }

  &__inputs {
    padding: $spacing--lg;
    min-width: 300px;

    p {
      color: $color--text-secondary;
    }
  }

  &__extra {
    display: grid;
    min-width: 300px;

    button {
      margin: $spacing--xxs;
      z-index: 9999;
      position: absolute; // to display the button on top of the monaco instance
      justify-self: flex-end;
    }

    &__monaco {
      padding: $spacing--lg;
    }

    &__gradient {
      background: linear-gradient(to right, $color--bg-primary, rgba($color--coalesce-blue, 0.1));
    }
  }
}
