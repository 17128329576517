@import "../../web/shared/styles/styles.scss";

.popover-overlay--color-picker {
  .ant-popover-inner-content {
    padding: 0;
  }
}

.snowflake-advanced-options {
  font-size: 14px;
  font-family: "Roboto";

  .ant-form-item-row {
    display: flex;
    justify-content: space-between;
  }

  .ant-input {
    font-size: 14px;
    padding: 8px;
  }
}

.ant-tabs.environment-tabs.full-height-tabs {
  .ant-tabs-content-holder {
    .ant-tabs-content {
      .ant-tabs-tabpane {
        overflow: auto;
      }
    }
  }
}

.add-new-storage-location-button {
  margin: auto 0;
}

.new-environment-modal-parent {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.workspace-modal {
  &__buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: $spacing--md;
  }
}

.delete-workspace-button {
  margin-top: $spacing--lg;
}

.workspace-setting-database-schema-title {
  margin-bottom: $spacing--md;
  padding-left: 180px;

  .workspace-setting-database-title,
  .workspace-setting-schema-title {
    font-weight: $font-weight--400;
    font-size: $font-size--lg;
  }
}
