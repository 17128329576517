@import "../../../web/shared/styles/styles.scss";

.merge-branch-modal-container {
  display: flex;
  padding: $spacing--sm 0 0 $spacing--md-lg;
  gap: $spacing--lg;
}

.merge-icon {
  width: 36px;
  height: 182px;
}

.merge-branch-modal-span-text {
  font-weight: $font-weight--500;
  word-break: break-all;
  white-space: normal;
  display: inline;
}

.merge-branch-modal-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.merge-branch-modal-card-container {
  position: relative;
  font-family: $font-family--roboto;
  font-size: $font-size--sm;
  color: $color--text-primary;
  max-width: 590px;
}

.merge-branch-modal-card-title {
  position: absolute;
  top: -10px;
  left: $spacing--md;
  display: flex;
  padding: 0 $spacing--xs;
  align-items: center;
  gap: $spacing--xs;
  background-color: $color--bg-primary;
  color: $color--text-secondary;
  line-height: $line-height--20;
  max-width: fit-content;
}

.merge-branch-modal-card {
  display: flex;
  flex-direction: column;
  padding: $spacing--md-lg;
  align-items: flex-start;
  border-radius: $spacing--xxs;
  border: 1px solid $color--divider-1;
  justify-content: space-between;
  gap: $spacing--sm;
}

.merge-branch-modal-card > p,
.merge-branch-modal-with-tag > p {
  overflow-wrap: anywhere;
}

.merge-branch-modal-card-commit-code {
  color: $color--text-secondary;
  font-weight: $font-weight--400;
  font-size: $font-size--sm;
  margin-left: $spacing--xxs;
}

.merge-branch-modal-with-tag {
  display: flex;
  max-width: 100%;
  align-items: center;
}

.merge-modal-branch-with-tag,
.merge-modal-branch {
  font-weight: $font-weight--500;
  margin-bottom: 0;
}

.merge-modal-branch-with-tag {
  margin: 0 $spacing--xs 0 0;
}
