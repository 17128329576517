.enable-totp-form-segmented-input,
.enable-totp-form-instructions {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.enable-totp-form-instructions {
  margin-bottom: 1em;
}

.enable-totp-form-segmented-input {
  justify-content: space-between;
}

.enable-totp-form-segmented-input > .ant-row.ant-form-item-row,
.enable-totp-form-segmented-input > .ant-row.ant-form-item-row > .ant-col.ant-form-item-control,
.enable-totp-form-segmented-input
  > .ant-row.ant-form-item-row
  > .ant-col.ant-form-item-control
  > .ant-form-item-control-input,
.enable-totp-form-segmented-input
  > .ant-row.ant-form-item-row
  > .ant-col.ant-form-item-control
  > .ant-form-item-control-input
  > .ant-form-item-control-input-content {
  width: 100%;
}
