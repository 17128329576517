@import "../../../../../../../shared/styles/styles.scss";

.browser-tab-header {
  border-bottom: 1px solid $color--divider-1;
  padding: $spacing--xs;

  & > * + * {
    margin-top: $spacing--xs;
  }
}
