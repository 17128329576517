@import "../../../shared/styles/styles.scss";

#edit-release {
  --displayWidth: 832px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  background-color: $color--bg-primary;

  & .sticky-header-wrapper {
    &__breadcrumb {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: $spacing--lg;

      & .edit-release-nav {
        padding: $spacing--md-lg 0 0 0;
        width: var(--displayWidth);
      }
    }

    &__header {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: $spacing--lg;

      & .edit-release-header,
      & .edit-release-error-banner {
        margin: 0;
        width: var(--displayWidth);
      }

      & .edit-release-package-name {
        color: $color--text-secondary;
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: $spacing--lg;

      & .edit-release-form {
        width: var(--displayWidth);
        display: flex;
        flex-direction: column;
        gap: $spacing--lg;

        & .ant-form-item-label {
          width: 142px;
        }

        & .edit-release-notes-input {
          width: 100%;
        }
      }

      & .edit-release-button-box {
        display: flex;
        width: var(--displayWidth);
        justify-content: space-between;
        padding: 0 0 $spacing--lg 0;
      }
    }
  }
}
