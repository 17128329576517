@import "../../../../../../../transform/variables.scss";
@import "../../../../../../../web/shared/styles/styles.scss";

@mixin cancel-image {
  background-image: linear-gradient(
    135deg,
    $color--neutral-6 25%,
    $color--neutral-8 25%,
    $color--neutral-8 50%,
    $color--neutral-6 50%,
    $color--neutral-6 75%,
    $color--neutral-8 75%,
    $color--neutral-8 100%
  );
  background-size: 5px 5px;
}

@keyframes working-pulse {
  to {
    opacity: 50%;
  }
}

.run-overview-widget {
  &__secondary-label {
    color: $color--text-tertiary;
    font-size: 0.7rem;
  }

  &__chip {
    border-radius: 4px;
    border: none;
    flex: 0 0 auto;
    height: 25px;
    margin: 0 3px;
    width: 9px;
    &--successful {
      background-color: $color--text-success;
    }
    &--failed {
      background-color: $color--text-error;
    }
    &--testFailed {
      background-color: $color--text-warning;
    }
    &--skipped {
      background-color: $color--neutral-7;
    }
    &--canceled {
      @include cancel-image();
    }
    &--canceling {
      @include cancel-image();
      animation-direction: alternate-reverse;
      animation-duration: 1s;
      animation-iteration-count: infinite;
      animation-name: working-pulse;
      animation-timing-function: ease;
    }
    &--queued {
      background-color: $color--brand-blue;
      opacity: 50%;
    }
    &--running {
      animation-direction: alternate-reverse;
      animation-duration: 1s;
      animation-iteration-count: infinite;
      animation-name: working-pulse;
      animation-timing-function: ease;
      background-color: $color--brand-blue;
    }
    &--none {
      background-color: $color--neutral-5;
    }
    &:hover {
      filter: brightness(75%);
      cursor: pointer;
    }
  }
}
