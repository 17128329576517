@import "../../../../web/shared/styles/styles.scss";

// Collapsible Sections
.repo-validation-drawer-collapse-container {
  margin-top: $spacing--md-lg;

  & .ant-collapse-header {
    display: flex;

    & .ant-collapse-expand-icon {
      align-self: center;
    }

    & .repo-validation-file-id {
      font-size: $font-size--sm;
      color: $color--text-secondary;
    }

    & .ant-collapse-header-text > * {
      margin: 0;
    }
  }

  & .repo-validation-drawer-error {
    display: flex;
    flex-direction: column;
    gap: $spacing--xs;

    & .repo-validation-drawer-error-path {
      font-family: $font-family--roboto-mono;
      width: fit-content;
    }

    & .repo-validation-drawer-error-message {
      color: $color--text-error;

      & .anticon-exclamation-circle {
        margin-right: $spacing--xxs;
      }
    }
  }
}
