@import "../../../shared/styles/styles.scss";

.project {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 1200px;
  overflow: hidden;
  padding: $spacing--xl;
  width: 100%;
}
