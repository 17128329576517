@import "../../web/shared/styles/styles.scss";

$sidebar-width: $spacing--xxxl;

.tree-categories-sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: $color--bg-view;
  width: $sidebar-width;
}

.tree-buttons-wrapper {
  border: none;
  display: flex;
  flex-direction: column;
  padding: $spacing--xs $spacing--xxxs 0 $spacing--xxxs;

  .category-button,
  .action-button {
    border-radius: 4px;
    font-size: $font-size--xs;
    height: fit-content;
    min-height: 60px;
    text-align: center;
    width: 100%;

    &--active {
      color: $color--coalesce-blue !important; // !important so it overrides antd's hover colors
    }

    &:focus,
    &:hover {
      background-color: $color--neutral-4;
    }

    &__icon,
    svg {
      display: block;
      margin: 0 auto;
      font-size: 18px;
      height: 18px;
      line-height: 18px;
      width: 18px;
    }
  }

  .category-button {
    padding: $spacing--xs 0 $spacing--xxs;
    &__icon,
    svg {
      & + span {
        margin: $spacing--xs auto 0 auto;
      }
    }
    + .category-button {
      margin-top: $spacing--xxxs;
    }
  }

  .clear-stale-subgraph-nodes-button {
    &:focus {
      background: unset;
    }
  }
}

.tree-misc-buttons-wrapper {
  @extend .tree-buttons-wrapper;
  margin: 0;
}

.tree-category-buttons-wrapper {
  @extend .tree-buttons-wrapper;
  margin: 0;
}

.cell-label {
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: nowrap;
  }
}

.new-workspace-tooltip {
  &__title-container {
    display: flex;
    padding: $spacing--sm;
    gap: $spacing--md;
  }

  &__header {
    color: $color--text-primary-inverted;
  }

  &__button-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: $spacing--xl;
  }

  &__close-button {
    color: $color--text-primary-inverted !important;
    width: 20px;
    align-self: flex-end;
  }

  &__got-it-button {
    color: $color--coalesce-blue !important;
  }

  &__overlay {
    & .ant-tooltip-arrow-content {
      --antd-arrow-background-color: ; // intentionally blank
      background-color: $color--coalesce-blue;
    }
    & .ant-tooltip-inner {
      background-color: $color--coalesce-blue;
    }
    &.ant-tooltip {
      max-width: 425px;
    }
  }
}
