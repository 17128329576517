// TODO: get the REAL font sizes - this is just here to establish the pattern

$font-size--xs: 11px;
$font-size--sm: 12px;
$font-size--md: 14px;
$font-size--lg: 16px;
$font-size--l-xl: 20px;
$font-size--xl: 24px;
$font-size--xxl: 32px;
$font-size--xxxl: 48px;

$font-family--roboto: "Roboto", sans-serif;
$font-family--roboto-mono: "Roboto Mono", monospace;

$line-height--20: 20px;
$line-height--22: 22px;
$line-height--24: 24px;

$font-weight--600: 600;
$font-weight--500: 500;
$font-weight--400: 400;
$font-weight--300: 300;
