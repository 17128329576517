@import "../../web/shared/styles/styles.scss";

.ant-tabs-top.DataTabs > .ant-tabs-nav {
  margin: 0px;
}

.DataTabs.ant-tabs .ant-tabs-tabpane {
  padding-left: 0px;
}

.DataTabs .ant-tabs-nav-list > .ant-tabs-tab {
  margin: 0 8px;
}

// this ensures the scrolling bar in Results panel & the grid in Data panel show up
.DataTabs > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane.ant-tabs-tabpane-active {
  height: 100%;
}

.query-result-sequence__collapse.ant-collapse {
  & > .ant-collapse-item {
    background-color: $color--bg-primary;
  }
}

.run-result__collapse.ant-collapse {
  > .ant-collapse-item {
    border: none;
    background-color: $color--bg-secondary;

    > .ant-collapse-header {
      border: none;
    }
  }

  b {
    font-size: small;
  }
}

.sqlMonacoBorder {
  border: 1px solid $color--divider-2;
  border-radius: 12px;
  margin: 3px;
  padding: $spacing--xxs;
}
