@import "../../web/shared/styles/styles.scss";

.CoalesceCSSTag {
  border: 1px solid $color--divider-2;
  border-top-left-radius: 4px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 0px;
  padding-left: 8px;
  padding-right: 8px;
  margin-left: -8px;
  color: rgba(0, 0, 0, 1);
  background-color: $color--bg-view;
  border-radius: 8px;
}

.borderRightNone {
  border-right: none;
}

.noBorderRadiusRight {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.smallInputBox.ant-input.ant-input-sm {
  padding: 0px;
}

.node-status-container > div {
  justify-content: center;
}
