@import "../../styles/styles.scss";

.key-value-tooltip {
  overflow: hidden;
  text-overflow: ellipsis;
}

.ant-tooltip.key-value-tooltip-overlay {
  max-width: none;

  .ant-tooltip-inner {
    font-size: $font-size--xs;
    min-height: 16px;
  }
}

.key-value-tooltip-overlay {
  &__table {
    &__row {
      &__label {
        font-weight: 800;
        text-transform: capitalize;
        padding-right: $spacing--xs;
      }

      &__label,
      &__text {
        text-align: left;
        white-space: nowrap;
      }
    }
  }
}
