@import "../../../../transform/variables.scss";
@import "../../../../web/shared/styles/styles.scss";

.nav-menu {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  gap: $spacing--md;
  height: $spacing--xxl;

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: $spacing--xxl;
    width: $spacing--xxxl;
    color: $color--neutral-8;
    font-size: $font-size--sm;
    text-decoration: none;
    transition: background-color 0.2s linear, color 0.2s linear;

    &__icon {
      height: $spacing--lg;
      width: $spacing--lg;
      margin-bottom: $spacing--xxxs;
      fill: $color--neutral-8;
    }

    &:hover {
      color: $color--text-primary;
      background-color: $color--neutral-3;
      .nav-menu__item__icon {
        fill: $color--text-primary;
      }
    }

    &.active {
      color: $color--brand-blue;
      .nav-menu__item__icon {
        fill: $color--brand-blue;
      }
    }
  }
}
