@import "../../../web/shared/styles/styles.scss";

.column-viewer {
  display: flex;
  flex-direction: column;

  &__grid {
    flex: 1 0 auto;
    overflow-x: auto;

    &__header {
      color: $color--text-secondary;
      text-align: right;
    }

    &__row {
      background-color: $color--bg-primary;
    }
  }
}
