@import "../../../web/shared/styles/styles.scss";

.source-tree {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  &__search {
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: $spacing--sm;
    padding-right: $spacing--sm;

    & > * + * {
      margin-left: $spacing--xs;
    }
  }

  &__grid {
    flex: 1;
    height: 100%;
    width: 100%;

    .ag-root .ag-header {
      display: none;
    }

    .ag-row-level-0 .ag-cell {
      padding-left: 0px;
    }
    .ag-row-level-1 .ag-cell {
      padding-left: $spacing--xxl;
    }
    .ag-row-level-1.ag-row-group-contracted .ag-cell,
    .ag-row-level-1.ag-row-group-expanded .ag-cell {
      padding-left: $spacing--xs;
    }

    .ag-row-level-2 .ag-cell,
    .ag-row-level-3 .ag-cell {
      cursor: pointer;
    }
  }
}
