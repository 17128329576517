@import "../../../../../web/shared/styles/styles.scss";

.job-schedule-definition-form {
  &__form {
    display: flex;
    flex-direction: column;
    gap: $spacing--sm;
  }

  &__form-item {
    margin-bottom: 24px;
  }

  div &__cron-expression-explain {
    color: $color--text-secondary;
  }

  &__divider {
    padding-top: $spacing--lg;
    & .ant-divider-inner-text {
      font-weight: $font-weight--500;
    }
  }

  &__environment-select-info {
    & > div {
      display: flex;
      flex-direction: row;
      height: 100%;
      gap: $spacing--sm;
    }

    & p {
      margin-bottom: 0;
    }

    border: none;
    border-top: 1px solid $color--divider-2;
  }

  div &__cron-help-message {
    color: $color--text-secondary;
  }
}
