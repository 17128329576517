@import "../../styles/styles.scss";

.free-trial-tc-sheet {
  padding: $spacing--md;

  &__list {
    list-style-type: none;

    li {
      margin-top: $spacing--sm;
    }

    &--numbered {
      li {
        b {
          margin-left: $spacing--lg;
        }
      }
    }
  }
}
