@import "../../../../shared/styles/styles.scss";

.packages-picker-tool {
  width: 100%;

  &__selector-grid-wrapper {
    margin: auto;
    width: 95%;
  }

  &__button-wrapper {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    width: 100%;

    & > * {
      margin: $spacing--xl;
    }
  }
}
