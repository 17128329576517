@import "./antd-colors.scss";
@import "./marketing-colors.scss";

/**********  Antd 4 text colors  *************/

$color--text-title: transparentize($color--neutral-13, 0.15); // black 85% opacity
$color--text-primary: transparentize($color--neutral-13, 0.15); // black 85% opacity
$color--text-secondary: transparentize($color--neutral-13, 0.55); // black 45% opacity
$color--text-tertiary: transparentize(
  $color--neutral-13,
  0.75
); // black 25% opacity, also called disabled/placeholder in figma

$color--text-primary-inverted: $color--neutral-1; // white text

$color--text-success: $color--green-6; // green color for success text and icons
$color--text-warning: $color--calendula-gold-6; // yellow color for warning text and icons
$color--text-error: $color--dust-red-6; // red color for error text and icons
$color--text-info: $color--daybreak-blue-6; // blue color for information text and icons

/********  Antd 4 auxiliary colors  ***********/

$color--item-bg-hover: $color--neutral-3; // #f5f5f5 light grey hover state for lists, tabs, etc
$color--item-bg-selected: $color--daybreak-blue-1; // #e6f7ff light blue color used for selected state of lists, tabs, etc
$color--item-text-selected: $color--daybreak-blue-6; // bright blue used to highlight text of selected items

/*************  Coalesce colors  **************/

// Our own variables. OK if they map to antd colors.

$color--coalesce-blue: $color--daybreak-blue-6; // called coalesce blue but matches the antd primary color

$color--bg-primary: $color--neutral-1; // white background
$color--bg-secondary: #f9f9f9; // light grey background, use against white background

$color--bg-graph: $color--neutral-4; // #f0f0f0. Use ONLY for the graph background
$color--bg-view: #f5f6fa; // grey page background. Use ONLY for the page background

$color--divider-1: $color--neutral-4; // #f0f0f0, lighter grey divider color. Used by antd as the border of table rows and cards
$color--divider-2: $color--neutral-5; // #d9d9d9, darker grey divider color. Used by antd as lists divider

// Nodes
$color--node-bg-selected: #6ea7db; // selected node color used in graph and lineage

// Merge Conflict Highlighting Colors
$color--conflict-current: desaturate($color--cyan-2, 30%);
$color--conflict-current-dark: darken($color--conflict-current, 10%);
$color--conflict-current-light: desaturate($color--cyan-1, 30%);
$color--conflict-incoming: desaturate($color--geek-blue-2, 30%);
$color--conflict-incoming-dark: darken($color--conflict-incoming, 10%);
$color--conflict-incoming-light: desaturate($color--geek-blue-1, 30%);

$color--highlight: $color--calendula-gold-2; // light yellow highlight color
