@import "../../../../../shared/styles/styles.scss";

.progress-bar {
  align-items: center;
  display: flex;
  height: 100%;
  min-width: 500px;
  width: 100%;

  &__content {
    flex: 1;
    margin-right: $spacing--xxs;

    &__elements {
      background-color: $color--text-secondary;
      border-radius: 10px;
      display: flex;
      flex-wrap: nowrap;
      height: 12px;
      position: relative;
      width: 100%;

      &__element {
        border: none;
        border-radius: 10px;
        height: 100%;
        position: absolute;
      }
    }

    &__labels {
      font-size: $font-size--sm;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-evenly;
      margin-top: $spacing--xxs;
      width: 100%;
    }
  }
}
