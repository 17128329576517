@import "../../../../../../web/shared/styles/styles.scss";

$animation-transition: 0.25s;

.docs-node-top {
  &__breadcrumbs {
    align-items: center;
    display: flex;
    grid-column-end: 25;
    grid-column-start: 1;
    padding: 0px $spacing--md;
  }

  &__title {
    grid-column-start: 5;
    grid-column-end: 21;
    display: flex;
    align-items: flex-end;
    font-size: $font-size--xl;
    margin-bottom: $spacing--md;

    &--icon {
      margin-right: $spacing--sm;
    }

    &--text {
      margin: 0px;
      font-weight: 600;
      font-size: $font-size--xl;
    }
  }

  &__subtitle {
    grid-column-start: 5;
    grid-column-end: 21;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    &--item {
      display: flex;
      align-items: center;
    }
    &--text {
      margin: 0px;
      font-weight: 500;
      font-size: $font-size--md;
    }
    &--icon {
      margin-right: $spacing--xs;
    }
  }
}

.flex {
  display: flex;
}

.margin-left-md {
  margin-left: $spacing--md;
}
