.main-window {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  width: 100%;

  &--is-add-sql-activated {
    .ant-tabs-content {
      z-index: 5;
    }
  }

  &--empty-name-activated {
    .ant-tabs-content {
      flex: 1 0 auto;
      z-index: 0;
    }
  }
}
