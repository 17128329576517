@import "../../../styles/styles.scss";

.graph-controls {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  left: 0;
  padding: $spacing--xxs;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;

  &__buttons {
    background-color: $color--bg-primary;
    border-radius: 2px;
    display: inline-block;
    flex-wrap: nowrap;
    justify-content: flex-end;
  }
}
