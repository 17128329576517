.step-tab-content {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  overflow: hidden;
  width: 100%;

  &__horizontal-split {
    display: flex;
    flex-wrap: nowrap;
    height: 100%;
    overflow: hidden;
    width: 100%;
  }

  &__vertical-split {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    height: 100%;
    overflow: hidden;
  }

  &__sql-and-run {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}
