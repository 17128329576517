@import "../../../web/shared/styles/styles.scss";

.query-results {
  &__header {
    align-items: center;
    display: flex;
    justify-content: space-between;

    &__text {
      font-size: $font-size--sm;

      &--error {
        color: $color--text-error;
      }
    }

    &__timing {
      font-size: $font-size--xs;
      margin-left: $spacing--sm;
      text-wrap: nowrap;
    }
  }
}
