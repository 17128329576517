@import "../../transform/variables.scss";
@import "../../web/shared/styles/styles.scss";

.user-activation {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: scroll;

  &__header {
    align-self: center;
    margin: $spacing--lg auto;
    width: 500px;
  }

  &__form {
    display: grid;
    padding: 16px 48px 16px 16px;
    width: 800px;
    background-color: $color--bg-primary;
    border-radius: 12px;
    align-self: center;
    margin-bottom: 16px;

    &__header-text {
      margin: $spacing--xl;
      justify-self: center;
      align-self: center;
    }

    .ant-form-item-control-input-content {
      justify-content: center;
    }

    input {
      height: auto;
      padding: 7px 11px; // Common.scss does some unnecessary overrides, this is actually antd's default styling
    }

    button.ant-btn.ant-btn-primary {
      margin-top: $spacing--md;
    }

    #phoneNumber {
      width: 100%;
      height: 40px;
      border: 1px solid $color--divider-2;
      border-radius: 8px;
    }
  }
}
