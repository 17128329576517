@import "../../../web/shared/styles/styles.scss";

.step-field {
  width: 100%;
  cursor: pointer;

  &__edit-icon.anticon-edit {
    color: $color--coalesce-blue;
    font-size: $font-size--lg;
    visibility: hidden;
  }
}

.step-field:hover .step-field__edit-icon.anticon-edit {
  visibility: visible;
}
