$spacing--xxxs: 2px;
$spacing--xxs: 4px;
$spacing--xs: 8px;
$spacing--sm: 12px;
$spacing--md: 16px;
$spacing--md-lg: 20px;
$spacing--lg: 24px;
$spacing--lg-xl: 28px;
$spacing--xl: 32px;
$spacing--xl-xxl: 40px;
$spacing--xxl: 48px;
$spacing--xxxl: 64px;
