@import "../../../../shared/styles/styles.scss";

.file-diff-viewer {
  background-color: $color--bg-primary;
  border-left: 1px solid $color--divider-2;
  border-radius: 0;
  display: flex;
  flex-direction: column;
  grid-area: diff;
  padding-left: $spacing--sm;
  width: 100%;
  overflow: hidden;

  &__header {
    align-items: center;
    display: flex;

    &__filename {
      margin: 0;
      padding: 0;
    }

    &__directory {
      color: $color--text-secondary;
      font-size: $font-size--sm;
      margin-left: $spacing--xs;
    }
  }

  &__column-titles {
    color: $color--text-secondary;
    display: flex;
    font-size: $font-size--sm;
    margin-bottom: $spacing--xxs;

    & > * {
      flex: 1;
    }
  }

  &__editor-wrapper {
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: center;
  }
}
