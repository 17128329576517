@import "../../../shared/styles/styles.scss";

.user-menu {
  &__information {
    &__email {
      color: $color--text-secondary;
    }
    &__name {
      color: $color--text-primary;
    }
  }

  &__logout {
    display: flex;
    gap: $spacing--xs;
  }
}
