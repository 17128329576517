@import "../../../web/shared/styles/styles.scss";

.config-menu-groups-wrapper {
  height: 100%;
  overflow: hidden;

  .config-menu-groups {
    height: 100%;
    overflow: auto;

    .config-menu-group {
      &__header {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
      }
    }
  }

  .ant-collapse.config-menu-groups {
    border: none;
  }
}
