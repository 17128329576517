@import "../../../web/shared/styles/styles.scss";

.result-header {
  align-items: center;
  display: flex;
  overflow: hidden;
  width: 100%;

  .anticon.result-header__icon {
    &--test {
      font-size: $font-size--lg;
    }

    &--error {
      color: $color--text-error;
    }

    &--success {
      color: $color--text-success;
    }
  }

  & > * + * {
    margin-left: $spacing--xs !important; // needed to override h5/tooltip-on-truncation default margin
  }
}
