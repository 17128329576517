@import "../../../web/shared/styles/styles.scss";

.runs-list-container {
  margin-left: $spacing--xs;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  height: 100%;
  border-radius: 12px;
  background-color: $color--bg-primary;
  overflow: hidden;
}

.runs-list-component {
  height: 100%;
  overflow-y: hidden;
  margin: $spacing--xs;

  .ant-list {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100%;
    overflow-y: hidden;

    .ant-spin-nested-loading {
      flex: 1;
      overflow-y: auto;
    }
  }

  .ant-list-item {
    padding: $spacing--sm 0 $spacing--sm $spacing--sm;
  }

  .ant-list-item-action {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .ant-spin-container,
  .ant-list-empty-text,
  .ant-empty,
  .ant-empty-image {
    height: 100%;

    & > .ant-empty-img-simple {
      width: 64px;
    }
  }

  .run-item {
    .environment-tag {
      margin-right: 0;
    }
    &__time {
      font-size: $font-size--sm;
    }
    &__id {
      font-size: $font-size--sm;
      color: $color--text-secondary;
    }
  }
}

.canceling-run i {
  background-color: $color--neutral-7;
}

.run-status-indicator {
  margin-right: $spacing--md;

  &--spinner {
    font-size: $font-size--l-xl;
  }

  &--failed {
    font-size: $font-size--l-xl;

    color: $color--text-error !important;
  }

  &--testFailed {
    font-size: $font-size--l-xl;

    color: $color--text-warning !important;
  }

  &--completed {
    font-size: $font-size--l-xl;

    & svg {
      color: $color--text-success !important;
    }
  }

  &--canceled {
    font-size: $font-size--l-xl;
    color: $color--neutral-5 !important;
  }
}
