@import "../../../../shared/styles/styles.scss";

.hash-column-editor {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  width: 100%;

  &__selector {
    align-items: center;
    display: flex;
    padding: $spacing--sm;
    width: 100%;

    &__select.ant-select {
      flex: 1;
      margin-left: $spacing--xxs;
    }
  }
}
