@import "../../../web/shared/styles/styles.scss";

.multi-source-node {
  height: 100%;
  overflow: hidden;

  &__tabs.ant-tabs {
    height: 100%;
    padding-left: $spacing--xxxs;

    & > .ant-tabs-nav {
      margin-top: $spacing--sm;

      & > .ant-tabs-nav-wrap {
        & > .ant-tabs-nav-list {
          & > .ant-tabs-tab {
            width: 100%;

            & > .ant-tabs-tab-btn {
              width: 100%;
            }

            // The draggable tabs currently only work on tabs that are closeable
            // This hides the close button from the dom
            & > .ant-tabs-tab-remove {
              display: none;
            }

            .anticon {
              margin-right: 0;
            }
          }

          & > .ant-tabs-tab-active {
            border: 1px solid $color--brand-blue;
            border-right: $color--neutral-1;
          }

          & > .ant-tabs-nav-add {
            margin-top: $spacing--xs;
            border: none;
            background-color: $color--bg-primary;

            &:hover {
              background-color: $color--item-bg-hover;
            }
          }
        }
      }
    }

    & > .ant-tabs-content-holder {
      & > .ant-tabs-content {
        & > .ant-tabs-tabpane {
          padding-left: 0;
        }
      }
    }

    & .sql-step-menu-title-input {
      cursor: pointer;

      &__edit-icon.anticon-edit {
        font-size: $font-size--lg;
        visibility: hidden;
      }
    }

    .sql-step-menu-title-input:hover .sql-step-menu-title-input__edit-icon.anticon-edit {
      visibility: visible;
    }
  }
}
