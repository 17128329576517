.overflow {
  height: 100%;
  overflow: auto;
  width: 100%;

  &__wrapper {
    height: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;
  }

  &__shadow {
    display: block;
    margin: 0 !important;
    padding: 0 !important;
    position: absolute;

    &--top {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 100%);
      height: 10px;
      left: 0;
      top: 0;
      width: 100%;
    }

    &--bottom {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 100%);
      bottom: 0;
      height: 10px;
      left: 0;
      width: 100%;
    }

    &--left {
      background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 100%);
      height: 100%;
      left: 0;
      top: 0;
      width: 10px;
    }

    &--right {
      background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 100%);
      height: 100%;
      right: 0;
      top: 0;
      width: 10px;
    }
  }
}
