@import "../../shared/styles/styles.scss";

.CommitListMeta {
  .ant-checkbox-wrapper {
    padding-right: $spacing--xs;
  }
}

.settingsContainer {
  .visibilityHidden {
    visibility: hidden !important;
  }
  .marginBottom-5 {
    margin-bottom: -5px;
  }
  .ant-row.ant-form-item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
}
.workspace-settings-user-credentials {
  & .ant-row.ant-form-item-row {
    padding-bottom: $spacing--lg;
  }
}
