@import "../../../web/shared/styles/styles.scss";

.repo-validation-drawer {
  font-size: $font-size--md;

  & .ant-drawer-body {
    scrollbar-gutter: stable;
  }

  // To match figma had to disable default close button which left a gap in title box
  & .ant-drawer-close {
    width: 0;
    padding: 0;
    margin: 0;
  }

  // Added manual icon, removed button default styling to match figma
  & .repo-validation-drawer-close-button.ant-btn {
    border: none;
    box-shadow: none;

    & > .anticon {
      color: $color--text-secondary;
    }
  }

  & .repo-validation-branch-name {
    font-weight: $font-weight--500;
  }

  & .repo-validation-docs-link {
    margin-left: $spacing--xxs;
  }

  // Footer Buttons
  & .ant-drawer-footer {
    display: flex;
    justify-content: flex-end;
    gap: $spacing--xs;
  }
}
