@import "../../../../web/shared/styles/styles.scss";

.parsing-error-drawer-collapse-container {
  margin-top: $spacing--md-lg;

  & .ant-collapse-header {
    display: flex;

    & .parsing-error-file-name {
      margin: 0;
    }
  }

  // CD-7758 This makes the arrows on the arrow bar transparent. If we ever make make changes so that the parser can catch more than one error in a file remove this CSS
  & .menu-entry,
  .filename {
    opacity: 0;
    pointer-events: none;
  }

  // This is used to manually change the height of the monaco editor when the error banner is present since monaco bases its size off its container not based on its content
  & .parsing-error-monaco-editor {
    height: 110px;
  }

  & .parsing-error-monaco-editor-error {
    height: 175px;
  }
}
