/**********************************************/
/*************  Antd 4 colors  ****************/
/**********************************************/

// https://4x.ant.design/docs/spec/colors

// Neutral Gray
$color--neutral-1: #ffffff;
$color--neutral-2: #fafafa;
$color--neutral-3: #f5f5f5;
$color--neutral-4: #f0f0f0;
$color--neutral-5: #d9d9d9;
$color--neutral-6: #bfbfbf;
$color--neutral-7: #8c8c8c;
$color--neutral-8: #595959;
$color--neutral-9: #434343;
$color--neutral-10: #262626;
$color--neutral-11: #1f1f1f;
$color--neutral-12: #141414;
$color--neutral-13: #000000;

// Dust Red
$color--dust-red-1: #fff1f0;
$color--dust-red-2: #ffccc7;
$color--dust-red-3: #ffa39e;
$color--dust-red-4: #ff7875;
$color--dust-red-5: #ff4d4f;
$color--dust-red-6: #f5222d;
$color--dust-red-7: #cf1322;
$color--dust-red-8: #a8071a;
$color--dust-red-9: #820014;
$color--dust-red-10: #5c0011;

// Volcano
$color--volcano-1: #fff2e8;
$color--volcano-2: #ffd8bf;
$color--volcano-3: #ffbb96;
$color--volcano-4: #ff9c6e;
$color--volcano-5: #ff7a45;
$color--volcano-6: #fa541c;
$color--volcano-7: #d4380d;
$color--volcano-8: #ad2102;
$color--volcano-9: #871400;
$color--volcano-10: #610b00;

// Sunset Orange
$color--sunset-orange-1: #fff7e6;
$color--sunset-orange-2: #fff7e6;
$color--sunset-orange-3: #ffd591;
$color--sunset-orange-4: #ffc069;
$color--sunset-orange-5: #ffa940;
$color--sunset-orange-6: #fa8c16;
$color--sunset-orange-7: #d46b08;
$color--sunset-orange-8: #ad4e00;
$color--sunset-orange-9: #873800;
$color--sunset-orange-10: #612500;

// Calendula Gold
$color--calendula-gold-1: #fffbe6;
$color--calendula-gold-2: #fff1b8;
$color--calendula-gold-3: #ffe58f;
$color--calendula-gold-4: #ffd666;
$color--calendula-gold-5: #ffc53d;
$color--calendula-gold-6: #faad14;
$color--calendula-gold-7: #d48806;
$color--calendula-gold-8: #ad6800;
$color--calendula-gold-9: #874d00;
$color--calendula-gold-10: #613400;

// Sunrise Yellow
$color--sunrise-yellow-1: #feffe6;
$color--sunrise-yellow-2: #ffffb8;
$color--sunrise-yellow-3: #fffb8f;
$color--sunrise-yellow-4: #fff566;
$color--sunrise-yellow-5: #ffec3d;
$color--sunrise-yellow-6: #fadb14;
$color--sunrise-yellow-7: #d4b106;
$color--sunrise-yellow-8: #ad8b00;
$color--sunrise-yellow-9: #876800;
$color--sunrise-yellow-10: #614700;

// Lime
$color--lime-1: #fcffe6;
$color--lime-2: #f4ffb8;
$color--lime-3: #eaff8f;
$color--lime-4: #d3f261;
$color--lime-5: #bae637;
$color--lime-6: #a0d911;
$color--lime-7: #7cb305;
$color--lime-8: #5b8c00;
$color--lime-9: #3f6600;
$color--lime-10: #254000;

// Polar Green
$color--green-1: #f6ffed;
$color--green-2: #d9f7be;
$color--green-3: #b7eb8f;
$color--green-4: #95de64;
$color--green-5: #73d13d;
$color--green-6: #52c41a;
$color--green-7: #389e0d;
$color--green-8: #237804;
$color--green-9: #135200;
$color--green-10: #092b00;

// Cyan
$color--cyan-1: #e6fffb;
$color--cyan-2: #b5f5ec;
$color--cyan-3: #87e8de;
$color--cyan-4: #5cdbd3;
$color--cyan-5: #36cfc9;
$color--cyan-6: #13c2c2;
$color--cyan-7: #08979c;
$color--cyan-8: #006d75;
$color--cyan-9: #00474f;
$color--cyan-10: #002329;

// Daybreak Blue
$color--daybreak-blue-1: #e6f7ff;
$color--daybreak-blue-2: #bae7ff;
$color--daybreak-blue-3: #91d5ff;
$color--daybreak-blue-4: #69c0ff;
$color--daybreak-blue-5: #40a9ff;
$color--daybreak-blue-6: #1890ff;
$color--daybreak-blue-7: #096dd9;
$color--daybreak-blue-8: #0050b3;
$color--daybreak-blue-9: #003a8c;
$color--daybreak-blue-10: #002766;

// Geek blue
$color--geek-blue-1: #f0f5ff;
$color--geek-blue-2: #d6e4ff;
$color--geek-blue-3: #adc6ff;
$color--geek-blue-4: #85a5ff;
$color--geek-blue-5: #597ef7;
$color--geek-blue-6: #2f54eb;
$color--geek-blue-7: #1d39c4;
$color--geek-blue-8: #10239e;
$color--geek-blue-9: #061178;
$color--geek-blue-10: #030852;

// Purple
$color--purple-1: #f9f0ff;
$color--purple-2: #efdbff;
$color--purple-3: #d3adf7;
$color--purple-4: #b37feb;
$color--purple-5: #9254de;
$color--purple-6: #722ed1;
$color--purple-7: #531dab;
$color--purple-8: #391085;
$color--purple-9: #22075e;
$color--purple-10: #120338;

// Magenta
$color--magenta-1: #fff0f6;
$color--magenta-2: #ffd6e7;
$color--magenta-3: #ffadd2;
$color--magenta-4: #ff85c0;
$color--magenta-5: #f759ab;
$color--magenta-6: #eb2f96;
$color--magenta-7: #c41d7f;
$color--magenta-8: #9e1068;
$color--magenta-9: #780650;
$color--magenta-10: #520339;
