@import "../../../../../../shared/styles/styles.scss";

.copy-workspace-objects-modal {
  .ant-modal-footer {
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
}

.copy-workspace-objects {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  width: 100%;

  &__icon {
    margin-right: $spacing--sm;
    color: $color--text-primary;

    &--success {
      color: $color--text-success;
    }

    &--failed {
      color: $color--text-error;
    }
  }

  &__status {
    &--hidden {
      visibility: hidden;
    }

    &--visible {
      visibility: visible;
    }
  }

  .workspace-selectors {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: $spacing--md;
    width: 100%;

    &__arrow-container {
      align-items: center;
      display: flex;
      justify-content: center;
    }

    &__panel {
      align-items: flex-start;
      background-color: $color--bg-secondary;
      border: 1px solid $color--divider-1;
      border-radius: 12px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: $spacing--md;
      width: 45%;
    }
  }

  &__body {
    flex: 1;
    height: 100%;
    margin: auto;
    overflow: auto;
    width: 100%;

    .ant-empty {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      margin: auto;
    }
  }
}
