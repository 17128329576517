@import "../../../web/shared/styles/styles.scss";

$animation-transition: 0.3s;

.docs-grid {
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  grid-template-rows: 200px 1fr;
  height: 100%;
  overflow: hidden;
  position: relative;
  transition: $animation-transition;
  &--small {
    box-shadow: 0px 0px 5px $color--neutral-13;
    grid-template-rows: 130px 1fr;
  }
}

.docs-top {
  background-color: $color--bg-primary;
  display: grid;
  grid-column-end: 25;
  grid-column-start: 1;
  grid-row: 1;
  grid-template-columns: repeat(24, 1fr);
  position: sticky;
  top: 0;
  z-index: 1;
  overflow-y: hidden;
  scrollbar-gutter: stable;
  &--small {
    box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.1);
    transition: $animation-transition;
  }
}

.docs-bottom {
  display: grid;
  grid-column-end: 25;
  grid-column-start: 1;
  grid-row: 2;
  grid-template-columns: repeat(24, 1fr);
  height: 100%;
  overflow-y: auto;
  padding-bottom: 150px;
  &__containerLeft {
    grid-column-end: 5;
    grid-column-start: 1;
    padding: $spacing--lg $spacing--md;
  }
  &__containerMain {
    display: flex;
    flex-direction: column;
    grid-column-end: 21;
    grid-column-start: 5;
    padding: $spacing--lg 0px;
  }
  &__containerRight {
    grid-column-end: 25;
    grid-column-start: 21;
    padding: $spacing--lg $spacing--md;
  }
}
