.snowflake-credentials-form {
  &__role-and-warehouse {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .ant-form-item {
      width: 48%;
    }
  }
}
