@import "../../../web/shared/styles/styles.scss";

.nodes-tree {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;

  // Indent some levels of the tree
  // Unsure why we have to set the indentation manually. May be something we're doing wrong or a bug in ag-grid.
  .ag-row-level-1 {
    .ag-drag-handle {
      display: none !important;
    }
    .ag-cell {
      padding-left: $spacing--md; //overridden by ag-grid.scss
    }
  }
  .ag-row-level-2 {
    .ag-cell {
      padding-left: calc($spacing--xl + $spacing--sm) !important; //overridden by ag-grid.scss
    }
  }

  &__column-viewer {
    flex: 0 0 40%;
  }
}
