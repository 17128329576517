@import "../../styles/styles.scss";

.loading-spinner-with-text {
  margin: 0;
}

.loading-spinner-with-text > .anticon-loading {
  color: $color--coalesce-blue;
  margin-right: $spacing--xs;
}
