@import "../../../../../shared/styles/styles.scss";

.environment-runs-overview {
  &__container {
    height: 100%;
    position: relative;
    width: 100%;

    &--visible {
      display: block;
    }

    &--not-visible {
      display: none;
    }

    // https://css-tricks.com/an-auto-filling-css-grid-with-max-columns/
    &__section {
      $grid-item--min-width: 250px;
      $grid-layout-gap: $spacing--lg;
      $grid-column-count: 4;
      $gap-count: calc($grid-column-count - 1);
      $total-gap-width: calc($gap-count * $grid-layout-gap);
      $grid-item--max-width: calc((100% - $total-gap-width) / $grid-column-count);

      display: grid;
      grid-column-gap: $grid-layout-gap;
      grid-row-gap: $grid-layout-gap;
      grid-template-columns: repeat(auto-fill, minmax(max($grid-item--min-width, $grid-item--max-width), 1fr));
      height: fit-content;
      width: 100%;
    }
  }

  .ant-divider-inner-text {
    color: $color--text-tertiary;
  }

  .ant-empty {
    margin: $spacing--lg auto;
    color: $color--text-tertiary;

    .ant-empty-image {
      height: unset;

      svg {
        width: 64px;
      }
    }
  }
}
