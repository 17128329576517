@import "../../../../../../../../shared/styles/styles.scss";

.browser-tab-header-controls {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  width: 100%;

  &__view-options {
    flex: 1;
  }

  &__buttons {
    align-items: center;
    display: flex;
    flex-wrap: nowrap;

    & > * + * {
      margin-left: $spacing--xs;
    }
  }
}
