@import "../../../../../../web/shared/styles/styles.scss";

.status {
  &--success {
    > svg {
      color: $color--text-success !important;
    }
  }

  &--running {
  }

  &--queued {
    > svg {
      color: $color--neutral-7 !important;
    }
  }

  &--failed {
    > svg {
      color: $color--text-error !important;
    }
  }
}
