.ant-drawer.error-drawer {
  top: unset;
  bottom: unset;
  position: absolute;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  text-wrap: nowrap;
  margin-bottom: 0;
  padding-left: 9px;
  font-size: 14px;
  list-style-position: inside;
  & ul {
    padding-inline-start: unset;
    padding-left: 14px;
    > li::marker {
      font-size: small;
    }
  }
}
