@import "../../../shared/styles/styles.scss";

.create-workspace-wizard {
  &__parent {
    max-width: 62.5%;
    width: 100%;
    height: 100%;
    margin: $spacing--xs;
    background-color: $color--bg-primary;
    padding: $spacing--lg $spacing--lg-xl;
    display: flex;
    flex-direction: column;
  }

  &__close-button.ant-btn {
    align-self: flex-end;
  }

  &__content {
    width: 100%;
    height: 100%;
    padding: 56px 82px; // variables stop at 64px, this is specific to this area of the app
    display: flex;
    flex-direction: column;
    gap: $spacing--md;
    overflow: auto;
  }

  &__subtitle {
    font-size: $font-size--lg;
    font-weight: $font-weight--600;
  }

  &__current-step-key {
    font-size: $font-size--sm;
    color: $color--text-secondary;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
  }

  &__previous-bttn {
    margin-right: $spacing--sm;
  }
}

.create-workspace-wizard-step {
  &__container {
    display: flex;
    flex-direction: column;
    gap: $spacing--md;
    margin-top: $spacing--md;
  }

  &__title {
    font-size: $font-size--xxl;
    font-weight: $font-weight--600;
  }

  &__subtitle {
    font-size: $font-size--lg;
    color: $color--text-secondary;
  }
}
