@import "../../../web/shared/styles/styles.scss";

.tree {
  display: flex;
  flex-wrap: nowrap;
  height: 100%;
  position: relative;
  width: 100%;

  &__grid {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    width: 100%;

    &--with-header {
      .ag-header {
        min-height: 0 !important;
        height: 0 !important;
      }
      .ag-group-value {
        width: -webkit-fill-available !important;
      }
    }

    &--with-sidebar {
      width: calc(100% - 60px);
    }

    &__header {
      &--custom {
        align-items: center;
        background: $color--bg-view;
        color: $color--text-secondary;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: -$spacing--xxxs;
        padding: $spacing--xxs $spacing--xxxs;

        .ant-page-header {
          padding: 0;

          .ant-page-header-heading {
            flex-wrap: unset;
            margin: 0;
          }

          .ant-page-header-heading-left {
            justify-content: center;
            padding-left: $spacing--sm;

            .ant-page-header-heading-title {
              overflow: visible;
            }
          }
        }
      }
    }

    &__content {
      display: contents;
      flex: 1 0 auto;
      width: 100%;
    }
  }
}
