@import "../../transform/variables.scss";
@import "../../web/shared/styles/colors.scss";

.app-layout {
  align-items: stretch;
  background-color: $color--bg-view;
  display: flex;
  flex-direction: column;
  height: 100vh;
  min-width: 768px;
  width: 100vw;

  & > .main-content {
    flex: 1;
    height: 100%;
    overflow: hidden;
  }
}

.header-content {
  box-shadow: 0px 1px 2px 0px transparentize($color--neutral-13, 0.9);
  z-index: 99;
}

.app-layout__error {
  max-width: 50%;
}
