@import "../../../../shared/styles/styles.scss";

.project-members {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  &__header {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-bottom: $spacing--sm;
  }

  &__alert.ant-alert {
    margin-bottom: $spacing--sm;
  }

  &__table {
    flex: 1;
    height: 100%;
    overflow: hidden;
    width: 100%;

    &__column {
      &--member {
        min-width: 100px;
      }

      &--role {
        min-width: 100px;
      }

      &--actions {
        min-width: 160px;
      }
    }
  }

  .project-member-role-text {
    text-transform: capitalize;
  }
}
