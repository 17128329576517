@import "../../../../../shared/styles/styles.scss";

.version-control-form {
  label {
    width: 100%;
  }

  &__branch-selector {
    &__label {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      button.ant-btn-link {
        padding: 0;
      }
    }
  }

  &__commit-container,
  &__commit-container-dashed {
    display: flex;
    border: 1px $color--neutral-5;
    border-radius: 4px;
    align-items: center;
  }

  &__commit-container {
    justify-content: space-between;
    flex-direction: column;
    border-style: solid;

    &__commit-information {
      display: flex;
      flex-direction: column;
      margin: $spacing--md $spacing--sm;
      width: 100%;

      &__message {
        margin: 0;
      }

      &__secondary {
        display: flex;
        flex-direction: row;
        color: $color--text-secondary;

        time {
          margin-left: $spacing--sm;
        }

        p {
          margin: 0;
        }
      }
    }

    button {
      align-self: center;
      justify-self: center;
      margin: $spacing--sm;
    }
  }

  &__branch-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: $spacing--md auto;
  }

  &__commit-container-dashed {
    display: flex;
    justify-content: center;
    border-style: dashed;
    height: 76px; // combined height of the commit information + their margins

    button {
      margin: auto;
    }
  }
}

.ant-form-vertical .ant-form-item-row {
  width: 100%;
}
