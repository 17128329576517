@import "../../../../../../web/shared/styles/styles.scss";

.docs-node-overview {
  border-radius: 8px;
  margin-bottom: $spacing--sm;
  z-index: 0;
  &__items {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    row-gap: $spacing--md;
  }
  &__item {
    min-height: 44px;
    word-wrap: break-word;
    word-break: break-all;
  }
  &__value {
    font-weight: 400;
    font-size: $font-size--md;
    font-family: "Roboto";
    flex-wrap: wrap;
    color: $color--text-primary;
    white-space: pre-line;
  }
  &__label {
    font-weight: 500;
    font-size: $font-size--sm;
    color: $color--text-secondary;
    font-family: "Roboto";
  }
}

.disabled-text {
  color: $color--text-tertiary;
}
