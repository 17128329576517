@import "../../../shared/styles/styles.scss";

.column-editor {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__empty {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
  }

  &__bulk {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100%;
    min-height: 0;
    width: 100%;

    &__card {
      display: flex;
      flex: 1;
      flex-direction: column;
      margin-top: $spacing--xs;
      min-height: 0;
    }
  }
}
