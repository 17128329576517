.disable-totp-component-flex,
.disable-totp-reauth-component-flex {
  display: flex;
  flex-direction: column;
}

.disable-totp-component-flex-description {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 2em;
}

.disable-totp-component-flex-segmented-input {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.disable-totp-component-flex-segmented-input > .ant-row.ant-form-item-row,
.disable-totp-component-flex-segmented-input > .ant-row.ant-form-item-row > .ant-col.ant-form-item-control,
.disable-totp-component-flex-segmented-input
  > .ant-row.ant-form-item-row
  > .ant-col.ant-form-item-control
  > .ant-form-item-control-input,
.disable-totp-component-flex-segmented-input
  > .ant-row.ant-form-item-row
  > .ant-col.ant-form-item-control
  > .ant-form-item-control-input
  > .ant-form-item-control-input-content {
  width: 100%;
}
