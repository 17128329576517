@import "../../../../../../../shared/styles/styles.scss";

.workspace-selector {
  width: 100%;

  &__select {
    width: 100%;
  }

  &__required-asterisk {
    color: $color--text-error;
    margin: auto $spacing--xxs auto auto;
  }
}
