@import "../../../shared/styles/styles.scss";

.users-table {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  &__error-alert.ant-alert {
    margin-bottom: $spacing--md;
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    width: 100%;

    & > * + * {
      margin-left: $spacing--sm;
    }
  }

  &__table {
    flex: 1;
    height: 100%;
    overflow: hidden;

    &__column {
      &--id {
        min-width: 150px;
      }

      &--name {
        min-width: 200px;
      }

      &--email {
        min-width: 100px;
      }

      &--role {
        min-width: 100px;
        text-transform: capitalize;
      }

      &--organization {
        min-width: 200px;
      }

      &--status {
        min-width: 100px;
      }

      &--mfa {
        min-width: 100px;
      }

      &--actions {
        min-width: 100px;
      }
    }
  }

  &__actions-button {
    padding-left: 0;
    padding-right: 0;
  }

  &__link {
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    overflow: hidden;
    width: 100%;

    &__icon {
      font-size: $font-size--sm;
      margin-left: $spacing--xs;
    }
  }
}
