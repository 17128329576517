@import "../../shared/styles/styles.scss";

.settings {
  $tab-nav-width: 200px;

  height: 100%;
  overflow: hidden;

  &__page {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    padding: $spacing--xl $spacing--sm $spacing--sm $spacing--sm;

    &__header {
      padding: 0 0 $spacing--md calc($tab-nav-width + $spacing--xl);
    }

    &__tabs.ant-tabs {
      & > .ant-tabs-nav {
        min-width: $tab-nav-width;
      }

      & > .ant-tabs-content-holder {
        border: none;
        border-radius: 8px;

        & > .ant-tabs-content {
          & > .ant-tabs-tabpane {
            overflow: auto !important;
            padding: $spacing--xl;
          }
        }
      }
    }
  }

  &__sub-page {
    &--max-width-640 {
      max-width: 640px; // based on the UX designs in Figma
    }

    &__header {
      align-items: start;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      margin: 0 0 $spacing--xl 0;

      &__text {
        margin: 0;
        padding: 0;
      }
    }

    &__description {
      color: $color--text-secondary;
      margin: 0 0 $spacing--xl 0;
      padding: 0;
    }
  }
}
