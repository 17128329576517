@import "../../styles/styles.scss";

.folder-icon {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  overflow: hidden;

  &__icon {
    border-radius: 4px 0 0 4px;
    display: inline-block;
    height: 15px;
    margin: auto $spacing--xs auto 0;
    width: 7px;
  }

  &__content {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
