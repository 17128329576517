@import "../../../../styles/styles.scss";

.graph-edge {
  fill: none;
  pointer-events: stroke;
  stroke: $color--neutral-7;
  stroke-width: 2px;
  transition: stroke 100ms;

  &--active-lineage {
    stroke: $color--daybreak-blue-8;
  }
}
