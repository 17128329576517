@import "../../../../../../../shared/styles/spacing.scss";

.publishing-progress-modal {
  & .ant-modal-header {
    border-bottom: 0 !important;
    height: 75px !important;
    overflow: hidden;
  }

  & .ant-modal-title {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  &__title {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: end;
  }

  &__header-logo {
    position: absolute;
    height: 100px;
    top: -25px;
    left: -50px;
  }

  &__header-container {
    position: relative;
    width: 100%;
  }

  &__content {
    display: flex;
    justify-content: center;
    align-items: end;
    gap: $spacing--xs;
  }
}
