@import "../../../shared/styles/styles.scss";

.builder-settings {
  display: flex;
  flex-wrap: nowrap;
  height: 100%;
  overflow: hidden;
  width: 100%;

  &__content {
    flex: 1;
    overflow: hidden;
    width: 100%;
  }

  &__menu {
    overflow-y: auto;
  }
}

.package-macro-viewer {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  .macro-viewer-header {
    background-color: $color--bg-primary;
    padding: $spacing--sm;
    margin-bottom: 0px;
  }

  .macro-divider {
    background-color: $color--bg-primary;
    margin: 0px;
    padding: $spacing--md;
  }

  .macro-editor {
    flex: 1;
    overflow: hidden;
  }
}
