@import "../../../../web/shared/styles/styles.scss";

.multi-source-node-tab {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  font-size: $font-size--sm;
  justify-content: space-between;
  max-width: 240px;
  text-align: left;

  & > * + * {
    margin-left: $spacing--xs;
  }

  &__title {
    flex: 1;
  }

  &__buttons {
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;

    & > * + * {
      margin-left: $spacing--xxxs;
    }
  }

  &__button.ant-btn {
    font-size: $font-size--sm;
  }
}
