@import "../../../../../../web/shared/styles/styles.scss";

.docs-column-monaco-editor {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  min-height: 150px;
}

.column-transformation-container {
  border: 1px solid $color--divider-2;
  margin-bottom: $spacing--sm;
  border-radius: 4px;
}

.column-transformation-title-container {
  border-bottom: 1px solid $color--divider-2;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.column-transformation-title-text {
  margin-left: $spacing--sm;
  font-size: $font-size--md;
  font-weight: 500;
  margin-bottom: $spacing--xxs;
  margin-top: $spacing--xxs;
  &--secondary {
    font-size: $font-size--sm;
    font-weight: 400;
    margin-bottom: $spacing--xxs;
    margin-top: $spacing--xxs;
    color: $color--text-secondary;
    margin-right: $spacing--sm;
  }
}
