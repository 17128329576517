@import "../../../../../../web/shared/styles/styles.scss";

.runs-display {
  &__container {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
  }

  &__label-container {
    color: $color--text-secondary;
    display: flex;
    flex-direction: row;
    font-size: 0.7rem;
    margin-bottom: 2px;
  }

  &__label {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  &__widgets-container {
    direction: rtl;
    overflow: hidden;
  }

  &__widgets {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
