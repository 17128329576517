@import "../../../../../shared/styles/styles.scss";

.canvas-tabs {
  &.ant-tabs {
    & > .ant-tabs-nav {
      margin: 0;

      & > .ant-tabs-nav-wrap {
        & > .ant-tabs-nav-list {
          & > .ant-tabs-tab {
            background-color: rgba($color--bg-primary, 0.5);
            font-size: $font-size--sm;

            & > .ant-tabs-tab-btn {
              align-items: center;
              color: $color--text-secondary;
              display: flex;
              flex-wrap: nowrap;
              justify-content: flex-start;

              & > .key-value-tooltip {
                align-items: center;
                line-height: $font-size--sm;
                flex-wrap: nowrap;
                display: flex;
                justify-content: flex-start;

                .folder-icon {
                  overflow: hidden;
                }

                .folder-icon__content,
                span {
                  max-width: 150px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
              }
            }

            &.ant-tabs-tab-active {
              background-color: $color--bg-primary;

              & > .ant-tabs-tab-btn {
                color: $color--brand-blue;
              }
            }
          }
        }
      }

      & > .ant-tabs-extra-content {
        align-items: center;
        border-left: 1px solid $color--divider-2;
        display: flex;
        flex-wrap: nowrap;
        height: 100%;
        padding: 0 $spacing--xs;
      }
    }

    & > .ant-tabs-content-holder {
      background-color: $color--bg-primary;
      display: flex;
      flex-direction: column;
    }
  }

  &__tab-icon {
    font-size: 1rem;
    height: 1rem;
    margin-right: $spacing--sm;
    width: 1rem;
  }
}
