@import "../../../../../../shared/styles/styles.scss";

.workspace-settings-modal {
  & > .ant-modal-content > .ant-modal-header {
    margin-bottom: 0;
  }

  &__tabs.ant-tabs {
    height: 100%;

    & > .ant-tabs-content-holder {
      padding: $spacing--lg;
    }
  }
}
