@import "../../../../../../web/shared/styles/styles.scss";

.script-labels {
  display: flex;
  padding-left: $spacing--lg;
  padding-right: $spacing--lg;
}

.script-label-container {
  min-width: 117px;
}

.script-label {
  margin-bottom: 0px;
  font-size: $font-size--sm;
  font-weight: 500;
  color: $color--text-secondary;
}

.script-label-text {
  font-size: $font-size--md;
  font-weight: 400;
  color: $color--text-primary;
}
