@import "../../../../web/shared/styles/styles.scss";

.step-node-title-content {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  font-size: $font-size--sm;
  justify-content: space-between;
  padding-left: $spacing--sm;
}

.cursorPointer {
  cursor: pointer;
}
