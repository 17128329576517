@import "../../../shared/styles/styles.scss";

#create-package {
  --displayWidth: 832px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  background-color: $color--bg-primary;

  & .sticky-header-wrapper {
    &__breadcrumb {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: $spacing--lg;

      & .create-package-nav {
        padding: $spacing--md-lg 0 0 0;
        width: var(--displayWidth);
      }
    }

    &__header {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: $spacing--lg;

      & .create-package-header-title {
        margin: 0;
        width: var(--displayWidth);
      }
      & .create-package-error-banner {
        margin: 0;
        width: var(--displayWidth);
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: $spacing--lg;

      & .create-edit-package-view {
        width: var(--displayWidth);
      }

      & .create-package-button-box {
        display: flex;
        width: var(--displayWidth);
        justify-content: space-between;
        padding: 0 0 $spacing--lg 0;
      }
    }
  }
}

.create-package-success-message-name {
  font-weight: $font-weight--600;
}
