@import "../../../../../shared/styles/styles.scss";

.workspace {
  align-items: center;
  border: 1px solid $color--divider-1;
  border-radius: 3px;
  display: flex;
  padding: $spacing--md;
  width: 100%;

  &__details {
    margin-right: $spacing--sm;
  }

  &__name {
    font-weight: 600;
    font-size: $font-size--lg;
    white-space: nowrap;
  }

  &__current-git-branch {
    margin-left: $spacing--sm;
  }

  &__version-control-warning {
    margin-left: $spacing--sm;
  }

  &__description {
    color: $color--text-secondary;
    display: block;
    margin-top: $spacing--sm;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }

  &__actions {
    & > * + * {
      margin-left: $spacing--xs;
    }

    // to make sure all the buttons have the same color; based on standard antd color
    button.ant-btn-text {
      svg {
        color: $color--text-primary;
        fill: $color--text-primary;
        stroke: $color--text-primary;
      }

      &:disabled {
        svg {
          fill: $color--text-tertiary;
          stroke: $color--text-tertiary;
        }
      }
    }
  }
}
