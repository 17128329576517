@import "../../../../../web/shared/styles/styles.scss";

.job-schedule-table {
  &-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
  }

  &-content {
    gap: $spacing--xs;
    height: 100%;
  }

  &__delete-modal-schedule-name {
    font-weight: $font-weight--600;
  }

  &__table-column {
    &-project {
      min-width: 200px;
    }

    &-environment {
      min-width: 200px;
    }

    &-job {
      min-width: 200px;
    }

    &-schedule-name {
      min-width: 200px;
    }

    &-last-execution {
      min-width: 150px;
    }

    &-next-execution {
      min-width: 150px;
    }

    &-run-status > span {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.25rem;
      min-width: 100px;
    }

    &-actions {
      min-width: 100px;
    }

    &__not-applicable {
      opacity: 45%;
    }
  }

  &__actions-menu {
  }

  &__actions-button {
    padding-left: 0;
    padding-right: 0;
  }

  &__empty-page-container {
    height: 100%;
    background-color: $color--bg-view;
    padding: $spacing--lg;
  }

  &__empty-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  &__empty-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: min(10000000px, auto);
    color: $color--text-primary;
  }

  &__empty-title {
    font-weight: $font-weight--500;
    margin-bottom: 0;
  }

  &__delete-notification-text {
    margin-bottom: 0;
  }

  &__delete-notification-keyword {
    font-weight: $font-weight--500;
  }
}
