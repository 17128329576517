.expandable {
  align-items: flex-start;
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;

  &__content {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
