@import "./shared/styles/styles.scss";
@import "./shared/styles/antd.scss";
@import "./shared/styles/ag-grid.scss";
@import "./shared/styles/react-split.scss";

html,
body {
  font-family: $font-family--roboto;
  line-height: normal; // needed because reset.css from antd sets this to 1.15 that messes with some rendering
  margin: 0;
  padding: 0;
}
