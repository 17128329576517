@import "../../../web/shared/styles/styles.scss";

.ant-notification {
  .ant-notification-notice.failed-nodes-error-notification {
    top: 171px;
    padding: 16px 40px 16px 24px;
    border-radius: 4px;
    color: $color--text-error;
  }
  ul.failed-nodes-list {
    width: 251px;
    max-height: 190px;
    overflow-x: hidden;
    overflow-y: scroll;
    text-wrap: nowrap;
    margin-bottom: 0;
    padding-left: 9px;
    font-size: 14px;
    list-style-position: inside;
    > li {
      min-width: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    > li::marker {
      font-size: xx-small;
    }
  }
}
