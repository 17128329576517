@import "../../../web/shared/styles/styles.scss";

.invalid-data-platform-confirmation-modal {
  & .invalid-data-platform-confirmation-modal-title {
    margin-left: $spacing--xxs;
  }
}

.invalid-data-platform-modal-card-container {
  position: relative;
  font-family: $font-family--roboto;
  font-size: $font-size--sm;
  color: $color--text-primary;
  width: 100%;
  margin-top: $spacing--xxs;

  & .invalid-data-platform-modal-card-title {
    position: absolute;
    top: -10px;
    left: $spacing--md;
    display: flex;
    padding: 0 $spacing--xs;
    align-items: center;
    gap: $spacing--xs;
    background-color: $color--bg-primary;
    color: $color--text-secondary;
    line-height: $line-height--20;
    max-width: fit-content;
  }

  & .invalid-data-platform-modal-card-commit-info {
    border-radius: $spacing--xxs;
    border: 1px solid $color--divider-1;
    width: 100%;
    padding: $spacing--lg;
    font-size: $font-size--md;

    & p {
      margin: 0;

      & .invalid-data-platform-modal-oid {
        color: $color--text-secondary;
        font-weight: $font-weight--400;
        font-size: $font-size--sm;
        margin-left: $spacing--xs;
      }
      & .invalid-data-platform-modal-branch-name {
        font-weight: $font-weight--500;
        margin-bottom: $spacing--xxs;
      }
    }
  }
}

.invalid-data-platform-modal-off-platform-info {
  margin-top: $spacing--lg;
}

.invalid-data-platform-modal-card-message {
  margin-bottom: $spacing--lg;
}

.invalid-data-platform-modal-button-box {
  align-self: flex-end;
  display: flex;
  gap: $spacing--xs;
}
