@import "../../../web/shared/styles/styles.scss";

.delete-button-modal__button-box {
  display: flex;
  align-self: flex-end;

  & .ant-btn {
    margin: $spacing--xs;
  }
}
