@import "../../../../shared/styles/styles.scss";

.node-type-commit-list {
  &.ant-list {
    .ant-list-items {
      & > .ant-list-item {
        & > .ant-list-item-meta {
          & > .ant-list-item-meta-content {
            & > h4.ant-list-item-meta-title {
              font-weight: $font-weight--400;
              margin: 0;
            }
          }
        }
        & > .ant-list-item-action {
          margin-inline-start: $spacing--md;
        }
      }
    }
  }

  &__discard-changes-button {
    .ant-btn:disabled {
      img {
        height: $font-size--lg;
        width: $font-size--lg;
        filter: invert(48%) sepia(14%) saturate(0%) hue-rotate(268deg) brightness(102%) contrast(89%);
      }
    }
  }

  &__discard-changes-button.ant-btn {
    img {
      height: $font-size--lg;
      width: $font-size--lg;
    }
  }

  &__item {
    border-radius: 3px;
    cursor: pointer;

    &.ant-list-item {
      padding: $spacing--xs 0 $spacing--xs $spacing--xs;
    }

    &:hover:not(.node-type-commit-list__item--selected) {
      background-color: $color--bg-secondary;
    }

    &--selected {
      background-color: $color--item-bg-selected;
    }
  }

  &__item-meta {
    align-items: center;
    display: flex;
    flex-direction: row;
    overflow: hidden;

    & > * + * {
      // !important added to force it on the ant-badge (dot action indicator)
      margin-left: $spacing--xs !important;
    }

    &__filename {
      flex: 1;
      font-size: $font-size--sm;
      line-height: $font-size--sm;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;

      &--bold {
        font-weight: $font-weight--500;
      }
    }
  }
}
