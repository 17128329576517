.operation-type-tabs.ant-tabs {
  flex: 1;

  & > .ant-tabs-content-holder {
    & > .ant-tabs-content {
      & > .ant-tabs-tabpane:not(.ant-tabs-tabpane-hidden) {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow: hidden;
      }
    }
  }
}
