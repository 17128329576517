@import "../../../../web/shared/styles/styles.scss";

.resync-confirmation-modal {
  & .ant-modal-title {
    & div {
      display: flex;
      margin: 0;
    }

    & .resync-confirmation-modal-icon {
      min-width: $spacing--md-lg;
      margin-right: $spacing--sm;

      & svg {
        height: 100%;
        width: 100%;
      }
    }
  }

  & p {
    margin: 0;
  }

  & .resync-confirmation-modal-button-box {
    display: flex;
    gap: $spacing--xs;
    justify-content: flex-end;

    & .resync-confirmation-modal-button {
      background-color: $color--coalesce-blue;
      color: $color--text-primary-inverted;
    }
  }
}
