@import "../../../web/shared/styles/styles.scss";

.jobs-tree {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;

  // Indent some levels of the tree
  // Unsure why we have to set the indentation manually. May be something we're doing wrong or a bug in ag-grid.
  .ag-row-level-1 {
    .ag-cell {
      padding-left: $spacing--xxl;
    }
  }
}
