@import "../../../../styles/styles.scss";

.graph-node {
  background-color: $color--bg-primary;
  border-radius: 4px;
  box-shadow: 0 0 5px rgba($color--neutral-12, 0.3);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  min-height: 44px;
  pointer-events: auto;
  position: absolute;
  transition: all 0.1s;
  width: 200px;

  &:hover,
  &:active {
    box-shadow: 0 0 5px $color--neutral-13;
    outline: 2px solid $color--brand-blue;
  }

  &__content {
    border-left: 6px solid $color--neutral-13;
    border-radius: 4px;
    flex: 1;
    padding: $spacing--xs;
    transition: all 0.25s;

    & > * + * {
      margin-top: $spacing--xxs;
    }
  }

  &__name {
    color: $color--text-primary;
    font-size: $font-size--sm;
    line-height: $font-size--sm;
    width: 100%;
  }

  &__storage-location {
    color: $color--text-secondary;
    font-size: $font-size--sm;
    line-height: $font-size--sm;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &--selected {
    background-color: $color--item-bg-selected;
    outline: 2px solid $color--brand-blue;

    &:hover,
    &:active {
      outline: 2px solid $color--coalesce-blue;
    }
  }

  &--successor,
  &--predecessor {
    outline: 2px solid $color--brand-blue;

    &:hover,
    &:active {
      outline: 2px solid $color--coalesce-blue;
    }
  }

  &--small {
    & > .graph-node__content {
      * {
        opacity: 0;
      }
    }

    &.graph-node--selected {
      outline-width: 8px;
    }
  }
}
