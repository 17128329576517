@import "../../../../../shared/styles/colors.scss";

.MuiToggleButtonGroup-root {
  & .MuiToggleButton-root {
    padding: 3px 14px;
  }

  & .Mui-selected {
    border-color: $color--coalesce-blue;
    background-color: $color--bg-primary;

    & .MuiToggleButton-label {
      color: $color--coalesce-blue;
    }
  }

  & .MuiToggleButton-label {
    text-transform: none;
  }
}
