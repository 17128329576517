@import "../../styles/styles.scss";

.loading-select {
  &-dropdown {
    &__wrapper {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &__spinner {
      opacity: 0.5;
    }

    &__text {
      margin-top: $spacing--sm;
    }
  }
}
