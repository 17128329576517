@import "../../../styles/styles.scss";

.monaco-tooltip {
  display: flex;
  flex-direction: row;
  font-size: $font-size--xs;
  width: 100%;

  &__content {
    white-space: nowrap;

    &--left {
      flex: 1;
    }

    &--right {
      color: $color--text-secondary;
      flex: 2;
      overflow: hidden;
      padding-left: $spacing--xs;
      text-overflow: ellipsis;
    }

    & > h3 {
      color: $color--text-title;
    }

    & > p {
      margin: 0;

      & + p {
        margin-top: $spacing--xs;
      }
    }
  }

  &__overlay.ant-popover {
    & > .ant-popover-arrow::before,
    & > .ant-popover-arrow::after {
      background-color: $color--bg-secondary;
    }

    & > .ant-popover-content {
      & > .ant-popover-inner {
        background-color: $color--bg-secondary;

        & > .ant-popover-inner-content {
          max-height: 35vh;
          overflow-y: auto;
        }
      }
    }
  }
}
