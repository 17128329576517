@import "../../../../../../shared/styles/styles.scss";

.new-branch-name-input {
  &__form-item {
    padding-left: $spacing--xs;
  }

  &__input {
    margin: $spacing--xxs;
    font-size: $font-size--sm;
  }
}
