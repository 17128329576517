@import "../../web/shared/styles/styles.scss";

.config-menu-component-wrapper {
  &__header {
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin: 0 0 $spacing--xs 0;
  }

  & + .config-menu-component-wrapper {
    margin-top: $spacing--xl;
  }
}
