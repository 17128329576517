@import "../../../shared/styles/styles.scss";

.deploy-wizard {
  background-color: $color--bg-primary;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  & > .wizard {
    flex: 1;
  }

  &__header {
    display: flex;
    justify-content: space-between;

    &__title {
      flex: 1;
    }
  }

  &__nav {
    & > * + * {
      margin-top: $spacing--md;
    }
  }
}
