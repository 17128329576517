@import "../../styles/styles.scss";

.wizard {
  background-color: $color--bg-primary;
  display: grid;
  grid-template:
    [row1-start] ". breadcrumb breadcrumb" [row1-end]
    [row2-start] ". header header" [row2-end]
    [row3-start] "nav steps steps" [row3-end];
  grid-template-columns: auto 1fr 1fr;
  grid-template-rows: auto auto 1fr;
  height: 100%;
  padding: $spacing--xl;

  &__breadcrumb.ant-breadcrumb {
    grid-area: breadcrumb;
    padding-bottom: $spacing--lg;
  }

  &__header {
    grid-area: header;
    padding-bottom: $spacing--lg;
  }

  &__nav {
    display: flex;
    flex-direction: column;
    grid-area: nav;
    justify-content: space-between;
    min-width: 256px;
    padding-right: $spacing--xl;
  }

  &__steps {
    grid-area: steps;
    flex: 1;
  }
}
