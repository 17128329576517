@import "../../web/shared/styles/styles.scss";

.run-panel-header {
  display: flex;
  justify-content: space-between;
}

.run-panel-buttons-container {
  display: flex;
  padding: $spacing--xs $spacing--xs $spacing--xxs $spacing--xs;
  justify-content: flex-end;
}

.run-panel-buttons-container > * {
  padding: $spacing--xxs;
}
