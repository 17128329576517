@import "../../shared/styles/styles.scss";

.new-version-banner {
  align-items: center;
  background-color: $color--brand-blue;
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 100;

  &__content {
    color: $color--text-primary-inverted;
    max-width: 1200px;
    padding: $spacing--sm 0 $spacing--sm calc($spacing--md + 56px); // spacing + size of icon
    position: relative;

    &__icon {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      z-index: 999;
    }

    &__refresh-button {
      background: none;
      border: none;
      color: $color--text-primary-inverted;
      cursor: pointer;
      display: inline-block;
      padding: 0;
      margin: 0;
      text-decoration: underline;
    }

    a,
    a:active,
    a:hover,
    a:visited {
      color: $color--text-primary-inverted;
      cursor: pointer;
      text-decoration: underline;
    }
  }
}
