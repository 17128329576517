@import "../../../../../shared/styles/styles.scss";

.node-type-tooltip {
  p {
    margin: 0;
    padding: 0;

    & + p {
      margin-top: $spacing--sm;
    }
  }
}
