@import "../../web/shared/styles/styles.scss";

.sign-in {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  overflow-y: auto;
  background: url("../../assets/img/sign-in-bg.png"),
    linear-gradient(135deg, $color--marketing-dark-blue 0%, $color--marketing-bright-blue 100%);
  background-size: cover;

  &__cta {
    color: $color--text-primary-inverted;
    &__link {
      color: $color--daybreak-blue-3;
    }
  }

  &__container {
    width: calc(6 * $spacing--xxxl);
    padding: $spacing--lg;
    background: $color--bg-primary;
    border-radius: $spacing--xs;
  }

  &__logo {
    width: calc(4 * $spacing--xxxl);
    display: block;
    margin: 0 auto;
  }

  &__btns-container {
    display: flex;
    flex-flow: column;
    gap: $spacing--sm;
  }
}

.totp-input-phase-form {
  text-align: center;
  margin: $spacing--lg 0 $spacing--md;
}
