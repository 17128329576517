@import "../../web/shared/styles/styles.scss";

.source-tooltip {
  overflow: hidden;
  width: 100%;

  & > * {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }

  &__step-location {
    color: $color--text-secondary;
  }
}

.custom-monaco-editor {
  height: 20vh;
  width: 500px;

  &__editor {
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    height: 90%;
    width: 100%;
  }
}

.source-cell-content {
  font-size: $font-size--sm;
  display: flex;
  flex-direction: column;
  &__location {
    color: $color--text-secondary;
  }
  &__ampersand {
    color: $color--text-secondary;
  }
}

.ag-row {
  .source-popover-content {
    color: $color--text-secondary;
  }
}

.ag-row-selected {
  .source-popover-content {
    color: $color--text-primary-inverted !important;
  }
}

.ant-popover-title {
  .source-popover-title {
    color: $color--text-secondary;
    font-size: $font-size--sm;
  }
  span {
    svg {
      fill: $color--coalesce-blue;
    }
  }
}
