@import "../../web/shared/styles/styles.scss";

.cascader-dropdown {
  .ant-cascader-menu-item-content {
    overflow: auto;
    text-overflow: ellipsis;
  }
}

.column-bulk-editor {
  &__input {
    &--description {
      margin-bottom: $spacing--md; // added to make sure the count does not go outside the card
    }
  }

  &__error-message {
    display: block;
    margin-right: 90px; // to make sure it does not overlap with the character count
    margin-top: -$spacing--md; // to vertically align it with the character count
  }
}

.node-dropdown-option {
  padding: $spacing--xxs 0;

  & > * {
    display: block;
    line-height: 12px; // to match font-size of .default-font
  }

  &__location {
    color: $color--text-secondary;
  }
}

.package-option {
  font-size: 12px;

  .node-dropdown-option__location {
    padding-top: $spacing--xxxs;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.ant-select-item-option-content {
  .package-option {
    .node-dropdown-option__location {
      display: none; // Hides package alias when dropdown is open CD-9378
    }
    .node-dropdown-option__name {
      font-size: $font-size--md;
    }
  }
}
