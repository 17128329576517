@import "../../../../../../web/shared/styles/styles.scss";

.docs-search-hit {
  align-items: center;
  background-color: $color--bg-primary;
  border-radius: 4px;
  border: 1px solid $color--divider-2;
  display: flex;
  margin-bottom: $spacing--sm;
  min-height: 100px;
  &__type {
    margin-left: $spacing--lg;
    margin-right: $spacing--lg;
  }
  &__content {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  &__title {
    display: flex;
    align-items: center;
    font-weight: 500;
    padding-bottom: 8px;
    &--primary {
      color: $color--coalesce-blue;
      font-size: $font-size--lg;
      max-width: 300px;
      &:hover {
        text-decoration-line: underline;
      }
    }
    &--secondary {
      font-size: $font-size--md;
      max-width: 200px;
    }
  }
  &__subtitles {
    align-items: center;
    display: flex;
    font-weight: 500;
    font-size: $font-size--md;
    color: $color--text-secondary;
    &--left {
      display: flex;
      flex-wrap: wrap;
      flex: 1;
    }
    &--right {
      padding-right: $spacing--xs;
      padding-left: $spacing--xs;
    }
  }
  &__subtitle {
    display: flex;
    align-items: center;
    margin-right: 10px;
  }
  &__indicator {
    border-radius: 5px 0px 0px 5px;
    height: 16px;
    margin-left: $spacing--xs;
    margin-right: $spacing--xs;
    width: 6px;
  }
  &__icon {
    margin-right: $spacing--sm;
    color: $color--text-tertiary;
  }

  &__loading {
    opacity: 0.5;
  }
}

.max-width-400px {
  max-width: 400px;
}

.mh8 {
  margin-left: $spacing--xs;
  margin-right: $spacing--xs;
}
