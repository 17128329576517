@import "../../../web/shared/styles/styles.scss";

.branches-table {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  &__table {
    flex: 1;
    height: 100%;
    overflow: hidden;

    & .ant-table-footer {
      background-color: transparent;
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;
    }

    // CD-9609 Had to use this selector to be specific enough to override ant default
    // If andt fixes the double scroll bar bug, can remove this style
    & div.ant-table-sticky-scroll:has(div.ant-table-sticky-scroll-bar) {
      visibility: hidden;
      max-height: 0;
    }

    &__column {
      &--message {
        min-width: 300px;
        max-width: 600px;
      }

      &--author {
        min-width: 100px;
      }

      &--commit {
        min-width: 100px;
      }

      &--date {
        min-width: 150px;
      }

      &--workspaces {
        min-width: 150px;
        max-width: 250px;
      }

      &--environments {
        min-width: 150px;
        max-width: 250px;
      }

      &--branch-button {
        min-width: 125px;
      }

      &--merge-button {
        min-width: 90px;
      }
    }
  }
}

.new-branch-success-message-name {
  font-weight: $font-weight--500;
}
