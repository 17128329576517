@import "../../web/shared/styles/styles.scss";
@import "../variables.scss";

.sql-step-menu {
  &__tabs--multisource.ant-tabs {
    margin-top: -28px;

    & > .ant-tabs-nav > .ant-tabs-nav-wrap > .ant-tabs-nav-list {
      margin: auto 0 0 0;
      max-height: 32px; // to match the heights of the tabs on non-mutlisource nodes
    }
  }

  &__tabs.ant-tabs {
    & > .ant-tabs-nav {
      margin: 0;

      & > .ant-tabs-nav-wrap {
        padding: $spacing--xs $spacing--md 0 $spacing--md;

        & > .ant-tabs-nav-list {
          & > .ant-tabs-tab {
            font-size: $font-size--xs;
          }
        }
      }
    }
  }
}

.sql-step-menu__tab.ant-tabs {
  border: 5px solid $color--dust-red-5;
}

.ant-typography.sql-step-menu-title-input {
  border-bottom: solid 1px $color--divider-2;
  display: block;
  height: 32px;
  margin-left: $spacing--md;
  margin-top: 0px !important; // otherwise, it is -12px and it makes the input partially disappear
  padding-bottom: $spacing--xxs;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(100% - 430px); // width of the container minus the width of the RemappingIndicator
  z-index: 2;

  &:hover {
    border-bottom: solid 1px $color--neutral-13;
  }

  &.ant-typography-edit-content {
    & > textarea {
      padding-right: $spacing--lg-xl;
    }
  }
}

.node-name-input {
  padding-right: $spacing--lg;
  width: 100%;

  &__content {
    & > .ant-typography-edit-content {
      padding-left: $spacing--xs;

      & > textarea {
        padding-right: $spacing--lg-xl;
      }
    }
  }
}

.hundred-percent-height-hidden-overflow {
  height: 100%;
  overflow: hidden;
}

.sql-node-menu-header {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: $spacing--xxs;
  padding: $spacing--md $spacing--md-lg 0 $spacing--md-lg;

  & .node-name {
    display: flex;
    justify-content: space-between;
    font-size: $font-size--lg;

    & .ant-typography.ant-typography-edit-content.ant-typography-span {
      display: flex;
      flex: 1 0 auto;
      margin: $spacing--xs;
    }

    &__container {
      display: flex;
      flex: 1 1 auto;
      min-width: 0px;
    }

    &__id {
      display: flex;

      &__section {
        font-size: $spacing--sm;
        color: $color--text-secondary;
        font-family: $font-family--roboto;
        margin: auto $spacing--xs;
      }
    }
  }
}
