.ant-tag.environment-tag {
  border: none;
  border-radius: 6px;
  vertical-align: middle;
  overflow: hidden;
}

.tooltip-on-truncation-tag {
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
