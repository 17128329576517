@import "../../../../../../../web/shared/styles/spacing.scss";

.create-workspace-details-form {
  padding-top: $spacing--md;

  &__color-picker {
    display: flex;

    > * {
      margin-right: $spacing--sm;
    }

    &__text {
      margin-left: $spacing--lg-xl;
    }
  }
}
