@import "../../../../../web/shared/styles/styles.scss";

.docs-home-grid {
  background-color: transparent;
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  position: relative;
  overflow: auto;
  height: 100%;
}

.docs-home-container {
  grid-column-start: 6;
  grid-column-end: 20;

  .empty-projects-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 66vh;

    .empty-projects.ant-empty {
      width: 360px;
    }

    .empty-project-description-container {
      display: flex;
      flex-direction: column;
    }

    .project-member-description {
      max-width: 256px;
      color: $color--text-secondary;
      text-align: center;
    }
  }
}

#docs-header-text {
  padding-top: $spacing--xl;
  padding-bottom: $spacing--xl;
  margin-bottom: 0px;
  font-size: $font-size--xxl;
  font-weight: 600;
}
