@import "../../../../shared/styles/styles.scss";
$spacing-avg: 10px;

.packages {
  .packages-header-btn-container {
    display: flex;
    gap: $spacing-avg;
    align-items: center;
  }

  .packages-empty {
    height: 100%;
    font-family: "Roboto";
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .packages-card {
    background-color: $color--bg-primary;
    font-family: "Roboto";
    padding: $spacing--lg;
    border-radius: 12px;
    margin-bottom: $spacing-avg;

    .card-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .card-title-info {
        display: flex;
        align-items: center;

        .card-title-alias {
          max-width: 40vw;
        }

        .packages-card-tag {
          display: flex;
          align-items: center;
          font-family: "Roboto";
          font-size: 12px;
          padding: 1px 8px;
          background-color: $color--neutral-2;
          border: 1px solid $color--divider-2;
          border-radius: 2px;
          margin-left: $spacing-avg;
          font-weight: 400;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: 150px;
        }
      }
    }
    .package-publisher-info {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }
  }
}

.uninstall-package-btn-container {
  .ant-btn {
    margin-left: $spacing-avg;
  }
}

.install-package-form {
  font-family: $font-family--roboto;
  width: 100%;

  .install-form-items {
    display: flex;
    gap: 15px;
  }

  .ant-form-item:has(#package-id-input) {
    min-width: 75%;
  }

  .package-version-select {
    width: 25vw;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .package-alias-description {
    color: $color--text-primary;
  }

  .install-package-error-boundary {
    margin-bottom: $spacing--sm;
  }

  .button-container {
    padding-right: $spacing--lg;
    padding-bottom: $spacing--lg;
    display: flex;
    justify-content: flex-end;
  }
}

.view-nodes-in-use-btn {
  padding-left: 0px !important;
}
