@import "../../../../transform/variables.scss";
@import "../../styles/styles.scss";

.error-boundary {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  width: 100%;

  h1 {
    font-weight: 400;
    color: $color--text-secondary;
    font-size: 18px;
  }

  h2 {
    font-weight: 400;
    color: $color--text-secondary;
    text-align: center;
    font-size: 14px;
  }

  &__try-again-button {
    margin-top: $spacing--sm;
  }
}
