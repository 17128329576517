@import "../../../../../shared/styles/styles.scss";

.create-package-release-form {
  display: flex;
  flex-direction: column;
  gap: $spacing--lg;

  & .ant-form-item-label {
    width: 142px;
  }

  & .set-package-form-release-info {
    width: 100%;
  }
}
