@import "../../../../../../../../shared/styles/styles.scss";

.node-right-click-context-menu.ant-dropdown-menu {
  .ant-dropdown-menu-item {
    .ant-dropdown-menu-item-icon {
      font-size: $font-size--md;
      min-width: $font-size--md;
    }
  }
}

.node-right-click-context-menu__add-node-sub-menu.ant-dropdown-menu-submenu-popup {
  padding-top: 0;

  & > .ant-dropdown-menu {
    padding-top: 0;
  }
}

.node-right-click-context-menu__add-node-sub-menu__filter-input-wrapper.ant-dropdown-menu-item {
  background-color: $color--bg-primary;
  left: 0;
  position: sticky;
  padding: $spacing--sm;
  top: 0;
  z-index: 1;
}
