@import "../../styles/styles.scss";

.sticky-header-wrapper {
  background-color: $color--bg-primary;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  overflow: auto;
  position: relative;
  width: 100%;

  &__breadcrumb {
    background-color: $color--bg-primary;
    padding: $spacing--md $spacing--md 0 $spacing--md;
    position: sticky;
    top: 0;

    &--without-header {
      position: relative;
    }
  }

  &__header {
    background-image: linear-gradient(rgba($color--bg-primary, 0), 20%, rgba($color--bg-primary, 1));
    padding: $spacing--xl $spacing--md $spacing--md $spacing--md;
    position: sticky;
    top: 0;
    z-index: 1;

    &--with-dropshadow {
      background-color: $color--bg-primary;
      box-shadow: hsla(0 0% 0% / 0.1) 0 0.5rem 1rem;
      transition-duration: 100ms;
    }
  }

  &__content {
    flex: 1;
    padding: $spacing--md;
  }

  &__footer {
    background-color: $color--bg-primary;
    padding: $spacing--md;
    position: sticky;
    bottom: 0;

    &--with-dropshadow {
      box-shadow: hsla(0 0% 0% / 0.1) 0 -0.5rem 1rem -0.5rem;
      transition-duration: 100ms;
    }
  }
}
